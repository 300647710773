import React from 'react';
// import Instagram from "./../images/instagram.png"
import telegram from "./../images/teligram.png"
import youtube from "./../images/youtube.png"


import website from "./../images/website.png"
import Banner from "./../images/banner.png"
import Popup from "./../images/Popup.png"
import Push from "./../images/Push.png"
import { Link } from 'react-router-dom';

function DigitalAdvertising() {
    return (
      <div>
        <div
          style={{
            background: "linear-gradient( #A5BFD9, #A7D3FF)",
            padding: "50px 25px",
          }}
        >
          <h1
            className="top-hea"
            style={{ fontSize: "40px", lineHeight: "24px", fontWeight: "400" }}
          >
            Digital Advertising
          </h1>
          <section>
            <h3 className="mt-5">Social Media</h3>
            <hr
              className="hr-style"
              style={{
                width: "1204px",
                height: "0px",
                top: "243px",
                left: "295px",
                gap: "0px",
                opacity: "0px",
                transform: "rotate(0deg)",
                border: "3px solid white",
              }}
            />
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <div className="mx-3" style={{ width: "150px" }}>
                <img
                  src={"Instagram"}
                  className="icons-insta mt-2"
                  style={{
                    width: "69px",
                    height: "67px",
                    display: "block",
                    margin: "auto",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
                <Link to={"/digitaladd/instagram"}>
                  {" "}
                  <button
                    type="button"
                    className="btn-insta mt-5"
                    style={{
                      width: "150px",
                      height: "44px",
                      left: "337px",
                      gap: "0px",
                      borderRadius: "15px",
                      opacity: "0px",
                      background: "#FFFFFFCC",
                      border: "none",
                    }}
                  >
                    Instagram
                  </button>
                </Link>
              </div>
              <div className="mx-3" style={{ width: "150px" }}>
                <img
                  src={telegram}
                  className="icons-insta mt-2"
                  style={{
                    width: "70px",
                    height: "67px",
                    display: "block",
                    margin: "auto",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
                <button
                  type="button"
                  className="btn-insta mt-5"
                  style={{
                    width: "150px",
                    height: "44px",
                    left: "337px",
                    gap: "0px",
                    borderRadius: "15px",
                    opacity: "0px",
                    background: "#FFFFFFCC",
                    border: "none",
                  }}
                >
                  Instagram
                </button>
              </div>
              <div className="mx-3 pt-3" style={{ width: "150px" }}>
                <img
                  src={youtube}
                  className="icons-insta mt-2"
                  style={{
                    width: "83px",
                    height: "50px",
                    display: "block",
                    margin: "auto",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
                <Link to={"/digitaladd/youtube"}>
                  <button
                    type="button"
                    className="btn-insta mt-5"
                    style={{
                      width: "150px",
                      height: "44px",
                      left: "337px",
                      gap: "0px",
                      borderRadius: "15px",
                      opacity: "0px",
                      background: "#FFFFFFCC",
                      border: "none",
                    }}
                  >
                    Instagram
                  </button>
                </Link>
              </div>
            </div>

            <h3 className="mt-5">Website</h3>
            <hr
              className="hr-style"
              style={{
                width: "1204px",
                height: "0px",
                top: "243px",
                left: "295px",
                gap: "0px",
                opacity: "0px",
                transform: "rotate(0deg)",
                border: "3px solid white",
              }}
            />
            <div className="d-flex" style={{ flexWrap: "wrap" }}>
              <div className="mx-3 mt-5" style={{ width: "250px" }}>
                <img
                  src={website}
                  className="icons-insta mt-2"
                  style={{ width: "250px", height: "250px" }}
                />
                <button
                  type="button"
                  className="btn-insta mt-5"
                  style={{
                    width: "250px",
                    height: "44px",
                    left: "337px",
                    gap: "0px",
                    borderRadius: "15px",
                    opacity: "0px",
                    background: "#FFFFFFCC",
                    border: "none",
                  }}
                >
                  Websites
                </button>
              </div>
              <div className="mx-3  mt-5" style={{ width: "250px" }}>
                <img
                  src={Banner}
                  className="icons-insta mt-2"
                  style={{
                    width: "250px",
                    height: "250px",
                    display: "block",
                    margin: "auto",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
                <button
                  type="button"
                  className="btn-insta mt-5"
                  style={{
                    width: "250px",
                    height: "44px",
                    left: "337px",
                    gap: "0px",
                    borderRadius: "15px",
                    opacity: "0px",
                    background: "#FFFFFFCC",
                    border: "none",
                  }}
                >
                  Banners
                </button>
              </div>
              <div className="mx-3  mt-5" style={{ width: "250px" }}>
                <img
                  src={Popup}
                  className="icons-insta mt-2"
                  style={{
                    width: "250px",
                    height: "250px",
                    display: "block",
                    margin: "auto",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
                <button
                  type="button"
                  className="btn-insta mt-5"
                  style={{
                    width: "250px",
                    height: "44px",
                    left: "337px",
                    gap: "0px",
                    borderRadius: "15px",
                    opacity: "0px",
                    background: "#FFFFFFCC",
                    border: "none",
                  }}
                >
                  Popups
                </button>
              </div>
              <div className="mx-3  mt-5" style={{ width: "250px" }}>
                <img
                  src={Push}
                  className="icons-insta mt-2"
                  style={{
                    width: "250px",
                    height: "250px",
                    display: "block",
                    margin: "auto",
                    gap: "0px",
                    opacity: "0px",
                  }}
                />
                <button
                  type="button"
                  className="btn-insta mt-5"
                  style={{
                    width: "250px",
                    height: "44px",
                    left: "337px",
                    gap: "0px",
                    borderRadius: "15px",
                    opacity: "0px",
                    background: "#FFFFFFCC",
                    border: "none",
                  }}
                >
                  Push
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
}

export default DigitalAdvertising;
