import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import account from "src/_mock/account";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/system";
import Modal from "@mui/material/Modal";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import PauseIcon from "@mui/icons-material/Pause";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { Helmet } from "react-helmet-async";
import { getUserFromLocalStorage } from "src/service/localStorage";
import LinkIcon from "@mui/icons-material/Link";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Chistory = () => {
  const user2 = getUserFromLocalStorage();
  const accessToken = user2?.data.access_token;
  const affiliate_id = account.affiliate_id;
  const affiliate_name = account.displayName;
  const [remainBalance, setRemainBalance] = useState(0);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [apires, setApires] = useState([]);
  const URL = process.env.REACT_APP_PROD_FILINGSOLUTIONS_API;
  const Affiliate_URL = process.env.REACT_APP_PROD_API;
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isDetailsStatus, setIsDetailsStatus] = useState(false);
  const [orderStatus, setOrderStatus] = useState();
  const [totalBill, setTotalBill] = useState(0);
  const [currentData, setCurrentData] = useState({});

  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(currentData);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getJobData = async () => {
    try {
      // const url = `https://auto-api-affworld.vercel.app/api/particularjobs/${affiliate_id}`;
      const url = `${URL}/api/particularjobs/${affiliate_id}`;

      setLoading(true);
      const res = await axios.get(url);
      setData(res.data);
      setLoading(false);
      // console.log(" this is jobs  data--->", res.data);
    } catch (error) {
      console.log("Erro In getting jobs data--->", error);
      toast.error("Error In getting jobs data!!");
    }
  };

  const handleClick = (link) => {
    if (link) {
      // Open the link in a new tab/window
      window.open(link, "_blank");
    } else {
      toast.warning("No link available for this item.");
    }
  };

  const handleApires = (apires) => {
    setApires(apires.apiResponse);
    setIsOpen(true);
  };

  const getTotalBill = async () => {
    try {
      const url = `${Affiliate_URL}/api/wallet/get-total-bill`;
      const data = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (data.status === 200) {
        setTotalBill(data?.data);
      } else if (data.status === 401) {
        toast.error("Unauthorized access", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.log("Error in getting total bill--->", error);
      toast.error("Error in getting total bill!!");
    }
  };

  const getRemainBalance = async () => {
    try {
      const url = `${Affiliate_URL}/api/wallet/total-remaining-balance`;
      const data = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (data.status === 200) {
        // console.log("this is remaining balance--->", data);
        // toast.success("Remaining balance fetched successfully!!");
        const remainBalance = (data?.data?.total_remaining_balance).toFixed(2);
        setRemainBalance(remainBalance);
      }
    } catch (error) {
      console.error("Error in  fetching remaining balance --->", error);
      // toast.error("Error fetching remaining balance!!");
    }
  };

  const handleChnageStatus = async (row) => {
    try {
      const url = `${URL}/api/toggle-job/${row?._id}`;
      const res = await axios.post(url);
      if (res.status === 200) {
        toast.success(`Status  of ${row?.name} has been changed successfully`, {
          position: toast.POSITION.TOP_CENTER,
        });
        getJobData();
      }
    } catch (error) {
      console.log("Error in changing status--->", error);
      toast.error("Error in changing status!!");
    }
  };

  const handleDelete = (row) => {
    setServiceToDelete(row);
    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const url = `${URL}/api/jobs/${serviceToDelete?._id}`;
      const res = await axios.delete(url);

      if (res.status === 200) {
        toast.success(
          `Job of ${serviceToDelete?.name} has been deleted successfully`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      getJobData();
    } catch (error) {
      console.log("Error in deleting--->", error);
      toast.error("Error in deleting!!");
    } finally {
      setDeleteConfirmationOpen(false);
      setServiceToDelete(null);
    }
  };

  const formatMilliseconds = (milliseconds) => {
    const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
    const hours = Math.floor(
      (milliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
    );
    const minutes = Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000));

    return `${days}d/${hours}h/${minutes}m`;
  };

  const totalCharges = data?.reduce((acc, item) => {
    return acc + (item?.totalCharges ?? 0);
  }, 0);

  const handleOrderStatus = async (item) => {
    console.log("this is item for status--->", item.response.order);

    const order = item.response.order;

    try {
      const res = await axios.post(`${URL}/api/check-order-status`, { order });
      // console.log("this is res for Order Status--->", res);
      setOrderStatus(res.data);
      setIsDetailsStatus(true);
    } catch (error) {
      console.log("Error While getting Status Of Order --->", error);
      toast.error("Error While getting Status Of Order !!");
    }
  };

  useEffect(() => {
    getJobData();
    getTotalBill();
    getRemainBalance();
  }, [affiliate_id]);

  return (
    <>
      <Helmet>
        <title>C-States | Affworld</title>
      </Helmet>
      <div className="mt-5 px-4">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success mb-3 fw-bold"
          table="table-to-xls"
          filename="c-stat"
          sheet="c-stat"
          buttonText="Export Data to Excel Sheet"
        />

        {loading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table
              id="table-to-xls"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ bgcolor: "#3D6A91", color: "#ffff" }}
                    align="center"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      bgcolor: "#3D6A91",
                      color: "#ffff",
                      whiteSpace: "nowrap",
                    }}
                    align="center"
                  >
                    Exe. count / Max execution
                  </TableCell>
                  <TableCell
                    sx={{ bgcolor: "#3D6A91", color: "#ffff" }}
                    align="center"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ bgcolor: "#3D6A91", color: "#ffff" }}
                    align="center"
                  >
                    Timing
                  </TableCell>

                  <TableCell
                    sx={{ bgcolor: "#3D6A91", color: "#ffff" }}
                    align="center"
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    sx={{
                      bgcolor: "#3D6A91",
                      color: "#ffff",
                      whiteSpace: "nowrap",
                    }}
                    align="center"
                  >
                    Total Charges
                  </TableCell>
                  <TableCell
                    sx={{
                      bgcolor: "#3D6A91",
                      color: "#ffff",
                      whiteSpace: "nowrap",
                    }}
                    align="center"
                  >
                    Total Quantity
                  </TableCell>

                  <TableCell
                    sx={{ bgcolor: "#3D6A91", color: "#ffff" }}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      <h5>
                        No data found , Use Campagin Management to create..
                      </h5>
                    </TableCell>
                  </TableRow>
                ) : (
                  [...data].reverse().map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="td" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">
                      {row?.category === null ? "N/A" : row?.executionCount}     / {row?.category === null ? "N/A" : row?.maxExecutions}
                      </TableCell>
                      <TableCell align="center">
                        {row?.status === "active" ? (
                          <CloudDoneIcon style={{ color: "#32e620" }} />
                        ) : row?.status === "inactive" ? (
                          <PauseIcon style={{ color: "#FF0000" }} />
                        ) : null}
                        {"   "}
                        <span style={{ fontWeight: 700 }}>{row?.status} </span>
                      </TableCell>
                      <TableCell align="center">
                        {formatMilliseconds(row?.timing)}
                      </TableCell>
                    
                      <TableCell align="center">{row?.quantity}</TableCell>
                      <TableCell align="center">
                        ₹ {row?.totalCharges.toFixed(4)}
                      </TableCell>
                      <TableCell align="center">{row?.totalQuantity}</TableCell>

                      <TableCell align="center">
                        <Button
                          aria-describedby={id}
                          variant="contained"
                          onClick={(event) => {
                            handlePopClick(event);
                            setCurrentData(row);
                          }}
                        >
                          ...
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ListItem
            sx={{ bgcolor: "#3D6A91", color: "#fff" }}
            onClick={handlePopClose}
          >
            <ListItemText
              primary="Action"
              primaryTypographyProps={{ style: { fontWeight: 600 } }}
            />
          </ListItem>
          <Box sx={{ width: 260 }}>
            <List>
              <ListItem
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handlePopClose();
                  handleClick(currentData?.link);
                }}
              >
                <Box
                  sx={{
                    marginRight: "40px",
                    bgcolor: "#E0E0E0C2",
                    borderRadius: 50,
                    padding: "10px",
                  }}
                >
                  <LinkIcon />
                </Box>
                <ListItemText
                  primary="Link"
                  primaryTypographyProps={{ style: { fontWeight: 600 } }}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem
                onClick={() => {
                  handleApires(currentData);
                  handlePopClose();
                }}
              >
                <Box
                  sx={{
                    marginRight: "40px",
                    bgcolor: "#E0E0E0C2",
                    borderRadius: 50,
                    padding: "10px",
                  }}
                >
                  <VisibilityTwoToneIcon />
                </Box>
                <ListItemText
                  primary="Show Execution"
                  primaryTypographyProps={{ style: { fontWeight: 600 } }}
                />
              </ListItem>
              <Divider component="li" />{" "}
              <ListItem
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handlePopClose();
                  handleChnageStatus(currentData);
                }}
              >
                <Box
                  sx={{
                    marginRight: "40px",
                    bgcolor: "#E0E0E0C2",
                    borderRadius: 50,
                    padding: "10px",
                  }}
                >
                  <AutorenewIcon />
                </Box>
                <ListItemText
                  primary="Change Status"
                  primaryTypographyProps={{ style: { fontWeight: 600 } }}
                />
              </ListItem>
              {/* <Divider component="li" />{" "}
              <ListItem onClick={handlePopClose}>
                <Box
                  sx={{
                    marginRight: "40px",
                    bgcolor: "#E0E0E0C2",
                    borderRadius: 50,
                    padding: "10px",
                  }}
                >
                  <BorderColorIcon />
                </Box>
                <ListItemText
                  primary="Modify"
                  primaryTypographyProps={{ style: { fontWeight: 600 } }}
                />
              </ListItem>
              <Divider component="li" />{" "}
              <ListItem onClick={handlePopClose}>
                <Box
                  sx={{
                    marginRight: "40px",
                    bgcolor: "#E0E0E0C2",
                    borderRadius: 50,
                    padding: "10px",
                  }}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </Box>
                <ListItemText
                  primaryTypographyProps={{ style: { fontWeight: 600 } }}
                  primary="Delete"
                />
              </ListItem> */}
            </List>
          </Box>
        </Popover>
        <Modal onClose={() => setIsOpen(false)} open={isOpen}>
          <Box sx={{ margin: "auto", position: "relative" }}>
            <Typography
              component="h1"
              backgroundColor="white"
              variant="h6"
              align="center"
              gutterBottom
            >
              Excutions
              <Box
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "0px",
                  color: "red",
                }}
                onClick={() => setIsOpen(false)}
              >
                Close
              </Box>
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-center">No.</TableCell>
                  <TableCell className="text-center">Order</TableCell>
                  <TableCell className="text-center">Time Stamp</TableCell>
                  <TableCell className="text-center">Order Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apires?.length > 0 &&
                  [...apires].reverse().map((item, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }} // Alternating colors
                    >
                      <TableCell className="text-center">{index + 1}</TableCell>
                      <TableCell className="text-center">
                        {item?.response?.order}
                      </TableCell>
                      <TableCell className="text-center">
                        {item?.timestamp}
                      </TableCell>
                      <TableCell className="text-center">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleOrderStatus(item)}
                        >
                          Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Modal>
        <Modal
          onClose={() => setDeleteConfirmationOpen(false)}
          open={isDeleteConfirmationOpen}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 2,
              borderRadius: "8px",
              width: "60%",
            }}
          >
            <Typography variant="h6" align="center" gutterBottom>
              Confirm Deletion
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              Are you sure you want to delete the service:{" "}
              {serviceToDelete?.name}?
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Button onClick={confirmDelete} variant="contained" color="error">
                Yes
              </Button>
              <Button
                onClick={() => setDeleteConfirmationOpen(false)}
                variant="contained"
              >
                No
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal onClose={() => setIsDetailsStatus(false)} open={isDetailsStatus}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              boxShadow: 24,
              p: 2,
              borderRadius: "8px",
              width: "60%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-center">Status</TableCell>
                  <TableCell className="text-center">Remaining</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ backgroundColor: "#f2f2f2" }}>
                  <TableCell className="text-center">
                    <b>{orderStatus?.status}</b>{" "}
                    {orderStatus?.status === "Completed" ? (
                      <CloudDoneIcon style={{ color: "#32e620" }} />
                    ) : orderStatus?.status === "Pending" ? (
                      <HourglassBottomIcon style={{ color: "orange" }} />
                    ) : null}
                    {"   "}
                  </TableCell>
                  <TableCell className="text-center">
                    {orderStatus?.remains}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default Chistory;
