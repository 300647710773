import { Grid, Typography, Box } from "@mui/material";
import React from "react";

function WalletBankStatementInfoCard({
  WalletImg,
  WalletTitle,
  WalletDate,
  WalletOrderId,
}) {
  return (
    <>
      <Box component="div" width="420px" he>
        <Grid container spacing={2}>
          <Grid
            item
            md={2}
            className="d-flex justify-content-center align-items-center"
          >
            <Box
              className="bg-dark-subtle"
              sx={{
                borderRadius: "50%",
                height: "55px",
                width: "55px",
                padding: "11px",
              }}
            >
              {" "}
              <img
                src={WalletImg}
                alt="wallet-img"
                width="60px"
                className="mx-auto"
              />
            </Box>
          </Grid>
          <Grid item md={10}>
            <Box>
              <Typography variant="subtitle2" className="text-start mt-1">
                {WalletTitle}
              </Typography>
              <Typography
                className="text-start"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                {WalletDate}
              </Typography>
              <Typography
                className="text-start mt-1"
                sx={{ fontSize: "12px", color: "gray" }}
              >
                {WalletOrderId}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default WalletBankStatementInfoCard;