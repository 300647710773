import React from "react";
import {
    Card,
    CardContent,
    CardMedia,
    Typography ,
    Box,
    Button,
    Grid,
    Divider,
    Avatar,
    Rating,
    
   } from "@mui/material";
   import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
   import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
   import img from "./image9.png";
   import avt1 from "./image10.png";
   import avt2 from "./image11.png";
   import "./Website.css";

function Website(){

    return(
        <>
            <Box>
            <div style={{ position: "relative" }}>
            <CardMedia style={{ height: "auto"}}  component="img" image={img} title="Image" alt="Image"/> 
            <Typography  variant="h1" style={{position: "absolute",textAlign:"center", fontWeight:"bold", color: "white",top: 550,left: "70%",transform: "translateX(-50%)",}}>Unleash Creativity<Typography>Push Ads with Ease<br/><Button sx={{paddingLeft:12, paddingRight:12, marginTop:2}} variant="contained">Get Started</Button></Typography></Typography>
            </div>
            </Box>

            <Box sx={{bgcolor:'#64b5f6'}}>
                <Typography variant="h2" sx={{textAlign:"center", fontWeight:"bold", color: "black"}}>Efferless Design</Typography>
                <Typography sx={{textAlign:"center", color: "black"}}>Customize with precision</Typography>
                <Grid container sx={{paddingTop:2 }}>
                    <Grid sx={{backgroundColor:"white", borderRadius:2,height:180, width:300,margin:5}}>
                        <Box>
                        <CameraAltOutlinedIcon fontSize="large" sx={{marginLeft:17, marginTop:3, marginBottom:2}}/>
                        <Typography variant="h5" sx={{textAlign:"center"}}>Multi-Platform Sync</Typography>
                        <Typography sx={{textAlign:"center"}}>Manage ads across platforms.</Typography>
                        </Box>
                    </Grid>
                    <Grid sx={{backgroundColor:"white", borderRadius:2,height:180, width:300,margin:5}}>
                        <Box>
                        <CameraAltOutlinedIcon fontSize="large" sx={{marginLeft:17, marginTop:3, marginBottom:2}}/>
                        <Typography variant="h5" sx={{textAlign:"center"}}>Instant Previews</Typography>
                        <Typography sx={{textAlign:"center"}}>See changes in real-time.</Typography>
                        </Box>
                    </Grid>
                    <Grid sx={{backgroundColor:"white", borderRadius:2,height:180, width:300,margin:5}}>
                        <Box>
                        <CameraAltOutlinedIcon fontSize="large" sx={{marginLeft:17, marginTop:3, marginBottom:2}}/>
                        <Typography variant="h5" sx={{textAlign:"center"}}>Advance Analytics</Typography>
                        <Typography sx={{textAlign:"center"}}>Track Ad performance easily.</Typography>
                        </Box>
                    </Grid>
                    
                </Grid>
            </Box>

            <Box sx={{backgroundColor:"rgb(22, 20, 112)",paddingTop:5,height:450}}>
                <Typography variant="h2" sx={{color:"white",textAlign:"center",paddingBottom:3}}>Simple Campaign Setup</Typography>

                <Box className="camp">
                    <Box>
                        <Typography variant="h2" sx={{color:"white",marginLeft:30}}>1</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{color:'rgb(194, 193, 190)',marginLeft:40}}>Choose Ad Format</Typography>
                        <Typography sx={{color:"white",marginLeft:40}}>Select from templates or custom.</Typography>
                    </Box>
                </Box>
                <Divider variant="middle" component="li"/>
                <Box className="camp">
                    <Box>
                        <Typography variant="h2" sx={{color:"white",marginLeft:30}}>2</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{color:'rgb(194, 193, 190)',marginLeft:40}}>Target Audience</Typography>
                        <Typography sx={{color:"white",marginLeft:40}}>Refine reach by demographics.</Typography>
                    </Box>
                </Box>
                <Divider variant="middle" component="li"/>
                <Box className="camp">
                    <Box>
                        <Typography variant="h2" sx={{color:"white",marginLeft:30}}>3</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{color:'rgb(194, 193, 190)',marginLeft:40}}>Target Audience</Typography>
                        <Typography sx={{color:"white",marginLeft:40}}>Refine reach by demographics.</Typography>
                    </Box>
                </Box>
                <Divider variant="middle" component="li"/>
            </Box>

            <Box className="transparent" sx={{backgroundColor:"rgb(55, 90, 138)",height:500}}>
                <Box >
                    <Typography variant="h2" sx={{color:"white"}}>Transparent <br/> Pricing</Typography>
                    <Typography sx={{color:"white"}}>Competitive rates, no hidden fees.</Typography>
                </Box>
                <Box>
                    <Typography>Plans Start At</Typography>
                    <Typography variant="h2" sx={{color:"white"}}>₹ 9999/month</Typography>
                </Box>
                <Box>
                <Box sx={{color:"white"}}><CheckCircleOutlineRoundedIcon/> Unlimited Revisions</Box> <br/>
                <Box sx={{color:"white"}}><CheckCircleOutlineRoundedIcon/>Robust Data Security</Box> <br/>
                <Box sx={{color:"white"}}><CheckCircleOutlineRoundedIcon/>Priority Customer Support</Box> <br/>
                <Box sx={{color:"white"}}><CheckCircleOutlineRoundedIcon/>API & Plugin Access</Box> 
                </Box>
            </Box>

            <Box sx={{backgroundColor:"rgb(129, 199, 252)",height:450}}>
                <Typography variant="h2" sx={{textAlign:"center", font:"bold",paddingTop:2, paddingBottom:3}}>Customer Testimonials</Typography>
<Box className="main-testi">
                <Box className="testi" sx={{backgroundColor:"rgb(36, 123, 189)",height:250, width:500, borderRadius:2, paddingLeft:5, alignItems:"center"}}>
                <Box>
                <Avatar alt="Jane Doe" src={avt1} sx={{height:100, width:100}} /> <br/>
                <Typography variant="h5" sx={{color:"white"}}>Jane Doe</Typography>
                <Typography sx={{color:"white"}}>Marketing Lead,<br/> TechCorp</Typography>
                </Box>
                
                <Box>
                <Rating name="no-value" value={null} />
                <Typography sx={{color:"white"}}>“AdCrafters made our ad campaigns effortless with their intuitive design and powerful analytics. Highly recommended!”</Typography>
                </Box>
                </Box>

                
                <Box className="testi" sx={{backgroundColor:"rgb(36, 123, 189)",height:250, width:500, borderRadius:2, paddingLeft:5, alignItems:"center"}}>
                <Box>
                <Avatar alt="Jane Doe" src={avt2} sx={{height:100, width:100}} /> <br/>
                <Typography variant="h5" sx={{color:"white"}}>John Smith</Typography>
                <Typography sx={{color:"white"}}>Founder, StartupX</Typography>
                </Box>
                
                <Box>
                <Rating name="no-value" value={null} />
                <Typography sx={{color:"white"}}>“The multi-platform synchronization saved us time and increased our productivity. Fantastic tool for marketers!”</Typography>
                </Box>
                </Box>
                </Box>
            </Box>

        </>
    )
}

export default Website;