import React from "react";
import "./OutdoorAdvertising.css";
import image2 from "./image2.jpg";
import AutoRikshaw from "./image3.jpg";
import Billbords from "./image4.jpg";
import BusStand from "./image5.jpg";
import Cab from "./image6.jpg";
import BusBrand from "./image7.jpg";
import MobileVan from "./image8.jpg";
import {


  Card,

  CardContent,
  CardMedia,
  Typography ,
  Box,
 } from "@mui/material";

function OutdoorAdvertising(){
 
    return(
      <>
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2, bgcolor:'#64b5f6' }}>
        Outdoor Advertising
      </Typography>

          <Box sx={{ display: 'flex',bgcolor:'#90caf9' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h3" color="white">
                A Perfect Place To<br/>
                Launch Your Outdoor<br/>
                Marketing Campaign
                </Typography><br/>
                <Typography color="black" component="div">
                OOH advertising, one of the popular forms of advertising, allows<br/>
                 businesses to reach a wide audience while they are out of their<br/>
                homes. It is highly targeted with the help of our advertising<br/>
                services, as businesses can choose specific locations and<br/>
                formats to<br/>
                reach their target audience. Importantly, it raises effective brand<br/>
                awareness by reaching people during their daily commutes,<br/>
                traveling, shopping trips, or errand runs. With an end-to-end<br/>
                outdoor advertising agency like BuyMediaSpace in India by your<br/>
                side, you can search inventory, plan and execute outdoor<br/>
                campaigns like never before.
                </Typography>
              </CardContent>
              
            </Box>
            <CardMedia
              component="img"
              sx={{ width: 500, borderRadius: 3, margin:5}}
              src={image2}
              alt="Live from space album cover"
            />
          </Box>
          <Box className="box2">
            <Box>
          <Typography className="heading" variant="h3" sx={{ mt: 35,ml:5, bgcolor:'rgb(99, 140, 176)', color:'white'}}>
        Outdoor <br/>Advertising <br/>Option
      </Typography>
      <Typography sx={{ml:7,mt:7,color:'white'}}>
      Transit or Static, we've got every type of tailor-<br/>
      made outdoor advertising campaign just for you !
      </Typography>
      
      </Box>
      
        <Box className="image-grid" sx={{margin:5, height:900, width:600}}>
            <Box sx={{backgroundColor:'white',p:2, borderRadius:2}}>
            <Box
              component="img"
              src={AutoRikshaw}
              alt="AutoRikshaw"
            />
            <Typography variant="p" component="div" textAlign={"center"}>
            AutoRikshaw
          </Typography>
          </Box>

          <Box sx={{backgroundColor:'white',p:2, borderRadius:2}}>
            <Box
              component="img"
              src={Billbords}
              alt="Billbords"
            />
            <Typography variant="p" component="div" textAlign={"center"}>
            Billbords
          </Typography>
          </Box>
          <Box sx={{backgroundColor:'white',p:2, borderRadius:2}}>
            <Box
              component="img"
              src={BusStand}
              alt="BusStand"
            />
            <Typography variant="p" component="div" textAlign={"center"}>
            BusStand
          </Typography>
          </Box>
          <Box sx={{backgroundColor:'white',p:2, borderRadius:2}}>
            <Box
              component="img"
              src={Cab}
              alt="Cab"
            />
            <Typography variant="p" component="div" textAlign={"center"}>
            Cab
          </Typography>
          </Box>
          <Box sx={{backgroundColor:'white',p:2, borderRadius:2}}>
            <Box
              component="img"
              src={BusBrand}
              alt="BusBrand"
            />
            <Typography variant="p" component="div" textAlign={"center"}>
            BusBrand
          </Typography>
          </Box>
          <Box sx={{backgroundColor:'white',p:2, borderRadius:2}}>
            <Box
              component="img"
              src={MobileVan}
              alt="MobileVan"
            />
            <Typography variant="p" component="div" textAlign={"center"}>
            MobileVan
          </Typography>
          </Box>
            
        </Box>
    </Box>
    

    </>
    )
}

export default OutdoorAdvertising;