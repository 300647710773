import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  IconButton,
  Paper,
  Grid,
  Avatar,
  Box,
  LinearProgress,
  Modal,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { getUserFromLocalStorage } from "../service/localStorage";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const UpdatedSite = () => {
  const [allPnsSites, setAllPnsSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSite, setCurrentSite] = useState({});
  const [formData, setFormData] = useState({});
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState(null);
  const {
    data: { access_token },
  } = getUserFromLocalStorage();
  const URL = process.env.REACT_APP_PROD_API;

  const navigate = useNavigate();

  const handleNavigateAddNewSite = () => {
    navigate("/aff-push/Addnewsite");
  };

  const fetchAllPnsSite = async () => {
    try {
      const response = await axios.get(`${URL}/api/pns/get_pns_site`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      setAllPnsSites(response.data);
    } catch (error) {
      console.error("Error fetching PNS sites:", error);
      toast.error("Failed to fetch PNS sites.");
    } finally {
      setLoading(false);
    }
  };

  const refreshAllPnsSite = async () => {
    setButtonLoading(true);
    try {
      const response = await axios.get(`${URL}/api/pns/check_pns`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response) {
        toast.success("PNS sites updated successfully.");
        fetchAllPnsSite(); // Refresh the PNS sites list after updating
      }
    } catch (error) {
      console.error("Error refreshing PNS sites:", error);
      toast.error("Failed to refresh PNS sites.");
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPnsSite();
  }, []);

  const handleEditClick = (site) => {
    setCurrentSite(site);
    setFormData(site);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleDeleteClick = (site) => {
    setSiteToDelete(site);
    setDeleteConfirmOpen(true);
  };
  const handleCloseDeleteConfirm = () => {
    setDeleteConfirmOpen(false);
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async () => {
    const {
      website_name,
      website_brief,
      website_tagline,
      estimate_visitors,
      audience_type,
      category,
      geo,
    } = formData;
    const updatedData = {
      website_name,
      website_brief,
      website_tagline,
      estimate_visitors,
      audience_type,
      category,
      geo,
    };
    try {
      const response = await axios.put(
        `${URL}/api/pns/update_pns_site?website_url=${formData.website_url}`, // Update PNS site API endpoint
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Site updated successfully.");
        setModalOpen(false);
        fetchAllPnsSite();
      }
    } catch (error) {
      console.error("Error updating site:", error);
      toast.error("Failed to update site.");
    }
  };
  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `${URL}/api/pns/delete_pns_site?website_url=${siteToDelete.website_url}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Site deleted successfully.");
        setDeleteConfirmOpen(false);
        fetchAllPnsSite();
      }
    } catch (error) {
      console.error("Error deleting site:", error);
      toast.error("Failed to delete site.");
    }
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #A5BFD9 9.55%, #A7D3FF 100%)",
        p: 2,
        height: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            color: "#12314F",
            fontWeight: "bold",
            fontSize: { xs: "14px", md: "18px" },
          }}
        >
          Manage Site
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: { xs: 1, md: 3 },
          }}
        >
          <Button
            sx={{
              fontSize: { xs: "11px", md: "16px" },
              color: "#FFFF",
              bgcolor: "#445490",
              "&:hover": {
                bgcolor: "#445491",
              },
            }}
            onClick={handleNavigateAddNewSite}
          >
            Add New Site
          </Button>
          {buttonLoading ? (
            <Button
              sx={{
                fontSize: { xs: "11px", md: "16px" },
                color: "#0000",
                bgcolor: "#f4f4f4",
                "&:hover": {
                  bgcolor: "#f4f4f4",
                },
              }}
              disabled
            >
              Loading...
            </Button>
          ) : (
            <Button
              onClick={refreshAllPnsSite}
              sx={{
                fontSize: { xs: "11px", md: "16px" },
                color: "#000",
                bgcolor: "#fff",
                "&:hover": {
                  bgcolor: "#fff",
                },
              }}
            >
              Refresh Sites
            </Button>
          )}
        </Box>
      </Box>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          {allPnsSites.map((site, index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
              <Paper
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: 3,
                  position: "relative",
                }}
              >
                <IconButton
                  sx={{ position: "absolute", top: 8, right: 40 }}
                  size="small"
                  color="primary"
                  onClick={() => handleDeleteClick(site)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  sx={{ position: "absolute", top: 8, right: 8 }}
                  size="small"
                  color="primary"
                  onClick={() => handleEditClick(site)}
                >
                  <EditIcon />
                </IconButton>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar
                      src={site.favicon_url || "default-favicon-url"}
                      sx={{ width: 110, height: 110, borderRadius: 1 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    container
                    direction="column"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="h6">
                        {site.website_name || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {site.website_brief || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item container alignItems="center">
                      <Typography variant="body2">
                        {site.website_tagline || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        <a
                          href={site.website_url || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {site.website_url || "N/A"}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container spacing={1}>
                    <Grid item xs={6} sm={4}>
                      <Typography variant="caption" color="textSecondary">
                        Estimate Visitors
                      </Typography>
                      <Typography variant="body2">
                        {site.estimate_visitors || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography variant="caption" color="textSecondary">
                        Audience Type
                      </Typography>
                      <Typography variant="body2">
                        {site.audience_type || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography variant="caption" color="textSecondary">
                        Category
                      </Typography>
                      <Typography variant="body2">
                        {site.category || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Typography variant="caption" color="textSecondary">
                        Location
                      </Typography>
                      <Typography variant="body2">
                        {site.geo || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textSecondary">
                        Last Updated
                      </Typography>
                      <Typography variant="body2">
                        {site.timestamp || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, md: 600 },
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" mb={2}>
            Edit Site
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Website Name"
                name="website_name"
                value={formData.website_name || ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Website Brief"
                name="website_brief"
                value={formData.website_brief || ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Website Tagline"
                name="website_tagline"
                value={formData.website_tagline || ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Estimate Visitors"
                name="estimate_visitors"
                value={formData.estimate_visitors || ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Audience Type"
                name="audience_type"
                value={formData.audience_type || ""}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Location"
                name="geo"
                value={formData.geo || ""}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  value={formData.category || ""}
                  onChange={handleInputChange}
                  name="category"
                  fullWidth
                >
                  {[
                    "technology",
                    "news",
                    "entertainment",
                    "ecommerce",
                    "health",
                    "other",
                  ].map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3, textAlign: "right" }}>
            <Button
              onClick={handleFormSubmit}
              variant="contained"
              color="primary"
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleCloseDeleteConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this site?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UpdatedSite;
