import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HubIcon from "@mui/icons-material/Hub";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Helmet } from "react-helmet-async";

function OfferDetails() {
  const theme = useTheme();
  const { name } = useParams();
  const Affiliate_Admin_URL = process.env.REACT_APP_PROD_ADMIN_API;
  const [offerDetailsObject, setOfferDetailsObject] = useState({});
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const displayDescription = () => {
    if (!offerDetailsObject || !offerDetailsObject.description) {
      return "Description not available";
    }

    const lines = offerDetailsObject.description.split("\n");
    if (showFullDescription) {
      return lines.join("\n");
    } else {
      return lines.slice(0, 3).join("\n");
    }
  };
  const linearGradient1 =
    "linear-gradient(to bottom, #6683b1, #7b91b9, #8fa0c0, #a3afc8, #b6becf, #bcc4d5, #c3cbdb, #c9d1e1, #c4cfe7, #bfcdee, #bacbf4, #b6c9fa)";

  const getCampOfferDetailsData = async () => {
    const apiUrl = `${Affiliate_Admin_URL}/campaign/by-name/${name}`;
    try {
      const response = await axios.get(apiUrl);
      if (response.data) {
        setOfferDetailsObject(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCampOfferDetailsData();
  }, [name]);

  return (
    <>
     <Helmet>
  <title>{`${name} Affiliate Program`}</title>
  <meta name="description" content={offerDetailsObject.description} />
  <meta name="theme-color" content="#008f68" />
</Helmet>


      <Box
        sx={{
          padding: { xs: "18px 9px", md: "18px 10px" },
          backgroundImage: linearGradient1,
        }}
      >
        <Box className="">
          <Grid container spacing={{ xs: 3, md: 0 }}>
            {/* Left side */}
            <Grid
              item
              lg={4}
              md={4}
              xs={12}
              className=" d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3, // Increased padding for better content spacing
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                }}
              >
                <Box className="main">
                  <Box
                    sx={{
                      width: 120,
                      height: 120,
                      marginX: "auto",
                      borderRadius: 2,
                    }}
                  >
                    <img
                      src={offerDetailsObject?.CampaignPhoto}
                      alt="CampaignPhoto"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                  <Box className="mt-3">
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#44444",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {offerDetailsObject?.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      mt={1}
                      sx={{ color: "#666666", textAlign: "center" }}
                    >
                      Website: https://www.affiliates.affworld.in
                    </Typography>

                    <Box className="mt-3 text-center">
                      <Button variant="contained">
                        Join Affiliate Program
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {/* Right side */}
            <Grid
              item
              xs={12}
              md={8}
              className="d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3, // Increased padding for better content spacing
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "#44444", fontWeight: "bold" }}
                >
                  {offerDetailsObject?.name} Affiliate Program
                </Typography>
                <div>
                  <Typography variant="body1" mt={2} sx={{ color: "#666666" }}>
                    {displayDescription()}
                  </Typography>
                  {offerDetailsObject && offerDetailsObject.description && (
                    <Typography
                      variant="body2"
                      mt={2}
                      sx={{
                        color: "#1976d2",
                        fontWeight: "bold",
                        cursor: "pointer",
                        overflow: "hidden",
                        transition: "height 0.3s ease",
                        maxHeight: "150px",
                      }}
                      onClick={toggleDescription}
                    >
                      {showFullDescription ? (
                        <RemoveCircleOutlineIcon
                          fontSize="small"
                          sx={{ marginRight: 0.5 }}
                        />
                      ) : (
                        <ControlPointIcon
                          fontSize="small"
                          sx={{ marginRight: 0.5 }}
                        />
                      )}
                      {showFullDescription ? "Show Less" : "Read More"}
                    </Typography>
                  )}
                </div>
                <Typography variant="body2" mt={2} sx={{ color: "#666666" }}>
                  <span className="fw-bold">Category:</span>{" "}
                  {offerDetailsObject?.category}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box className="mt-3">
          <Grid container spacing={{ xs: 3, md: 0 }}>
            {/* Left side */}
            <Grid
              item
              lg={4}
              md={4}
              xs={12}
              className=" d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3, // Increased padding for better content spacing
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                }}
              >
                <Box className="main">
                  <Typography
                    variant="h6"
                    sx={{ color: "#212B36", fontWeight: "bold" }}
                  >
                    Payout: Upto ₹90 / Action*
                  </Typography>
                  <Typography variant="body1" mt={2} sx={{ color: "#666666" }}>
                    *Increment on volume & advertiser approval
                  </Typography>
                  <Box className="mt-3">
                    <Box>
                      <Box className="d-flex">
                        <p style={{ width: "65%" }}>Campaign Type </p>{" "}
                        <p className="fw-bold">
                          {offerDetailsObject.type
                            ? offerDetailsObject.type
                            : "NA"}
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <p style={{ width: "65%" }}>CAMPAIGN MODEL</p>{" "}
                        <p className="fw-bold">
                          {offerDetailsObject.Model
                            ? offerDetailsObject.Model
                            : "NA"}
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <p style={{ width: "65%" }}>Validation Time </p>{" "}
                        <p className="fw-bold">
                          {offerDetailsObject.validation_time
                            ? offerDetailsObject.validation_time
                            : "NA"}
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <p style={{ width: "65%" }}>Avg. Earning Per Click</p>{" "}
                        <p className="fw-bold">₹0.22</p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <p style={{ width: "65%" }}>Payment Frequency </p>{" "}
                        <p className="fw-bold">
                          <p className="fw-bold">
                            {offerDetailsObject.payment_frequency
                              ? offerDetailsObject.payment_frequency
                              : "NA"}
                          </p>
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <p style={{ width: "65%" }}>Joining Fee </p>
                        <p className="fw-bold">
                          {" "}
                          {offerDetailsObject.joining_fee
                            ? offerDetailsObject.joining_fee
                            : "NA"}
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <p style={{ width: "65%" }}>Reporting Type </p>{" "}
                        <p className="fw-bold">
                          {offerDetailsObject.reporting_type
                            ? offerDetailsObject.reporting_type
                            : "NA"}
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <p style={{ width: "65%" }}>Country </p>
                        <p className="fw-bold">
                          {offerDetailsObject.country
                            ? offerDetailsObject.country
                            : "NA"}
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {/* Right side */}
            <Grid
              item
              xs={12}
              md={8}
              className="d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3,
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "0.7",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "#212B36", fontWeight: "bold" }}
                >
                  Affiliate Link Builder
                </Typography>
                <Box className="d-flex justify-content-center align-items-center   h-100">
                  <Paper
                    elevation={7}
                    sx={{
                      width: { xs: "100%", md: "100%" },
                      marginX: "auto",
                      border: "1px solid gray",
                      bgcolor: "white",
                      p: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#212B36",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Register to Generate your Own Affiliate Links
                    </Typography>
                    <Box className="mt-4 text-center">
                      <Button
                        variant="contained"
                        sx={{ fontSize: { xs: "12px", md: "16px" } }}
                      >
                        Register for this Affiliate Program
                      </Button>
                    </Box>
                  </Paper>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box className="mt-3">
          <Grid container spacing={{ xs: 3, md: 0 }}>
            {/* Left side */}
            <Grid
              item
              lg={4}
              md={4}
              xs={12}
              className=" d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3,
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                }}
              >
                <Box className="main">
                  <Typography
                    variant="h6"
                    sx={{ color: "#212B36", fontWeight: "bold" }}
                  >
                    {offerDetailsObject.name}Conversion Flows
                  </Typography>

                  <Box className="mt-3">
                    <Box>
                      <Box className="px-3">
                        <ul>
                          {offerDetailsObject.conversion_flows
                            ? offerDetailsObject.conversion_flows
                            : "Not Available"}
                        </ul>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {/* Right side */}
            <Grid
              item
              xs={12}
              md={8}
              className="d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3, // Increased padding for better content spacing
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "#212B36", fontWeight: "bold" }}
                >
                  {offerDetailsObject?.name} Affiliate Payout Commission
                  Structure
                </Typography>

                <Box className="mt-3">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              border: "1px solid black",
                              bgcolor: "transparent",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            Category
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid black",
                              bgcolor: "transparent",
                              color: "#666666",
                            }}
                          >
                            Payout
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              border: "1px solid black",
                              bgcolor: "transparent",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            Deposit
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "1px solid black",
                              bgcolor: "transparent",
                              color: "#666666",
                            }}
                          >
                            ₹ 72
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box className="mt-3">
                  <Typography>
                    <span className="fw-bold">*Note:</span> The above commission
                    rate is the base rate, further based on the volume &
                    performance the commissions are incremented. Please talk you
                    your account manager for more assistance.
                  </Typography>
                </Box>
                {/* <Box className="mt-3">
                  <Paper
                    elevation={7}
                    sx={{
                      width: { xs: "100%", md: "100%" },
                      marginX: "auto",
                      border: "1px solid gray",
                      bgcolor: "white",
                      p: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#212B36",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Website/App Affiliation Eligblity Checker
                    </Typography>
                    <Box className="mt-4 d-flex justify-content-center  gap-2">
                      <input
                        type="text"
                        className="form-control w-75"
                        placeholder="Enter your website/app link eg: https://dailydeals.com "
                        style={{ fontSize: "15px" }}
                      />
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "#364A6F",
                          color: "#fff",
                          width: { xs: "150px", md: "200px" },
                        }}
                      >
                        <HubIcon style={{ marginRight: "7px" }} />
                        <Typography
                          variant="button"
                          sx={{
                            fontSize: { md: "16px", xs: "12px" },
                            whiteSpace: "nowrap",
                          }}
                        >
                          Copy Link
                        </Typography>
                      </Button>
                    </Box>
                  </Paper>
                </Box> */}
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box className="mt-3">
          <Grid container spacing={{ xs: 3, md: 0 }}>
            {/* Left side */}
            <Grid
              item
              lg={4}
              md={4}
              xs={12}
              className=" d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3,
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                }}
              >
                <Box className="main">
                  <Typography
                    variant="h6"
                    sx={{ color: "#212B36", fontWeight: "bold" }}
                  >
                    Addons with Spartan Poker Campaign
                  </Typography>

                  <Box className="mt-3">
                    <Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Spartan Poker Banner Ad Generator{" "}
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Plug & Play auto updating Banner ads
                        </Box>{" "}
                        <p className="fw-bold">
                          {" "}
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          CPA + CPV Hybrid Payout Support{" "}
                        </Box>{" "}
                        <p className="fw-bold">
                          {" "}
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Deeplink to Short Link Converter
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Access to Latest Merchant Coupons{" "}
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Merchant API for Custom Integration{" "}
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Deal Feeds for Latest Deals & Offers
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Affiliate Link via Browser Extension
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Wordpress Plugin for auto Integration
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          JS based Auto Affiliate Link Builder
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          On the Fly Affiliate Link Generator
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Search Bar Widget Integration
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Access to Merchant Google Analytics
                        </Box>{" "}
                        <p className="fw-bold">
                          <CloseIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "red" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Publisher logo on Merchant Website
                        </Box>{" "}
                        <p className="fw-bold">
                          <CloseIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "red" }}
                          />
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {/* Right side */}
            <Grid
              item
              xs={12}
              md={8}
              className="d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3, // Increased padding for better content spacing
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "#212B36", fontWeight: "bold" }}
                >
                  Terms and Conditions
                </Typography>
                <Typography variant="body1" mt={2} sx={{ color: "#666666" }}>
                  → Tracking Information
                </Typography>
                <Box
                  sx={{
                    width: { xs: "90px", md: "120px" },
                    height: { xs: "90px", md: "120px" },
                    marginX: "auto",
                    borderRadius: 2,
                    position: "absolute",
                    top: { xs: 0.4, md: 0 },
                    right: { xs: 2, md: 10 },
                  }}
                >
                  <img
                    src={offerDetailsObject?.CampaignPhoto}
                    alt="CampaignPhoto"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <Box className="mt-4 bg-black">
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Reporting
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#337AB7",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Offline(Reports get updated within 28-48hrs)
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Cookie Duration
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#337AB7",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          30 Days
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Deeplink
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#337AB7",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          No
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Mobile Web
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#337AB7",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          YES
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Desktop
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#337AB7",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          YES
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Location
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#337AB7",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Pan India
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Income Group
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#337AB7",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          5L and above
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Payment Time
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#337AB7",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          45 - 60 Days
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box className="mt-3">
          <Grid container spacing={{ xs: 3, md: 0 }}>
            {/* Left side */}
            <Grid
              item
              lg={4}
              md={4}
              xs={12}
              className=" d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3,
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                }}
              >
                <Box className="main">
                  <Typography
                    variant="h6"
                    sx={{ color: "#212B36", fontWeight: "bold" }}
                  >
                    Additional Benefits with {offerDetailsObject?.name}
                  </Typography>

                  <Box className="mt-3">
                    <Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Highest no of Advertisers on Single Platform
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Automatic Link Routing for Maximum Payout
                        </Box>{" "}
                        <p className="fw-bold">
                          {" "}
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          24x7 Campaign Monitoring
                        </Box>{" "}
                        <p className="fw-bold">
                          {" "}
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Realtime Link Click Analytics
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          100% Payout for Amazon Affiliate
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          One Click Universal Link Generator
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Auto updating Affiliate Ads Banners
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Access to Multiple Automated Plugin & Tools
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Friendly Phone/Email Support
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Handpicked Top Converting Offers
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>{" "}
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Single Point of Reporting & Payout
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Hybrid Model for Maximum ROI
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Deal & Coupon Feed
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          Access to various API’s
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                      <Box className="d-flex">
                        <Box sx={{ width: { xs: "85%", md: "100%" } }}>
                          100% Free Forever
                        </Box>{" "}
                        <p className="fw-bold">
                          <CheckIcon
                            fontSize="medium"
                            style={{ fontWeight: "bold", color: "green" }}
                          />
                        </p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {/* Right side */}
            <Grid
              item
              xs={12}
              md={8}
              className="d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3, // Increased padding for better content spacing
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "#212B36", fontWeight: "bold" }}
                >
                  Terms and Conditions
                </Typography>
                <Typography variant="body1" mt={2} sx={{ color: "#666666" }}>
                  →   Traffic Source
                </Typography>
                <Box
                  sx={{
                    width: { xs: "90px", md: "120px" },
                    height: { xs: "90px", md: "120px" },
                    marginX: "auto",
                    borderRadius: 2,
                    position: "absolute",
                    top: { xs: 0.4, md: 0 },
                    right: { xs: 2, md: 10 },
                  }}
                >
                  <img
                    src={offerDetailsObject?.CampaignPhoto}
                    alt="CampaignPhoto"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
                <Box className="mt-4 bg-black">
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Cashback
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.cashback ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          PopUp / ClickUnder
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.popunder_clickunder ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Behavioural Retargeting
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.behavioural_retargeting ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          SEM
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.sem ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          SMS
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.sms ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Email
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.email ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Brand Bidding
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.brand_bidding ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Social Media (ads)
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.social_media_ads ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Incentive
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.incentive ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Toolbar
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.toolbar ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Adult
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.adult ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} md={4}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                          display: { xs: "flex", md: "block" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#292929",
                            fontWeight: "bold",
                            fontSize: { xs: "11px", md: "16px" },
                          }}
                        >
                          Youtube
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Paper
                        sx={{
                          height: "100%",
                          width: "100%",
                          p: { xs: 1, md: 2 },
                          border: "1px solid black",
                          borderRadius: 0,
                        }}
                      >
                        {offerDetailsObject.youtube ? (
                          <Typography
                            sx={{
                              color: "green",
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                            }}
                          >
                            Allowed
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: "11px", md: "16px" },
                              color: "red",
                            }}
                          >
                            Not Allowed
                          </Typography>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box className="mt-3">
          <Grid container spacing={{ xs: 3, md: 0 }}>
            {/* Left side */}
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className=" d-flex justify-content-center align-items-center"
            >
              <Paper
                elevation={6}
                sx={{
                  p: 3, // Increased padding for better content spacing
                  height: "100%",
                  width: "100%",
                  margin: 1,
                  backgroundColor: "#ffff",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  opacity: "70%",
                }}
              >
                <Box className="main">
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#44444",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Advantages of using Affworld Tech. Affiliation via Affworld
                  </Typography>
                </Box>
                <Typography variant="body1" mt={2} sx={{ color: "#666666" }}>
                  This affiliate program is a part of the INRDeals affiliate
                  network. After signing up for Spartan Poker Campaign via
                  INRDeals you won‘t have to sign up for any other network
                  anymore. No more applying for programs or searching for the
                  best Payouts as INRDeals offers fully{" "}
                  <span className="fw-bold">
                    managed Affiliate Marketing services
                  </span>{" "}
                  that yield maximum returns with fastest payments all on a
                  single platform. Our technology works across sites, apps, and
                  social networks so you can focus on your business, earn more,
                  and avoid the hassle of managing countless affiliate programs.
                  Still not convinced ?{" "}
                  <span className="fw-bold">
                    Click here to Check the list of benefits you can get as an
                    INRDeals Publisher!
                  </span>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default OfferDetails;
