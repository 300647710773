import Description from "@mui/icons-material/Description";
import { Link as MuiLink, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

function AboutUs() {

  return (
    <>
    <Helmet>
      <title>About Us | Affworld</title>
      <meta name="description" content="Learn about AffWorld.io, an innovative IT company delivering cutting-edge solutions. Meet our expert team and explore our mission to empower businesses globally." />
      <meta name="keywords" content="AffWorld.io, technology experts, digital transformation, About us, Affworld, Affworld About Us" />
    </Helmet>
      <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
        About Us
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Our Mission
      </Typography>
      <Typography>
        At Affworld Technology, our mission is to revolutionize the digital
        advertising and influencer marketing landscape. We aim to empower
        businesses and influencers by providing innovative, efficient, and
        effective solutions that drive growth and success.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Who We Are
        </Typography>
        Affworld Technology is a leading technology company specializing in
        digital advertising and influencer marketing solutions. Founded in
        [Year], we have grown to become a trusted partner for businesses and
        influencers worldwide. Our team of experts is dedicated to developing
        cutting-edge technologies that make advertising and influencer
        collaborations seamless and impactful.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          What We Do
        </Typography>
        Digital Advertising Solutions: We offer a comprehensive platform for
        businesses to purchase ad space globally, ensuring their messages reach
        the right audience at the right time.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Influencer Marketing:
        </Typography>
        Our platform connects brands with influencers who can authentically
        promote their products and services, driving engagement and conversions.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Influencer Agents:
        </Typography>
        We provide influencer agents to manage and negotiate influencer
        partnerships, ensuring that both brands and influencers achieve their
        goals.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Ad Space Buying:
        </Typography>
        With our global reach, businesses can buy ad space in various formats
        and locations, maximizing their visibility and impact. Our Values
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Innovation:
        </Typography>
        We are committed to continuous innovation, developing new technologies
        and solutions that meet the evolving needs of our clients.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Integrity:
        </Typography>
        We operate with the highest standards of integrity, ensuring
        transparency and honesty in all our dealings.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Customer-Centricity:
        </Typography>
        Our customers are at the heart of everything we do. We strive to
        understand their needs and exceed their expectations.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Collaboration:
        </Typography>
        We believe in the power of collaboration, working closely with our
        clients and partners to achieve mutual success.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Meet Our Team
        </Typography>
        Our team is composed of industry experts, technologists, and creative
        minds who are passionate about transforming the digital advertising and
        influencer marketing landscape. We bring diverse perspectives and
        experiences to the table, fostering a culture of innovation and
        excellence.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Our Vision
        </Typography>
        Our vision is to be the leading technology partner for businesses and
        influencers, driving unparalleled growth and success through innovative
        digital solutions.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Join Us on Our Journey:
        </Typography>
        We invite you to join us on our journey to revolutionize the digital
        advertising and influencer marketing industry. Whether you are a
        business looking to expand your reach or an influencer seeking
        meaningful collaborations, Affworld Technology is here to support you.
        <br />
        For more information about our services and how we can help you achieve
        your goals, please visit our website{" "}
        <MuiLink
          href="https://www.affworld.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.affworld.com
        </MuiLink>{" "}
        or contact us at{" "}
        <MuiLink
          href="mailto:support@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >
          support@affworld.io
        </MuiLink>
        .
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Do Visit:
        </Typography>
      <Typography variant="h6" sx={{ mt: 2, border:"1px solid black",paddingTop:3, paddingBottom:3, textAlign:"center", borderRadius:2 }}>
      Bhamashah technhub 5 th Floor, RAJASTHAN INTERNATIONAL CENTRE, Jhalana Doongri, Jaipur, Rajasthan 302020
        </Typography>
    </>
  );
}

export default AboutUs;
