import { Typography, Link as MuiLink } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

function CustSupport() {
  return (
    <>
    <Helmet>
      <title>Customer Support | Affworld</title>
      <meta name="description" content="Get expert assistance with AffWorld.io's Customer Support. Our dedicated team is here to help you with any inquiries, troubleshooting, or technical issues you may have." />
      <meta name="keywords" content="AffWorld.io customer support, tech support, customer service, help center, technical assistance, support team, troubleshooting, customer inquiries, Affworld, Affworld customer support" />
    </Helmet>
      <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
        We're Here to Help
      </Typography>
      <Typography>
        At Affworld Technology, we value our customers and are here to assist
        you with any questions, concerns, or feedback. Please feel free to reach
        out to us through any of the following methods:
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Customer Support:
        </Typography>
        <MuiLink
          href="mailto:support@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >
          Email: support@affworld.io
        </MuiLink>
        <MuiLink
          href="tel:+971542997582"
          variant="body1"
          color="primary"
          underline="hover"
        >
          Phone: +971542997582
        </MuiLink>
        Live Chat: Available on our website www.affworld.io
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Business Inquiries:
        </Typography>
        <MuiLink
          href="mailto:business@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >
          Email: business@affworld.io
        </MuiLink>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Media Inquiries:
        </Typography>
        <MuiLink
          href="mailto:media@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >
          Email: media@affworld.io
        </MuiLink>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Office Locations:
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Dubai Office:
        </Typography>
        Affworld Fz llc 226 Iridium building , Near Mall of emirates Dubai, UAE
        Office Location
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          India Office:
        </Typography>
        <MuiLink
          href="https://maps.app.goo.gl/rwTYWuaD8C9StUMZA"
          target="_blank"
          rel="noopener noreferrer"
          variant="body1"
          color="primary"
          underline="hover"
        >
          https://maps.app.goo.gl/rwTYWuaD8C9StUMZA
        </MuiLink>
        Affworld Technology Bhamashah technhub 5 th Floor, RAJASTHAN
        INTERNATIONAL CENTRE, Jaipur, Rajasthan 302020, India Office Location
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Office Hours:
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Monday to Friday: 9:00 AM - 6:00 PM (Local Time)
          <br />
          Saturday: 10:00 AM - 4:00 PM (Local Time)
          <br />
          Sunday: Closed
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Follow Us on Social Media:
        </Typography>
        <MuiLink
          href="https://facebook.com/people/Affworld-Technologies/61556653426093/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook: facebook.com/people/Affworld-Technologies/61556653426093/
        </MuiLink>
        <br />
        <MuiLink
          href="https://linkedin.com/company/affworld-technologies"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn: linkedin.com/company/affworld-technologies
        </MuiLink>
        <br />
        <MuiLink
          href="https://instagram.com/Affworld.IO"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram: instagram.com/Affworld.IO
        </MuiLink>
        <br />
        We look forward to hearing from you and assisting you with your needs.
        Your satisfaction is our top priority.
      </Typography>
    </>
  );
}

export default CustSupport;
