import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Grid,
  Avatar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import image1 from "./../../../images/addspaceimages/ADVT1-IMG-109.png.png";
import image2 from "./../../../images/addspaceimages/ADVT2-IMG-51.png.png";
import image3 from "./../../../images/addspaceimages/ADVT3-IMG-249.png.png";
import image4 from "./../../../images/addspaceimages/ADVT4-IMG-145.png.png";
import image5 from "./../../../images/addspaceimages/Image 1.png";
import image6 from "./../../../images/addspaceimages/auto.png";
import image7 from "./../../../images/addspaceimages/amb.png";
import image8 from "./../../../images/addspaceimages/holding.png";
import image9 from "./../../../images/addspaceimages/bus.png";
import image10 from "./../../../images/addspaceimages/charging.png";

function OutdoorAirport() {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          backgroundImage: `url(https://img.freepik.com/free-photo/jumbo-jet-flying-sky_23-2150895675.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: "white",
          padding: { xs: 2, md: 4 },
        }}
      >
        <Typography variant="h2" gutterBottom ml={2}>
          Airport Branding in Jaipur
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          ml={2}
          sx={{ fontSize: "20px", letterSpacing: "2px" }}
        >
          Connect to the world through airport branding in Jaipur and create the
          future
        </Typography>
        <Box
          maxWidth="md"
          sx={{
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: 3,
            borderRadius: 2,
            mt: 3,
            ml: 3,
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="region-label" style={{ color: "white" }}>
                  Select Region
                </InputLabel>
                <Select
                  labelId="region-label"
                  id="region-select"
                  sx={{ color: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="north">North</MenuItem>
                  <MenuItem value="south">South</MenuItem>
                  <MenuItem value="east">East</MenuItem>
                  <MenuItem value="west">West</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="state-label" style={{ color: "white" }}>
                  Select State
                </InputLabel>
                <Select
                  labelId="state-label"
                  id="state-select"
                  sx={{ color: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {/* Add state options here */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="city-label" style={{ color: "white" }}>
                  Select City
                </InputLabel>
                <Select
                  labelId="city-label"
                  id="city-select"
                  sx={{ color: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {/* Add city options here */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                sx={{ backgroundColor: "#445490", width: "100%" }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          background: "linear-gradient(#A5BFD9, #A7D3FF)",
          width: "100%",
          padding: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "700",
            ml: 2,
            mt: 2,
            color: "#2A3973",
          }}
        >
          Advantages of Advertising on Airports
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            alignItems: "center",
            padding: 3,
          }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                gap: 2,
              }}
            >
              <Avatar
                src={image1}
                alt="Traveler Image"
                sx={{
                  width: { xs: 100, sm: 150 },
                  height: { xs: 100, sm: 150 },
                  borderRadius: 1,
                }}
              />
              <Box>
                <Typography variant="h6">Receptive</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Air travelers are optimistic and progressive people often
                  traveling for business or holiday. They are open to brand and
                  advertising messages and view situations from a business
                  perspective. Air travelers tend to pay close attention to
                  every signage and display board.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                gap: 2,
              }}
            >
              <Avatar
                src={image2}
                alt="Traveler Image"
                sx={{
                  width: { xs: 100, sm: 150 },
                  height: { xs: 100, sm: 150 },
                  borderRadius: 1,
                }}
              />
              <Box>
                <Typography variant="h6">Global reach</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Air travelers are optimistic and progressive people often
                  traveling for business or holiday. They are open to brand and
                  advertising messages and view situations from a business
                  perspective. Air travelers tend to pay close attention to
                  every signage and display board.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                gap: 2,
              }}
            >
              <Avatar
                src={image3}
                alt="Traveler Image"
                sx={{
                  width: { xs: 100, sm: 150 },
                  height: { xs: 100, sm: 150 },
                  borderRadius: 1,
                }}
              />
              <Box>
                <Typography variant="h6">Targeted audience</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Air travelers are optimistic and progressive people often
                  traveling for business or holiday. They are open to brand and
                  advertising messages and view situations from a business
                  perspective. Air travelers tend to pay close attention to
                  every signage and display board.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                gap: 2,
              }}
            >
              <Avatar
                src={image4}
                alt="Traveler Image"
                sx={{
                  width: { xs: 100, sm: 150 },
                  height: { xs: 100, sm: 150 },
                  borderRadius: 1,
                }}
              />
              <Box>
                <Typography variant="h6">Round-The-Clock</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: 1,
                    textAlign: "justify",
                    fontSize: "15px",
                  }}
                >
                  Air travelers are optimistic and progressive people often
                  traveling for business or holiday. They are open to brand and
                  advertising messages and view situations from a business
                  perspective. Air travelers tend to pay close attention to
                  every signage and display board.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#e6edf4",
          padding: 4,
          width: "100%",
          maxWidth: 900,
          marginLeft: "auto",
          mt: 7,
          minHeight: "500px",
        }}
      >
        <Box
          sx={{
            paddingRight: { xs: 2, md: 6 },
            width: { xs: "100%", md: "70%" },
            marginLeft: "auto",
            mt: { xs: 0, md: 5 },
            textAlign: { xs: "center", md: "justify" },
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Every Brand has a journey, make Jaipur Airport Advertising a part of
            your success story
          </Typography>
          <Typography variant="body1">
            For more than a decade, airport advertising in India has been one of
            the most consistent and effective forms of OOH that has gathered an
            affluent audience of travelers and has been proven effective at
            delivering branded messages. Airport advertising platform offers a
            blend of promoting and branding mediums to catch the eyes of
            travelers and convert them into future consumers. The airport's
            24-hour...
            {expanded && (
              <span>
                It offers unparalleled visibility and engagement, making it a
                strategic choice for brands aiming to make a significant impact.
                Whether through digital displays, banners, or interactive
                installations, the airport environment provides a unique
                opportunity to connect with a diverse and high-value audience.
              </span>
            )}
          </Typography>
          <Button onClick={handleExpandClick} sx={{ mt: 1 }}>
            {expanded ? "Show less" : "View more"}
          </Button>
        </Box>
        <Box
          component="img"
          src={image5}
          alt="Overlay Image"
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            height: { xs: 200, md: 250 },
            width: { xs: 300, md: 400 },
            display: { xs: "none", md: "block" },
            left: { xs: "auto", md: "-9rem" },
            right: { xs: "auto", md: "auto" },
          }}
        />
      </Box>
      <Grid
        container
        sx={{
          backgroundColor: "#133353",
          padding: 4,
          color: "#fff",
          minHeight: "100vh",
        }}
      >
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            <img src={image6} alt="Image 1" style={{ width: "45%" }} />
            <img src={image7} alt="Image 2" style={{ width: "45%" }} />
            <img src={image8} alt="Image 3" style={{ width: "45%" }} />
            <img src={image9} alt="Image 4" style={{ width: "45%" }} />
            <img src={image10} alt="Image 5" style={{ width: "45%" }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h2" gutterBottom margin={"30px 0"}>
              One Budget, Multiple Campaigns
            </Typography>
            <Box
              sx={{
                background: "linear-gradient(to bottom, #fff 20%, #133353)",
                padding: 2,
                borderRadius: 1,
                color: "#fff",
              }}
              margin={"30px 0"}
            >
              <Typography variant="h3" component="div" gutterBottom>
                Do you have a tight budget and are confused about which Media
                option to choose or ignore?
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ marginTop: 2 }} margin={"50px 0"}>
              Fret no more! As one of the premier advertising agencies in India,
              we are here to simplify your decision-making process. BMS offers a
              first in the industry: "Media Packages," - Curated Media Mix for
              varied marketing and advertising campaign requirements. It's a
              bouquet of complementary and supplementary media options bundled
              together to enhance the campaign's effectiveness.
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              marginTop: 2,
              backgroundColor: "#000",
              color: "#fff",
              width: "150px",
              marginLeft: "auto",
              height:"50px",
              borderRadius:"70px"
            }}
          >
            Book Now
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default OutdoorAirport;
