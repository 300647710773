import React from 'react';

const TemplateComponent = ({img, offter, totalPushSent, clickOnTemplate}) => {
  return (
    <div style={{ display: "flex", justifyContent: "start", textAlign: "center", fontFamily: "Public Sans" }}>
      <span style={{ width: "500px", textAlign: "center" }}>
        <div style={{ height: "40px", width: "40px", borderRadius: "50%", backgroundColor: "#D9D9D9", display: "block", marginLeft: "auto", marginRight: "auto" }}>{img}</div>
      </span>
      <span style={{ fontSize: "18px", width: "600px", textAlign: "center", marginLeft: "30px" }}>{offter}</span>
      <span style={{ fontSize: "18px", width: "600px", textAlign: "center", marginLeft: "30px" }}>{clickOnTemplate}</span>
      <span style={{ fontSize: "18px", width: "600px", textAlign: "center", marginLeft: "30px" }}>{totalPushSent}</span>
    </div>
  );
};

export default TemplateComponent;
