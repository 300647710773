import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getUserFromLocalStorage } from "src/service/localStorage";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Helmet } from "react-helmet-async";
import { Link, TableContainer } from "@mui/material";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import ResetTvIcon from "@mui/icons-material/ResetTv";
import { Button, Box, Modal } from "@mui/material";
import moment from "moment-timezone";

const PushHistory = () => {
  const URL = process.env.REACT_APP_PROD_API;
  const user = getUserFromLocalStorage();
  const accessToken = user?.data.access_token;
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [webUrl, setWebUrl] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  const [action, setAction] = useState("");
  const [canHandleDate, setCanHandleDate] = useState(false);
  const [localDateTime, setLocalDateTime] = useState("");
  const [convertedDateTime, setConvertedDateTime] = useState("");
  const [currentHistoryObject, setCurrentHistoryObject] = useState({});

  useEffect(() => {
    if (localDateTime) {
      convertToLocalTime();
    }
  }, [localDateTime]);
  const convertToLocalTime = () => {
    const localMoment = moment(localDateTime);

    const userTimeZone = moment.tz.guess(true) || "UTC";
    console.log(userTimeZone);

    const utcMoment = localMoment.clone().tz(userTimeZone).utc();
    const convertedTimeString = utcMoment.format("YYYY-MM-DD HH:mm:ss");
    setConvertedDateTime(convertedTimeString);
  };
  const handleCheckBox = (e) => {
    if (canHandleDate) {
      setLocalDateTime("");
    }
    setCanHandleDate(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("===================== ", currentHistoryObject);

    const { website_url, title, body, icon, clickAction } =
      currentHistoryObject;

    let payload = {
      website_url: website_url,
      title: title,
      body: body,
      icon: icon,
      clickAction: clickAction,
    };

    if (convertedDateTime) {
      payload.scheduleTime = convertedDateTime;
    }
    console.log("payload", payload);

    const requestBody = JSON.stringify(payload);

    const queryParams = new URLSearchParams(payload).toString();

    try {
      const apiURL = `${URL}/api/pns/send_notification?${queryParams}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const res = await fetch(apiURL, {
        method: "POST",
        headers: headers,
        body: requestBody,
      });

      if (res.status === 200) {
        toast.success("Notification sent successfully!!");
      }
    } catch (error) {
      console.log("this is error While submitting the data--->", error);
      toast.error("Error While submitting the data!! See Console");
    }
  };
  const historyDetails = async () => {
    try {
      setLoading(true);
      const url = `${URL}/api/pns/get_pns_history`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // console.log("this is response--->", res?.data);
      setHistory(res?.data);
      setLoading(false);
    } catch (error) {
      console.log("Error While Getting History Details--->", error);
      toast.error("Error While Getting History Details!!");
    }
  };
  const handleResendClick = (data) => {
    // console.log(data);
    handleShow2();
    setCurrentHistoryObject(data);
  };
  useEffect(() => {
    historyDetails();
  }, []);
  const formatLocalDate = (utcTimestamp) => {
    const utcDate = new Date(utcTimestamp);
    // Add 5 hours and 30 minutes to convert to IST
    utcDate.setHours(utcDate.getHours() + 5);
    utcDate.setMinutes(utcDate.getMinutes() + 30);

    // Format the date to a string in a way that suits your needs
    const istTimeString = utcDate.toLocaleString("en-In", {
      timeZone: "Asia/Kolkata", // Set the timezone to IST
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

    return istTimeString;
  };
  return (
    <>
      <h2 className="text-center mb-2">Push History here!!</h2>

      <Helmet>
        <title> Push History | Affworld </title>
      </Helmet>

      <TableContainer component={Paper}>
        <Table
          id="offers-table"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Icon</TableCell>

                  <TableCell align="center">Body</TableCell>
                  <TableCell align="center">Title</TableCell>

                  <TableCell align="center">Click Action</TableCell>
                  <TableCell align="center">Success</TableCell>
                  <TableCell align="center">Web URL</TableCell>
                  <TableCell align="center">Resend</TableCell>
                  <TableCell align="center">Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history?.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {history?.map((rowData) => (
                      <TableRow key={rowData?.affiliate_id}>
                        <TableCell align="center" key={rowData?.icon}>
                          {" "}
                          <img
                            src={rowData?.icon}
                            alt="icon"
                            style={{ width: 100 }}
                          />{" "}
                        </TableCell>
                        <TableCell align="center">{rowData?.body}</TableCell>
                        <TableCell align="center">{rowData?.title}</TableCell>

                        <TableCell align="center">
                          {" "}
                          <Link
                            target="_blank"
                            rel="noopener"
                            href={rowData?.clickAction}
                            variant="body2"
                            style={{ textDecoration: "none" }}
                          >
                            Check Here!!
                          </Link>{" "}
                        </TableCell>
                        <TableCell align="center">
                          {rowData?.success === true ? (
                            <CloudDoneIcon color="success" />
                          ) : (
                            <CloseIcon color="error" />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          <Link
                            target="_blank"
                            rel="noopener"
                            href={rowData?.website_url}
                            style={{ textDecoration: "none" }}
                            variant="body2"
                          >
                            URL
                          </Link>{" "}
                        </TableCell>
                        <TableCell align="center">
                          <ResetTvIcon
                            onClick={() => handleResendClick(rowData)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {formatLocalDate(rowData?.timestamp)}
                        </TableCell>

                        {console.log(rowData?.timestamp)}
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>

      <Modal
        open={show2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #4c545d",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="form-group ">
                <input
                  type="checkbox"
                  checked={canHandleDate}
                  onChange={handleCheckBox}
                  id="time-check-box"
                  style={{ cursor: "pointer" }}
                  className=""
                />
                <label
                  htmlFor="time-check-box"
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                >
                  Schedule Notification
                </label>
              </div>

              <div className={`form-group ${canHandleDate ? "" : "disabled"}`}>
                <input
                  type="datetime-local"
                  id="localDateTime"
                  className="form-control"
                  value={localDateTime}
                  onChange={(e) => setLocalDateTime(e.target.value)}
                  disabled={!canHandleDate}
                />
              </div>
            </Box>
          </div>

          <Box sx={{ borderBottom: "1px solid gray" }}></Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="success"
              type="button"
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ ml: 2 }}
              onClick={handleClose2}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PushHistory;
