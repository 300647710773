import React from 'react'
import {
  Container,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Grid,
  Avatar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
function OutdoorMetro() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          backgroundImage: `url(https://img.freepik.com/free-photo/jumbo-jet-flying-sky_23-2150895675.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: "white",
          padding: { xs: 2, md: 4 },
        }}
      >
        <Typography variant="h2" gutterBottom ml={2}>
          Cost effective Media packages that suite your requirements.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          ml={2}
          sx={{ fontSize: "20px", letterSpacing: "2px" }}
        >
        
        </Typography>
        <Box
          maxWidth="md"
          sx={{
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: 3,
            borderRadius: 2,
            mt: 3,
            ml: 3,
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="region-label" style={{ color: "white" }}>
                  Select Region
                </InputLabel>
                <Select
                  labelId="region-label"
                  id="region-select"
                  sx={{ color: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="north">North</MenuItem>
                  <MenuItem value="south">South</MenuItem>
                  <MenuItem value="east">East</MenuItem>
                  <MenuItem value="west">West</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="state-label" style={{ color: "white" }}>
                  Select State
                </InputLabel>
                <Select
                  labelId="state-label"
                  id="state-select"
                  sx={{ color: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {/* Add state options here */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="city-label" style={{ color: "white" }}>
                  Select City
                </InputLabel>
                <Select
                  labelId="city-label"
                  id="city-select"
                  sx={{ color: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {/* Add city options here */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SearchIcon />}
                sx={{ backgroundColor: "#445490", width: "100%" }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default OutdoorMetro