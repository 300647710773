import React from "react";
import { Box, Grid, Typography } from "@mui/material";

function NotificationCard({ notificationIcon, title, subTitle, length, time }) {
  return (
    <Box
      sx={{
        width: { xs: "95%", md: "70%" },
        height: { xs: "94px", md: "90px" },
        margin: "0 auto",
        bgcolor: "#ffffff",
        opacity: 0.7,
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container>
        <Grid item xs={3} md={3}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px 0px 0px 8px",
            }}
          >
            {notificationIcon}
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
              paddingLeft: { xs: "5px", md: "0px" },
            }}
          >
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body1">{subTitle}</Typography>
          </Box>
        </Grid>

        <Grid item xs={3} md={3}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "8px",
              borderRadius: "0px 8px 8px 0px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                bgcolor: "#364A6F",
                padding: "0.3rem",
                borderRadius: "50%",
              }}
              className="text-light"
            >
              {length}
            </Typography>
            <Typography variant="body1">{time}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NotificationCard;
