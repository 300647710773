import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
  Modal,
  IconButton,
  Tooltip,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LinkIcon from "@mui/icons-material/Link";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CollectionsIcon from "@mui/icons-material/Collections";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import HistoryIcon from "@mui/icons-material/History";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import AffPushTemplateTable from "src/components/AffPushTemplateTable/AffPushTemplateTable";
import ChooseImageForNotificationModel from "./modal/ChooseImageForNotificationModel";
import axios from "axios";
import { getUserFromLocalStorage } from "src/service/localStorage";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import AddCommentIcon from '@mui/icons-material/AddComment';


const Datestyles = {
  input: {
    minWidth: "200px",
    padding: "5px",
    fontSize: "16px",
    border: "2px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#f9f9f9",
    transition: "border-color 0.3s, box-shadow 0.3s",
    margin: "0 auto",
    display: "block",
  },
  inputFocus: {
    borderColor: "#007BFF",
    boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
    outline: "none",
  },
  calendarIndicator: {
    filter: "invert(0.5)",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: "50%" },
  background: "linear-gradient(180deg, #A5BFD9 9.55%, #A7D3FF 100%)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
};

function AffPush() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const theme = useTheme();
  const [canHandleDate, setCanHandleDate] = useState(false);
  const [localDateTime, setLocalDateTime] = useState("");
  const [convertedDateTime, setConvertedDateTime] = useState("");
  const [localDateTimeForTemplate, setLocalDateTimeForTemplate] = useState("");
  const [convertedDateTimeForTemplate, setConvertedDateTimeForTemplate] =
    useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [SelectedUrlToSendNotification, setSelectedUrlToSendNotification] =
    useState("");
  const [allVarifiedPnsSites, setAllVarifiedPnsSites] = useState([]);
  const [notiFicationTitle, setNotificationTitle] = useState("");
  const [notiFicationDescription, setNotiFicationDescription] = useState("");
  const [notiFicationClickActionLinkUrl, setNotiFicationClickActionLinkUrl] =
    useState("");
  const [notiFicationIconUrl, setNotiFicationIconUrl] = useState(null);
  const [notiFicationImageUrl, setNotiFicationImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState({});
  const navigate = useNavigate();

  function StyledDateTimeLocalInput() {
    const [isFocused, setIsFocused] = React.useState(false);

    return (
      <input
        type="datetime-local"
        style={{
          ...Datestyles.input,
          ...(isFocused ? Datestyles.inputFocus : {}),
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={localDateTimeForTemplate}
        onChange={(e) => setLocalDateTimeForTemplate(e.target.value)}
      />
    );
  }

  // const convertToLocalTime = () => {
  //   try {
  //     const localMoment = moment(localDateTime);
  //     const userTimeZone = moment.tz.guess(true) || "UTC";
  //     const utcMoment = localMoment.clone().tz(userTimeZone).utc();
  //     const convertedTimeString = utcMoment.format("YYYY-MM-DD HH:mm:ss");

  //     setConvertedDateTime(convertedTimeString);
  //     setSelectedTimeZone(userTimeZone);
  //   } catch (error) {
  //     console.error("Error converting local time:", error);
  //     toast.error("Failed to convert local time.");
  //   }
  // };

  const goToLink = (link) => {
    navigate(link);
  };

  const convertToLocalTime = () => {
    try {
      const localMoment = moment(localDateTime);
      const userTimeZone = moment.tz.guess(true) || "UTC";
      const utcMoment = localMoment.clone().tz(userTimeZone).utc();
      const convertedTimeString = utcMoment.format("YYYY-MM-DD HH:mm:ss");

      setConvertedDateTime(convertedTimeString);
    } catch (error) {
      console.error("Error converting local time:", error);
      toast.error("Failed to convert local time.");
    }
  };

  useEffect(() => {
    if (localDateTime) {
      convertToLocalTime();
    }
  }, [localDateTime]);

  // const convertToLocalTimeForTemplate = () => {
  //   try {
  //     const localMoment = moment(localDateTime);
  //     const userTimeZone = moment.tz.guess(true) || "UTC";
  //     const utcMoment = localMoment.clone().tz(userTimeZone).utc();
  //     const convertedTimeString = utcMoment.format("YYYY-MM-DD HH:mm:ss");

  //     setConvertedDateTimeForTemplate(convertedTimeString);
  //     setSelectedTimeZone(userTimeZone);
  //   } catch (error) {
  //     console.error("Error converting local time:", error);
  //     toast.error("Failed to convert local time.");
  //   }
  // };

  // useEffect(() => {
  //   if (localDateTimeForTemplate) {
  //     convertToLocalTimeForTemplate();
  //   }
  // }, [localDateTimeForTemplate]);

  const convertToLocalTimeForTemplate = () => {
    try {
      const localMoment = moment(localDateTimeForTemplate);
      const userTimeZone = moment.tz.guess(true) || "UTC";
      const utcMoment = localMoment.clone().tz(userTimeZone).utc();
      const convertedTimeString = utcMoment.format("YYYY-MM-DD HH:mm:ss");

      setConvertedDateTimeForTemplate(convertedTimeString);
    } catch (error) {
      console.error("Error converting local time:", error);
      toast.error("Failed to convert local time.");
    }
  };

  useEffect(() => {
    if (localDateTime) {
      convertToLocalTime();
    }
  }, [localDateTime]);

  useEffect(() => {
    if (localDateTimeForTemplate) {
      convertToLocalTimeForTemplate();
    }
  }, [localDateTimeForTemplate]);

  const sendTemplateNotificationHandlerObj = (row) => {
    setTemplate(row);
    console.log("Notification sent for row:", row);
  };

  const combinedHandler = (row) => {
    handleOpen1();
    sendTemplateNotificationHandlerObj(row);
  };

  const sendTemplateNotificationHandler = async () => {
    console.log("sendTemplateNotificationHandler", template);
    const { body, clickAction, image_url, title ,template_id} = template;

    const validate = (field, fieldName) => {
      if (!field || field.trim() === "") {
        toast.error(`${fieldName} field is required`);
        return false;
      }
      return true;
    };

    if (!validate(SelectedUrlToSendNotification, "Website URL")) {
      return;
    }

    let payload = {
      website_url: SelectedUrlToSendNotification,
      title: title,
      body: body,
      icon: image_url,
      clickAction: clickAction,
      ...(convertedDateTimeForTemplate && {
        scheduleTime: convertedDateTimeForTemplate,
      }),
      template_id
    };

    console.log("payload", payload);
    const queryParams = new URLSearchParams(payload).toString();
    const URL = process.env.REACT_APP_PROD_API;

    try {
      const apiURL = `${URL}/api/pns/send_notification?${queryParams}`;
      const {
        data: { access_token },
      } = getUserFromLocalStorage();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      };

      setLoading(true);
      const res = await axios.post(apiURL, payload, { headers });

      if (res.status === 200) {
        toast.success("Notification sent successfully!");
        setSelectedUrlToSendNotification("");
        setLocalDateTimeForTemplate("");
        setConvertedDateTimeForTemplate("");
        setTemplate(null);
        setLoading(false);
        handleClose1();
      }
    } catch (error) {
      console.error("Error while submitting the data:", error);
      toast.error("Error while submitting the data! See console for details.");
      setLoading(false);
    }
  };
  const handleSelectExternalImageUrl = (url) => {
    if (url) {
      setNotiFicationImageUrl(url);
      setNotiFicationIconUrl(null)
      handleClose();
      toast.success("Image url added");
    } else {
      toast.error("Please select image url");
    }
  };
  const onSelectImage = (url) => {
    if (url) {
      setNotiFicationIconUrl(url);
      handleClose();
      toast.success("image selected");
    }
  };

  const handleSubmitButtonForSendPnsNotification = async () => {
    const validate = (field, fieldName) => {
      if (!field || field.trim() === "") {
        toast.error(`${fieldName} field is required`);
        return false;
      }
      return true;
    };

    if (
      !validate(notiFicationTitle, "Notification Title") ||
      !validate(notiFicationDescription, "Notification Description") ||
      !validate(
        notiFicationClickActionLinkUrl,
        "Notification Click Action Link URL"
      ) ||
      !validate(SelectedUrlToSendNotification, "Website URL")
    ) {
      return;
    }

    let payload = {
      website_url: SelectedUrlToSendNotification,
      title: notiFicationTitle,
      body: notiFicationDescription,
      icon: notiFicationIconUrl || notiFicationImageUrl,
      clickAction: notiFicationClickActionLinkUrl,
      ...(convertedDateTime && { scheduleTime: convertedDateTime }),
    };

    console.log("payload", payload);

    const queryParams = new URLSearchParams(payload).toString();
    const URL = process.env.REACT_APP_PROD_API;

    try {
      const apiURL = `${URL}/api/pns/send_notification?${queryParams}`;
      const {
        data: { access_token },
      } = getUserFromLocalStorage();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      };

      setLoading(true);
      const res = await axios.post(apiURL, payload, { headers });

      if (res.status === 200) {
        toast.success("Notification sent successfully!");
        setLoading(false);
        setNotificationTitle("");
        setNotiFicationDescription("");
        setNotiFicationClickActionLinkUrl("");
        setNotiFicationIconUrl("");
        setConvertedDateTime("");
        setCanHandleDate(false);
        setConvertedDateTime("");
        setLocalDateTime("");
        payload = {};
      }
    } catch (error) {
      console.error("Error while submitting the data:", error);
      toast.error("Error while submitting the data! See console for details.");
      setLoading(false);
    }
  };

  const FetchAllVarifiedPnsSites = async () => {
    const URL = process.env.REACT_APP_PROD_API;
    const {
      data: { access_token },
    } = getUserFromLocalStorage();
    try {
      const response = await axios.get(`${URL}/api/pns/get_pns_site`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      let getAllPnsSites = response.data;
      getAllPnsSites.filter((item) => item.verified === true);
      if (getAllPnsSites) {
        setAllVarifiedPnsSites(getAllPnsSites);
      } else {
        return;
      }
    } catch (error) {
      console.log("Error While Fetching data click --->", error);
      toast.error("Session Expired Please Login Again");
    }
  };

  const handleDropdownChange = (e, v) => {
    if (v) {
      setSelectedUrlToSendNotification(v.website_url);
    } else {
      setSelectedUrlToSendNotification("");
    }
  };
  const handleCheckBox = (e) => {
    if (canHandleDate) {
      setLocalDateTime("");
    }
    setCanHandleDate(e.target.checked);
  };

  const affPushOptions = [
    {
      icon: <CollectionsIcon />,
      name: "Manage Gallery",
      link: "/aff-push/manage-galary",
    },
    {
      icon: <SettingsApplicationsIcon />,
      name: "Manage Site",
      link: "/aff-push/managesite",
    },
    {
      icon: <AddCommentIcon />,
      name: "New Site",
      link: "/aff-push/Addnewsite",
    },
    {
      icon: <HistoryIcon />,
      name: "Push History",
      link: "#",
    },
    {
      icon: <InsertLinkIcon />,
      name: "Link Library",
      link: "#",
    },
    {
      icon: <LibraryBooksIcon />,
      name: "Template Library",
      link: "#",
    },
    {
      icon: <SignalCellularAltIcon />,
      name: "Statistics",
      link: "#",
    },
  ];

  const boxStyle = {
    bgcolor: "red",
    height: 12,
    width: 12,
  };

  const containerStyle = {
    display: "flex",
    gap: 2,
    justifyContent: "start",
    alignItems: "center",
  };

  const paperstyle = [
    {
      backgroundColor: "#F9F9F9",
      opacity: "70%",
      borderRadius: 3,
      p: 2,
      width: "100%",
    },
    {
      backgroundColor: "transparent",
      width: "100%",
    },
    {
      backgroundColor: "transparent",
      width: "100%",
    },
  ];

  const commonPaperStyles = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    backgroundColor: "#ECF2FD",
    opacity: "70%",
    height: "150px",
    width: "100%",
    "&:hover": {
      opacity: "100%",
    },
    "& .MuiTypography-root": {
      color: "#12314F",
      fontWeight: "bold",
    },
    "& .MuiButton-root": {
      color: "#12314F",
      fontWeight: "bold",
    },
    borderRadius: 4,
  };

  const ClickAnyliticPaperStyles = {
    p: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    backgroundColor: "#EFF8F3",
    opacity: "70%",
    width: "100%",
    "&:hover": {
      opacity: "100%",
    },
    "& .MuiTypography-root": {
      color: "#12314F",
      fontWeight: "bold",
    },
    "& .MuiButton-root": {
      color: "#12314F",
      fontWeight: "bold",
    },
    borderRadius: 4,
  };

  const ClickAnyliticStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 110,
    height: 110,
    borderRadius: "50%",
    backgroundColor: "transparent",
    border: "10px solid",
    borderColor: "primary.main",
    color: "primary.main",
  };

  const affPushOptBoxCardStyle = {
    py: 2.5,
    px: 2,
    mb: 2.1,
    background: "#F9F9F9",
    opacity: "70%",
    width: "100%",
    borderRadius: 3,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      bgcolor: "#ffff",
      opacity: "100%",
    },
    gap: { xs: 1, md: 1.2 },
  };

  const paperContents = [
    <div>
      <Typography sx={{ color: "#12314F" }} variant="h6">
        Send Fresh Notification
      </Typography>
      <Grid container spacing={2} mt={2}>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          {notiFicationIconUrl || notiFicationImageUrl ? (
            <Avatar
              onClick={handleOpen}
              src={notiFicationIconUrl || notiFicationImageUrl}
              sx={{
                height: { xs: "100px", sm: "100px", md: "100px" },
                width: { xs: "320px", sm: "100px", md: "100px" },
                borderRadius: "0%",
                border: "2px dashed #669ADA",
                cursor: "pointer",
              }}
            />
          ) : (
            <Paper
              onClick={handleOpen}
              elevation={18}
              sx={{
                height: { xs: "100px", sm: "100px", md: "100px" },
                width: { xs: "320px", sm: "100px", md: "100px" },
                bgcolor: "#BDDCFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px dashed #669ADA",
                cursor: "pointer",
              }}
            >
              <CloudDownloadIcon
                fontSize="large"
                style={{ color: "#1C81F3" }}
              />
            </Paper>
          )}

          <Typography
            sx={{
              color: "#12314F",
              mt: 1,
              fontSize: { xs: "1rem", sm: "0.875rem", md: "1rem" },
            }}
            variant="h6"
          >
            Push Image
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={9}>
          <TextField
            sx={{
              backgroundColor: "#FFFFFF",
              border: "1px solid black",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            id="outlined-multiline-flexible"
            multiline
            maxRows={1}
            fullWidth
            value={notiFicationTitle}
            className="form-control custom-input shadow-sm"
            placeholder="Enter Your Notification’s Title Here"
            onChange={(e) => setNotificationTitle(e.target.value)}
          />

          <TextField
            sx={{
              marginTop: 1,
              backgroundColor: "#FFFFFF",
              border: "1px solid black",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            id="outlined-multiline-flexible"
            multiline
            maxRows={2}
            fullWidth
            placeholder="Enter Your Notification’s Description Here"
            className="form-control custom-input shadow-sm"
            value={notiFicationDescription}
            onChange={(e) => setNotiFicationDescription(e.target.value)}
          />

          <TextField
            sx={{
              marginTop: 1,
              backgroundColor: "#FFFFFF",
            }}
            id="outlined-start-adornment"
            fullWidth
            multiline
            maxRows={2}
            placeholder="Enter Click Action Link"
            className=" custom-input shadow-sm"
            value={notiFicationClickActionLinkUrl}
            onChange={(e) => setNotiFicationClickActionLinkUrl(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Divider sx={{ border: "2px solid black", my: 2 }} />

      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className=" ">
            <input
              type="checkbox"
              checked={canHandleDate}
              onChange={handleCheckBox}
              id="time-check-box"
              style={{ cursor: "pointer" }}
              className=""
            />
            <label
              htmlFor="time-check-box"
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                fontSize: "16px",
              }}
            >
              Schedule Notification
            </label>
          </div>

          <Box mt={1}>
            <input
              type="datetime-local"
              id="localDateTime"
              className="form-control m-0 mt-0"
              value={localDateTime}
              onChange={(e) => setLocalDateTime(e.target.value)}
              disabled={!canHandleDate}
            />
          </Box>
        </Box>

        <Box sx={{ mt: 1.7, width: "100%" }}>
          <Typography variant="h6" gutterBottom>
            Website URL
          </Typography>
          <Autocomplete
            className=" custom-input shadow-sm bg-white"
            id="url-autocomplete"
            options={allVarifiedPnsSites}
            getOptionLabel={(option) => option.website_url}
            onChange={handleDropdownChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                placeholder="Select a website"
              />
            )}
            freeSolo
          />
        </Box>

        <Box sx={{ mt: 1.8, width: "100%", textAlign: "center" }}>
          <button
            disabled={loading}
            type="button"
            onClick={handleSubmitButtonForSendPnsNotification}
            className="btn  m-0 p-0 fw-bold"
            style={{
              fontSize: { xs: 15, md: "18px" },
              backgroundColor: loading ? "gray" : "#445490",
              color: "#FFFF",
            }}
          >
            {loading ? <CircularProgress /> : "Send Notification"}
          </button>
        </Box>
      </div>
    </div>,
    <div className="d-flex flex-column gap-2  w-100 h-100">
      <Paper elevation={5} sx={commonPaperStyles}>
        <ShoppingCartIcon fontSize="large" aria-label="Total Spend" />
        <Typography variant="h5" sx={{ color: "#2A2B2D" }}>
          Total Spend
        </Typography>
        <Typography variant="h5" sx={{ color: "#2A2B2D" }}>
          ₹ 500/-
        </Typography>
      </Paper>
      <Paper elevation={5} sx={commonPaperStyles}>
        <AccountBalanceWalletIcon fontSize="large" aria-label="Payments" />
        <Typography variant="h5" sx={{ color: "#2A2B2D" }}>
          Payments
        </Typography>
      </Paper>
      <Paper elevation={5} sx={ClickAnyliticPaperStyles}>
        <Box sx={ClickAnyliticStyles}>
          <Typography variant="h5" component="span" color="text.primary">
            10M
          </Typography>
        </Box>
        <Typography variant="h5" sx={{ color: "#2A2B2D" }}>
          Total Clicks
        </Typography>
        <Box component="section" className="main">
          <Box component="main" sx={containerStyle}>
            <Box sx={boxStyle}></Box>
            <Typography variant="body2" component="span">
              Google
            </Typography>
          </Box>
          <Box component="main" sx={containerStyle}>
            <Box sx={{ ...boxStyle, bgcolor: "#73CE64" }}></Box>
            <Typography variant="body2" component="span">
              Instagram
            </Typography>
          </Box>
          <Box component="main" sx={containerStyle}>
            <Box sx={{ ...boxStyle, bgcolor: "#DADADA" }}></Box>
            <Typography variant="body2" component="span">
              Twitter
            </Typography>
          </Box>
        </Box>
      </Paper>
    </div>,
    <div>
      <Box
        component={"section"}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {affPushOptions.map((pushObject, i) => {
          const { icon, name, link } = pushObject;
          return (
            <Paper
              onClick={() => goToLink(link)}
              key={i}
              elevation={8}
              sx={affPushOptBoxCardStyle}
            >
              <Box component={"div"}>{icon}</Box>
              <Typography
                variant={"body2"}
                component={"span"}
                sx={{
                  fontSize: {
                    xs: "0.82rem",
                    sm: "0.875rem",
                    md: "1.1rem",
                  },
                  fontWeight: 800,
                  color: "#212B36",
                }}
              >
                {name}
              </Typography>
            </Paper>
          );
        })}
      </Box>
    </div>,
  ];

  useEffect(() => {
    FetchAllVarifiedPnsSites();
  }, []);

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #A5BFD9 9.55%, #A7D3FF 100%)",
        [theme.breakpoints.up("md")]: {
          p: 2,
          height: "auto",
        },
        [theme.breakpoints.up("xs")]: {
          p: 2,
          height: "auto",
        },
      }}
    >
      <Typography
        sx={{
          color: "#12314F",
          fontWeight: "bold",
          fontSize: "20px",
          [theme.breakpoints.up("md")]: {
            fontSize: "30px",
          },
          my: 1,
        }}
      >
        AFF PUSH
      </Typography>
      <Grid container spacing={2.2}>
        {[6, 3, 3].map((size, index) => (
          <Grid item xs={[12, 6, 6][index]} md={size} key={index}>
            <Paper sx={paperstyle[index]}>{paperContents[index]}</Paper>
          </Grid>
        ))}
      </Grid>
      <Box component={"section"} mt={3}>
        <AffPushTemplateTable
          sendTemplateNotificationHandler={combinedHandler}
        />
      </Box>
      <ChooseImageForNotificationModel
        onSelectImage={onSelectImage}
        open={open}
        handleClose={handleClose}
        onSelectExternalImageUrl={handleSelectExternalImageUrl}
      />
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography id="modal-title" variant="h5" mt={0} color="#002C56">
              Schedule or Send Immediate Notification
            </Typography>

            <Tooltip placement="left-start" title="Schedule">
              <IconButton aria-label="calendar">
                <StyledDateTimeLocalInput />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ mt: 1.7, width: "100%" }}>
            <Typography variant="h6" gutterBottom>
              Website URL
            </Typography>
            <Autocomplete
              className=" custom-input shadow-sm bg-white rounded-2"
              id="url-autocomplete"
              options={allVarifiedPnsSites}
              getOptionLabel={(option) => option.website_url}
              onChange={handleDropdownChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  placeholder="Select a website"
                />
              )}
              freeSolo
            />
          </Box>

          <Box sx={{ mt: 1.8, width: "100%", textAlign: "center" }}>
            <button
              disabled={loading}
              type="button"
              onClick={sendTemplateNotificationHandler}
              className="btn  m-0 p-0 fw-bold"
              style={{
                fontSize: { xs: 15, md: "18px" },
                backgroundColor: loading ? "gray" : "#445490",
                color: "#FFFF",
              }}
            >
              {loading ? <CircularProgress /> : "Send Notification"}
            </button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default AffPush;
