import React, { useEffect, useState } from "react";
import Testnav from "src/components/Testnav";
import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Rating,
} from "@mui/material";
import MaskGroup from "./Mask Group.png";
import Rectangle from "./Rectangle 893.png";
import Rectangle1 from "./Rectangle 4321.png";
import HomeIcon from "./Vector.png";
import InfoIcon from "./Vector (1).png";
import ContactMailIcon from "./Texts.png";
import StarIcon from "./Group.png";
import BuildIcon from "./Group (1).png";
import CheckCircleIcon from "./Vector (2).png";
import SecurityIcon from "./globe.png";
import ThumbUpIcon from "./Group (2).png";
import TrendingUpIcon from "./analyse.png";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const HoverCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#E0E0E0",
  transition: "transform 0.3s, background-color 0.3s",
  "&:hover": {
    backgroundColor: "#fff",
    transform: "translateY(-5px)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    transform: "scale(1.1)",
  },
  width: "150px",
  height: "150px",
  borderRadius: "0",
}));


const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: theme.spacing(2),
  },
  card: {
    minWidth: 300,
    maxWidth: 300,
    margin: theme.spacing(2),
    transition: "transform 0.5s ease, filter 0.5s ease",
  },
  cardVisible: {
    transform: "scale(1.1)",
    filter: "none",
  },
  cardBlurred: {
    filter: "blur(5px)",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}));
function LandingPage() {
  const colors = ["#0ac05e", "indigo", "violet", "red"];
  const numberOfSpots = 20;

  useEffect(() => {
    const container = document.querySelector(".spots-container");

    if (!container) return;

    for (let i = 0; i < numberOfSpots; i++) {
      const spot = document.createElement("div");
      spot.style.position = "absolute";
      spot.style.borderRadius = "50%";
      spot.style.zIndex = "999";
      spot.style.backgroundColor =
        colors[Math.floor(Math.random() * colors.length)];

      const size = Math.random() < 0.5 ? "10px" : "20px";
      spot.style.width = size;
      spot.style.height = size;

      spot.style.top = Math.random() * 100 + "%";
      spot.style.left = Math.random() * 100 + "%";
      container.appendChild(spot);
    }

    return () => {
      container.innerHTML = "";
    };
  }, [colors, numberOfSpots]);

  const services = [
    {
      title: "Effective Engagement",
      description:
        "Send targeted push notifications to keep your audience engaged and informed.",
      icon: HomeIcon,
    },
    {
      title: "Easy Customization",
      description:
        "Create and customize notification templates with ease, tailoring messages to your audience's needs.",
      icon: InfoIcon,
    },
    {
      title: "Comprehensive Analytics",
      description:
        "Track and analyze the performance of your push notifications to optimize engagement and conversions.",
      icon: ContactMailIcon,
    },
    {
      title: "Streamlined Management",
      description:
        "Manage all your push notification campaigns from a single, intuitive dashboard for maximum efficiency.",
      icon: StarIcon,
    },
    {
      title: "Customer-Centric Approach",
      description:
        "Focus on delivering value to your customers, building trust, and enhancing their overall experience with your brand.",
      icon: BuildIcon,
    },
  ];

  const features = [
    {
      title: "Pre-Designed Templates",
      description: "Choose from a variety of templates.",
      icon: CheckCircleIcon,
    },
    {
      title: "Custom Template Creation",
      description: "Create custom templates with title and description.",
      icon: SecurityIcon,
    },
    {
      title: "Push Notification Management",
      description: "Manage notifications from one intuitive dashboard.",
      icon: ThumbUpIcon,
    },
    {
      title: "Statistics and Analytics",
      description: "Track notification performance with detailed analytics.",
      icon: TrendingUpIcon,
    },
  ];

 
 const classes = useStyles();
 const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = React.useRef(null);

 const settings = {
   centerMode: true,
   centerPadding: "0",
   slidesToShow: 3,
   focusOnSelect: true,
   afterChange: (index) => setCurrentIndex(index),
   infinite: true,
   speed: 500,
   responsive: [
     {
       breakpoint: 1024,
       settings: {
         slidesToShow: 0,
       },
     },
     {
       breakpoint: 600,
       settings: {
         slidesToShow: 1,
       },
     },
   ],
 };
 const reviews = [
   {
     content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
     name: "John Doe",
     email: "john@example.com",
     rating: 5,
   },
   {
     content:
       "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
     name: "Jane Smith",
     email: "jane@example.com",
     rating: 4,
   },
   {
     content:
       "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
     name: "Alice Johnson",
     email: "alice@example.com",
     rating: 4.5,
   },
   {
     content:
       "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.",
     name: "Bob Brown",
     email: "bob@example.com",
     rating: 5,
   },
 ];


  const ReviewCard = ({ review, isVisible }) => (
    <Card
      className={`${classes.card} ${
        isVisible ? classes.cardVisible : classes.cardBlurred
      }`}
    >
      <CardContent>
        <Rating value={review.rating} readOnly />
        <Typography variant="body2" gutterBottom>
          {review.content}
        </Typography>
        <Typography variant="subtitle2">{review.name}</Typography>
        <Typography variant="caption" color="textSecondary">
          {review.email}
        </Typography>
      </CardContent>
    </Card>
  );

    const handlePrev = () => {
      sliderRef.current.slickPrev();
    };

    const handleNext = () => {
      sliderRef.current.slickNext();
    };

  return (
    <>
      <Box
        className="spots-container"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "90vw",
          height: "25vh",
          pointerEvents: "none",
          zIndex: -1,
        }}
      ></Box>

      <Testnav />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          zIndex: 1,
          padding: "0 20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ padding: "20px" }}>
              <Typography
                variant="h1"
                sx={{
                  marginBottom: "20px",
                  marginLeft: "10px",
                  fontSize: "clamp(2rem, 5vw, 4rem)",
                }}
              >
                Maximize Engagement with{" "}
                <Box component="span" color="#0ac05e">
                  {" "}
                  Smart Push Notification
                </Box>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: "20px",
                  fontSize: "clamp(1rem, 2.5vw, 1.25rem)",
                }}
              >
                Easily send targeted push notifications using our variety of
                templates or create your own. Track and manage your campaigns
                with our advanced analytics.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginTop: "20px",
                  borderRadius: "0",
                  width: 180,
                  fontWeight: "400",
                }}
              >
                Get Started
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "400px",
                  height: "400px",
                  backgroundColor: "#AAD2DE",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <img
                  src={MaskGroup}
                  alt="Description"
                  style={{ width: "100%", height: "150%", marginTop: "-200px" }}
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: "65%",
                  right: "25%",
                  transform: "translateY(-50%)",
                }}
              >
                <img
                  src={Rectangle1}
                  alt="Middle Left"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "15%",
                  right: "5%",
                  width: "200px",
                }}
              >
                <img
                  src={Rectangle}
                  alt="Bottom Right"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography
        sx={{ textAlign: "center", fontSize: "30px", marginBottom: "20px" }}
      >
        Grow faster with help our <br />
        customers
      </Typography>

      <Container>
        <Grid container spacing={3}>
          {services.map((service, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={index}
              sx={{
                margin: { xs: "0 auto", md: "0" },
                display: { xs: "block" },
              }}
            >
              <Box
                sx={{
                  padding: "20px",
                  textAlign: "center",
                  boxShadow: "none",
                  borderRadius: "8px",
                  width: "200px",
                  margin: "0 auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={service.icon}
                    alt={service.title}
                    style={{ width: 50, height: 50, objectFit: "cover" }}
                  />
                </Box>
                <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                  {service.title}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: "20px" }}>
                  {service.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Container>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            margin: "40px 0",
            position: "relative",
            "&::after": {
              content: '""',
              display: "block",
              width: "100px",
              height: "4px",
              backgroundColor: "blue",
              margin: "8px auto 0",
            },
          }}
        >
          Key Features
        </Typography>
        <Grid container spacing={2} sx={{ gap: "0px" }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  padding: "20px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "0px",
                  backgroundColor: "#f0f0f0",
                  maxWidth: "350px",
                  margin: "0 auto",
                  transition: "transform 0.3s, background-color 0.3s",
                  "&:hover": {
                    backgroundColor: "#fff",
                    transform: "scale(1.1)",
                  },
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src={feature.icon}
                      alt={feature.title}
                      style={{ width: 50, height: 50, objectFit: "cover" }}
                    />
                  </Box>
                  <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: "20px" }}>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Typography variant={"h2"} textAlign={"center"}>
        How it works
      </Typography>
      <Typography
        margin={"0 auto"}
        fontSize={"16px"}
        width={550}
        textAlign={"center"}
      >
        It's about your business, sending effective notifications, easy template
        creation, and detailed analytics.
      </Typography>
      <Container
        sx={{
          margin: "20px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "50px", alignItems: "center" }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-52%",
                  left: "40%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#FFE9E9",
                  width: "300px",
                  height: "150px",
                  borderBottomLeftRadius: "150px",
                  borderBottomRightRadius: "150px",
                  zIndex: 1,
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    transform: "translate(-50%, -50%)",
                    fontWeight: "bold",
                    fontSize: "3rem",
                    color: "#D7D7D7",
                    padding: "20px",
                  }}
                >
                  01
                </Typography>
              </Box>
              <Card
                sx={{
                  position: "relative",
                  top: "50px",
                  zIndex: 5,
                  width: "300px",
                  height: "250px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Create Account
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    marginTop={"30px"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "16px",
                      }}
                    >
                      {[...Array(4)].map((_, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "250px",
                            height: "10px",
                            borderRadius: "60px",
                            backgroundColor: "#F1F1F1",
                            margin: "5px 0",
                          }}
                        />
                      ))}
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                padding: "20px",
              }}
            >
              <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                Create Account
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "20px", color: "#425466" }}
              >
                Create your account in just a few steps.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "50px", alignItems: "center" }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                padding: "20px",
              }}
            >
              <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                Select Templates
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "20px", color: "#425466" }}
              >
                Pick from pre-designed templates or create your own.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-100%",
                  left: "40%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#F5F7FF",
                  width: "300px",
                  height: "150px",
                  borderBottomLeftRadius: "150px",
                  borderBottomRightRadius: "150px",
                  zIndex: 1,
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    transform: "translate(-50%, -50%)",
                    fontWeight: "bold",
                    fontSize: "3rem",
                    color: "#D7D7D7",
                    padding: "20px",
                  }}
                >
                  02
                </Typography>
              </Box>
              <Card
                sx={{
                  position: "relative",
                  top: "50px",
                  zIndex: 5,
                  width: "300px",
                  height: "150px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Templates
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    marginTop={"30px"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #ccc",
                        borderRadius: "4px",

                        overflow: "hidden",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="Select Templates"
                        border="#BCBCBC"
                        sx={{
                          "& fieldset": { border: "none" },
                          flex: 1,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                variant=""
                                color=""
                                sx={{
                                  minWidth: "auto",
                                  padding: "8px",
                                }}
                              >
                                <SearchIcon
                                  sx={{
                                    fill: "#0AC05E",
                                  }}
                                />
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "50px", alignItems: "center" }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-52%",
                  left: "40%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#FFF5DA",
                  width: "300px",
                  height: "150px",
                  borderBottomLeftRadius: "150px",
                  borderBottomRightRadius: "150px",
                  zIndex: 1,
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    transform: "translate(-50%, -50%)",
                    fontWeight: "bold",
                    fontSize: "3rem",
                    color: "#D7D7D7",
                    padding: "20px",
                  }}
                >
                  03
                </Typography>
              </Box>
              <Card
                sx={{
                  position: "relative",
                  top: "50px",
                  zIndex: 5,
                  width: "300px",
                  height: "250px",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Enter Details
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    marginTop={"30px"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "16px",
                      }}
                    >
                      {[...Array(4)].map((_, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "250px",
                            height: "10px",
                            borderRadius: "60px",
                            backgroundColor: "#F1F1F1",
                            margin: "5px 0",
                          }}
                        />
                      ))}
                    </Box>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                padding: "20px",
              }}
            >
              <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                Enter Details
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "20px", color: "#425466" }}
              >
                Provide the title and description for your custom templates.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "50px", alignItems: "center" }}
        >
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                padding: "20px",
              }}
            >
              <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                Send Notification
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginBottom: "20px", color: "#425466" }}
              >
                Send your notifications and track their performance.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-100%",
                  left: "40%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#F5F7FF",
                  width: "300px",
                  height: "150px",
                  borderBottomLeftRadius: "150px",
                  borderBottomRightRadius: "150px",
                  zIndex: 1,
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    transform: "translate(-50%, -50%)",
                    fontWeight: "bold",
                    fontSize: "3rem",
                    color: "#D7D7D7",
                    padding: "20px",
                  }}
                >
                  04
                </Typography>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  top: "50px",
                  zIndex: 5,
                  width: "300px",
                  height: "150px",
                  textAlign: "center",
                  background: "trasparent",
                }}
              >
                <img
                  src={Rectangle}
                  width={"200px"}
                  height={"50px"}
                  style={{
                    position: "absolute",
                    top: "5rem",
                    right: "2rem",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box
        sx={{
          margin: "10rem 0",
        }}
      ></Box>

      <Container>
        <Grid container spacing={5} margin={"10px auto"}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ borderRight: { md: "1px solid #ccc" }, pr: 2 }}
          >
            <Box mb={2}>
              <Typography variant="h3">About Us</Typography>
              <Typography>
                And produce say the ten moments parties. Simple innate summer
                fat appear basket his desire joy. Outward clothes promise at
                gravity do excited. Sufficient particular impossible by
                reasonable oh expression is. Yet preference connection
                unpleasant yet melancholy but end appearance. And excellence
                partiality estimating terminated day everything.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginTop: "20px",
                  borderRadius: "0",
                  width: 180,
                  fontWeight: "400",
                }}
              >
                Learn More
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item>
                <HoverCard>
                  <CardContent>
                    <Typography textAlign={"center"} variant="h3">
                      1000+
                    </Typography>
                    <Typography fontSize={"12px"}>
                      Completed Projects
                    </Typography>
                  </CardContent>
                </HoverCard>
              </Grid>
              <Grid item>
                <HoverCard>
                  <CardContent>
                    <Typography variant="h3">250+</Typography>
                    <Typography fontSize={"12px"}>
                      {" "}
                      On Going Projects
                    </Typography>
                  </CardContent>
                </HoverCard>
              </Grid>
              <Grid item>
                <HoverCard>
                  <CardContent>
                    <Typography variant="h3">500+</Typography>
                    <Typography fontSize={"12px"}>Happy Clients</Typography>
                  </CardContent>
                </HoverCard>
              </Grid>
              <Grid item>
                <HoverCard>
                  <CardContent>
                    <Typography variant="h3">4.9</Typography>
                    <Typography fontSize={"12px"}>
                      Average Customer rating out of 5.0!
                    </Typography>
                  </CardContent>
                </HoverCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            What Our Clients Say
          </Typography>
          <Slider ref={sliderRef} {...settings}>
            {reviews.map((review, index) => (
              <ReviewCard
                key={index}
                review={review}
                isVisible={index === currentIndex}
              />
            ))}
          </Slider>
          <Box className={classes.buttonGroup}>
            <Button variant="contained" color="primary" onClick={handlePrev}>
              Prev
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              style={{ marginLeft: 10 }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default LandingPage;
