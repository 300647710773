import { Avatar, Box, Button, Typography ,FormControlLabel, Checkbox,} from "@mui/material";
import React, { useState } from "react";

function Instagram() {
  const user = {
    name: "John Doe",
    userid: "@johndoe",
    posts: 123,
    followers: 456,
    following: 789,
    profilePic: "https://via.placeholder.com/150",
  };
 const [checkedItems, setCheckedItems] = useState({
   trending: false,
   popular: false,
   latest: false,
   bestSeller: false,
   featured: false,
   discounted: false,
   newArrivals: false,
 });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {
      setShowMore(!showMore);
    };

     
        const checkedValues = Object.keys(checkedItems).filter(
          (key) => checkedItems[key]
        );

      


  return (
    <>
      <Box
        sx={{
          background: "linear-gradient( #A5BFD9, #A7D3FF)",
          p: 4,
          height: { xs: "auto", md: "130vh" },
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
          Instagram
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap-reverse",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              width: "250px",
              padding: "20px",
              backgroundColor: "#EDF3F9",
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              margin: "20px auto",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "15px" }}
            >
              Category
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedItems.trending}
                  onChange={handleCheckboxChange}
                  name="trending"
                />
              }
              label="Trending"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedItems.popular}
                  onChange={handleCheckboxChange}
                  name="popular"
                />
              }
              label="Popular"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedItems.latest}
                  onChange={handleCheckboxChange}
                  name="latest"
                />
              }
              label="Latest"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedItems.bestSeller}
                  onChange={handleCheckboxChange}
                  name="bestSeller"
                />
              }
              label="Best Seller"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedItems.featured}
                  onChange={handleCheckboxChange}
                  name="featured"
                />
              }
              label="Featured"
            />
            {showMore && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems.discounted}
                      onChange={handleCheckboxChange}
                      name="discounted"
                    />
                  }
                  label="Discounted"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems.newArrivals}
                      onChange={handleCheckboxChange}
                      name="newArrivals"
                    />
                  }
                  label="New Arrivals"
                />
              </>
            )}
            <Button
              variant="text"
              onClick={handleShowMore}
              sx={{
                marginTop: "10px",
                textTransform: "none",
                display: "block",
              }}
            >
              {showMore ? "Show Less" : "Show More"}
            </Button>

            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "15px" }}
            >
              Price
            </Typography>
            <Box>
              <Typography>Under ₹500</Typography>
              <Typography>₹500 - ₹1,000</Typography>
              <Typography>₹1,000 - ₹2,000</Typography>
              <Typography>₹2,000 - ₹3,000</Typography>
              <Typography>Over ₹3,000</Typography>
            </Box>
            <Box>
              <Button
                sx={{
                  border: "1px solid #888C8C",
                  bgcolor: "#fff",
                  fontWeight: "450",
                  color: "#6F7373",
                  margin: "5px",
                }}
              >
                Min
              </Button>
              <Button
                sx={{
                  border: "1px solid #888C8C",
                  bgcolor: "#fff",
                  fontWeight: "450",
                  color: "#6F7373",
                }}
              >
                Max
              </Button>
              <Button
                sx={{
                  border: "1px solid #D5D9D9",
                  bgcolor: "#fff",
                  fontWeight: "450",
                  color: "#6F7373",
                  margin: "3px",
                  boxShadow: "0px 2px 5px 0 #D5D9D9",
                  textTransform: "none",
                }}
              >
                Go
              </Button>
            </Box>

            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "15px" }}
            >
              Subscription Option
            </Typography>
            <FormControlLabel
              control={<Checkbox name="Subscribe" />}
              label="Subscribe & Save"
            />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "15px" }}
            >
              Discount
            </Typography>
            <Box>
              <Typography> Any Discount</Typography>
              <Typography variant="p" sx={{ fontWeight: "bold" }}>
                10% Off or more
              </Typography>
              <Typography>25% Off or more</Typography>
              <Typography>35% Off or more</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              width: { xs: "70%", sm: "70%", md: "50%", lg: "45%" },
              margin: "20px auto",
              backgroundColor: "#edf3f9",
              padding: "20px",
              border: "1px solid #ddd",
              borderRadius: "15px",
              flexDirection: { xs: "column", sm: "row" },
              height: { lg: "200px", md: "250px" },
            }}
          >
            <Avatar
              src={user.profilePic}
              alt={user.name}
              sx={{
                width: 150,
                height: 150,
                marginRight: { xs: "auto", sm: "20px" },
                marginBottom: { xs: "20px", sm: 0 },
                marginLeft: { xs: "auto", sm: 0 },
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: { sm: "10px" },
                textAlign: { xs: "center" },
              }}
            >
              <Typography
                variant="h5"
                component="h1"
                sx={{ fontWeight: "bold" }}
              >
                {user.name}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ marginBottom: "10px" }}
              >
                {user.userid}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: { xs: "20px", sm: "50px" },
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <Typography variant="body1" component="p">
                    {user.posts}
                  </Typography>
                  <Typography variant="body2" component="p">
                    Posts
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: "20px",
                  }}
                >
                  <Typography variant="body1" component="p">
                    {user.followers}
                  </Typography>
                  <Typography variant="body2" component="p">
                    Followers
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" component="p">
                    {user.following}
                  </Typography>
                  <Typography variant="body2" component="p">
                    Following
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Instagram;
