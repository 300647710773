import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import Offers from "./pages/Offers";
import ConversionReport from "./pages/ConversionReport";
import DetailOffer from "./pages/DetailOffer";
import Conversions from "./pages/Conversions";
import PrivateComponent from "./components/PrivateRoute";
import PaymentDetails from "./pages/PaymentDetails";
import UserDetails from "./pages/UserDetails";
import RegisterPage from "./pages/RegisterPage";
import Finance from "./pages/Finance";
import DynamicAds from "./pages/DynamicAds";
import ClickLogs from "./pages/ClickLogs";
import ClickLogs2 from "./pages/ClickLogs2";
import Chistory from "./pages/Chistory";
import PlayerPage from "./pages/PlayerPage";
import LaunchCamapgin from "./pages/LaunchCamapgin";
import ForgotPass from "./pages/ForgotPass";
import Adspace from "./pages/Adspace";
import Webportal from "./pages/Webportal";
import Addnewsite from "./pages/Addnewsite";
import UpdatedSite from "./pages/UpdatedSite";
import PushPortal from "./pages/PushPortal";
import PushPortalDashBoard from "./pages/PushPortalDashBoard";
import PushHistory from "./pages/PushHistory";
import Wallet from "./pages/Wallet";
import Notification from "./pages/Notification";
import UserProfile from "./../src/pages/UserProfile";
import AffPulse from "./pages/AffPulse";
import UserMainDetails from "./pages/UserMainDetails";
import OfferDetails from "./pages/OfferDetails";
import AffpushGallery from "./pages/AffpushGallery";
import Template from "./pages/Template";
import Home from "./pages/Home";
import SendPush from "./pages/SendPush";
import DigitalAdvertising from "./pages/DigitalAdvertising";
import AddPushNotification from "./pages/AddPushNotification";
import AffPush from "./pages/AffPush";
import ManageGalary from "./components/Manage Galary/ManageGalary";
import AffPulseLandingPage from "./pages/AffPulseLandingPage/AffPulseLandingPage";
import LandingPage from "./pages/LandingPage";
import Instagram from "./pages/AddSpacePages/Instagram";
import Youtube from "./pages/AddSpacePages/Youtube";
import Proposal from "./pages/AddSpacePages/Proposal";

import OutdoorAdvertising from "./pages/Outdoor/OutdoorAdvertising";
import Website from "./pages/Outdoor/Website";
import Metro from "./pages/Outdoor/Metro";

import OutdoorAirport from "./pages/AddSpacePages/Outdoor/OutdoorAirport";
import EventAdvertising from "./pages/AddSpacePages/Event/EventAdvertising";
import OutdoorMetro from "./pages/AddSpacePages/Outdoor/OutdoorMetro";
import CancellationRefund from "./pages/AffPage/CancellationRefund";
import PrivacyPolicy from "./pages/AffPage/PrivacyPolicy";
import CustSupport from "./pages/AffPage/CustSupport";
import Terms_Conditions from "./pages/AffPage/Terms_Conditions";
import AboutUs from "./pages/AffPage/AboutUs";
import PricingDetails from "./pages/AffPage/PricingDetails";
import SelfPromotion from "./pages/SelfPromotion";

export default function Router() {
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        {
          path: "app",
          element: <PrivateComponent children={<DashboardAppPage />} />,
        },
        {
          path: "offers",
          element: <Offers />,
        },
        { path: "affpushgallery", element: <AffpushGallery /> },
        { path: "template", element: <Template /> },
        { path: "home", element: <Home /> },
        { path: "sendpush", element: <SendPush /> },

        {
          path: "aff-pulse",
          element: <PrivateComponent children={<AffPulse />} />,
        },
        {
          path: "adspace",
          element: <AddPushNotification />,
        },
        {
          path: "clicklogs2",
          element: <ClickLogs2 />,
        },
        { path: "chistory", element: <Chistory /> },
        { path: "playerPage", element: <PlayerPage /> },
      ],
    },
    {
      path: "/affilate",
      element: <DashboardLayout />,
      children: [
        { path: "detail-offer", element: <DetailOffer /> },
        { path: "offers", element: <Offers /> },
        { path: "conversionReport", element: <ConversionReport /> },
        { path: "conversions", element: <Conversions /> },
        { path: "payment/details", element: <PaymentDetails /> },
        { path: "user/details", element: <UserDetails /> },
        { path: "user/detail", element: <UserMainDetails /> },
        { path: "finance", element: <Finance /> },
        { path: "dynamicAds", element: <DynamicAds /> },
        { path: "clicklogs", element: <ClickLogs /> },
        { path: "clicklogs2", element: <ClickLogs2 /> },
        { path: "addspace", element: <AddPushNotification /> },
        { path: "Webportal", element: <Webportal /> },
        { path: "pushPortal", element: <PushPortal /> },
        { path: "pushPortalDashboard", element: <PushPortalDashBoard /> },
        { path: "pushHistory", element: <PushHistory /> },
        {path: "wallet",
          element: <PrivateComponent children={<Wallet />} />,},
        { path: "notifications", element: <Notification /> },
        { path: "digitaladd", element: <DigitalAdvertising /> },
        { path: "website", element: <Website /> },
        { path: "outdoor", element: <OutdoorAdvertising /> },
        { path: "eventadvertising", element: <EventAdvertising /> },
        { path: "metro", element: <Metro /> },
        {
          path: "cancellation-and-refund-policy",
          element: <CancellationRefund />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "terms-and-conditions",
          element: <Terms_Conditions />,
        },
        {
          path: "Customer-support",
          element: <CustSupport />,
        },
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "pricing-details",
          element: <PricingDetails />,
        },
      ],
    },
    {
      path: "/digitaladd",
      element: <DashboardLayout />,
      children: [
        { path: "instagram", element: <Instagram /> },
        { path: "youtube", element: <Youtube /> },

        { path: "proposal", element: <Proposal /> },
        { path: "outdoor", element: <OutdoorAdvertising /> },
        { path: "website", element: <Website /> },

        { path: "metro", element: <Metro /> },

        { path: "proposal", element: <Proposal /> },
        { path: "outdoor", element: <OutdoorAdvertising /> },

        { path: "proposal", element: <Proposal /> },
        { path: "outdoorairport", element: <OutdoorAirport /> },
        { path: "eventadvertising", element: <EventAdvertising /> },
        { path: "outdoormetro", element: <OutdoorMetro /> },
      ],
    },
    {
      path: "/outdooradvertising",
      element: <DashboardLayout />,
      children: [{ path: "metro", element: <Metro /> }],
    },
    {
      path: "",
      element: <DashboardLayout />,
      children: [
        { path: "/campaign/:name", element: <OfferDetails /> },
        { path: "/aff-push", element: <AffPush /> },
        { path: "/aff-push/manage-galary", element: <ManageGalary /> },
        { path: "/aff-push/managesite", element: <UpdatedSite /> },
        { path: "/aff-push/Addnewsite", element: <Addnewsite /> },
        { path: "/self-promotions", element: <SelfPromotion /> },
      ],
    },

    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "register",
      element: <RegisterPage />,
    },
    {
      path: "/profile/:id",
      element: <UserProfile />,
    },
    {
      path: "forgotpass",
      element: <ForgotPass />,
    },
    {
      path: "affpulse",
      element: <AffPulseLandingPage />,
    },
    {
      path: "landingpage",
      element: <LandingPage />,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },

    {
      element: <DashboardLayout />,
      children: [
        {
          path: "cancellation-and-refund-policy",
          element: <CancellationRefund />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "terms-and-conditions",
          element: <Terms_Conditions />,
        },
        {
          path: "Customer-support",
          element: <CustSupport />,
        },
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "pricing-details",
          element: <PricingDetails />,
        },
      ],
    },
  ]);

  return routes;
}
