import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  Container,
  Avatar,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import bgLayer from "./../../images/Layer.png";
import affPulseHero from "./../../images/heroaffpulse.png";
import EastIcon from "@mui/icons-material/East";
import statiSticsPng from "../../images/statistics.png";
import googlePng from "../../images/Google.png";
import websiteTraficsPng from "../../images/Website traffic from India.png";
import instagramPng from "../../images/Instagram.png";
import seoFriendlyPng from "../../images/SEO Friendly.png";
import janBusinessImage01 from "../../images/Jan-Business_report_1 1.png";
import groupPng02 from "../../images/Group 1000001641.png";
import corporateManage03 from "../../images/corporate-management-strategy-solution-branding-concept 1.png";
import howItsWorkImg01 from "../../images/Group 01.png";
import howItsWorkImg02 from "../../images/Group 02.png";
import howItsWorkImg03 from "../../images/Group 03.png";
import howItsWorkImg04 from "../../images/Group 04.png";
import testimonialHexImg from "../../images/Testimonials.png";
import rectangleDesktopImg from "../../images/Rectangle-for-desktop.png";
import rectangleMobileImg from "../../images/Rectangle-for-mobile.png";
import Carousel from "react-bootstrap/Carousel";

const shakeAnimation = `
@keyframes subtleShake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-3px) rotate(-1deg); }
  50% { transform: translateX(3px) rotate(1deg); }
  75% { transform: translateX(-3px) rotate(-1deg); }
}
`;

function AffPulseLandingPage() {
  const theme = useTheme();
  // Initialize the currentIndex state with a default value of 0
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle clicking the "Next" button
  const handleClickNext = () => {
    // Update the currentIndex state
    setCurrentIndex((prevIndex) =>
      // Increment the previous index by 1, but do not exceed the last index
      Math.min(prevIndex + 3, services.length - 1)
    );
  };

  // Function to handle clicking the "Previous" button
  const handleClickPrev = () => {
    // Update the currentIndex state
    setCurrentIndex((prevIndex) =>
      // Decrement the previous index by 1, but do not go below 0
      Math.max(prevIndex - 3, 0)
    );
  };

  const stats = [
    {
      value: "1k+",
      description: "Customers visit Aff Pulse every month",
    },
    {
      value: "93%",
      description: "Satisfaction rate from our customers",
    },
    {
      value: "4.9",
      description: "Average customer rating out of 5.00!",
    },
  ];
  const services = [
    {
      title: "Website Traffic   From India",
      description:
        "Drive targeted traffic to your website with our advanced strategies. We use data-driven methods to ensure high-quality visits that convert..",
      img: websiteTraficsPng,
    },
    {
      title: "SEO Friendly Website Traffic",
      description:
        "Attract organic, high-quality visitors with our advanced SEO strategies that enhance your site's search engine rankings.",
      img: seoFriendlyPng,
    },
    {
      title: "Google Visitors with Retention",
      description:
        "Keep your Google visitors engaged and returning with our retention techniques. Increase your site's stickiness and lower bounce rates effectively.",
      img: googlePng,
    },
    {
      title: "Instagram Premium Services",
      description:
        "Enhance your Instagram presence with our premium offerings: grow genuine followers, boost post visibility with likes, and encourage interaction with premium services.",
      img: instagramPng,
    },
    {
      title: "Conversions From Indian",
      description:
        "Drive high-quality traffic to your site, tailored to India, ensuring maximum conversions and engagement.",
      img: statiSticsPng,
    },
  ];
  const content = [
    {
      img: janBusinessImage01,
      title: "Track your statistics with our advanced site.",
      content:
        "Track your site’s performance with our advanced analytics, ensuring you maximize engagement and conversions with detailed, real-time insights.",
    },
    {
      img: corporateManage03,
      title: "Ready to Elevate Your Online Presence?",
      content:
        "Join thousands of satisfied clients and see the difference AFFPULSE can make for your digital campaigns.",
    },
    {
      img: groupPng02,
      title: "Make your customers happy by giving services.",
      content:
        "Make your customers happy with our comprehensive services, designed to boost engagement, retention, and satisfaction across all your digital platforms.",
    },
  ];
  const howItsWorkscontent = [
    {
      img: howItsWorkImg01,
      title: "Create Account",
      content: "Create your account in just a few steps.",
    },
    {
      img: howItsWorkImg02,
      title: "Select AFFPULSE",
      content: "Select AFFPULSE from the sidebar",
    },
    {
      img: howItsWorkImg03,
      title: "Enter Details",
      content: "Provide the category and services for your campaigns.",
    },
    {
      img: howItsWorkImg04,
      title: "Click Submit",
      content: "Launch your campaign and watch your online presence soar",
    },
  ];

  const testimonials = [
    {
      name: "Saurabh Jaykar",
      image: "https://avatars.githubusercontent.com/u/124028591?v=4",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus mae cenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim.",
    },
    {
      name: "John Doe",
      image: "https://via.placeholder.com/100",
      content:
        "Another testimonial goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim.",
    },
    {
      name: "Jane Smith",
      image: "https://via.placeholder.com/100",
      content:
        "Yet another testimonial goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim.",
    },
  ];
  return (
    <div
      style={{
        background: "#FFFAFA",
      }}
    >
      <Box
        sx={{
          height: "100vh",
          backgroundImage: `url(${affPulseHero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: theme.palette.common.white,
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bgLayer})`,
            padding: theme.spacing(3),
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
          }}
        >
          {/* Main Heading */}
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontFamily: "Monoton, cursive",
              color: theme.palette.common.white,
              textAlign: {
                xs: "center",
                md: "left",
              },
              fontSize: {
                xs: "2.6rem",
                sm: "1.25rem",
                md: "3.3rem",
              },
            }}
          >
            AffPulse
          </Typography>

          <Box
            sx={{
              padding: theme.spacing(1),
              marginTop: {
                xs: theme.spacing(1),
                md: theme.spacing(3),
              },
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            {/* Sub Heading */}
            <Typography
              variant="h3"
              component="p"
              sx={{
                fontSize: {
                  xs: "1.6rem",
                  sm: "1.25rem",
                  md: "3rem",
                },
              }}
            >
              Amplify Your Digital <br /> Influence with
            </Typography>

            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontFamily: "Monoton, cursive",
                color: theme.palette.common.white,
                marginTop: {
                  xs: theme.spacing(3),
                  md: theme.spacing(2),
                },
                fontSize: {
                  xs: "1.9rem",
                  sm: "1.25rem",
                  md: "2.8rem",
                },
              }}
            >
              AffPulse
            </Typography>

            {/* Description */}
            <Typography
              sx={{
                marginTop: theme.spacing(5),
                fontWeight: "normal",
                fontSize: {
                  xs: "1.2rem",
                  sm: "1.25rem",
                  md: "1.3rem",
                },
              }}
              variant="h4"
              component="p"
            >
              Boost Your Online Presence Today !! <br /> Take your online
              campaigns to the next level with our <br /> tailored solutions.
            </Typography>

            <Button
              sx={{
                marginTop: theme.spacing(5),
              }}
              variant="contained"
            >
              Get Started <EastIcon />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        component="section"
        sx={{
          bgcolor: "#2E3A88",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
          padding: theme.spacing(2),
        }}
      >
        {stats.map((stat, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              maxWidth: "200px",
              margin: theme.spacing(2),
            }}
          >
            <Typography
              component="span"
              sx={{
                color: "white",
                fontSize: {
                  xs: "1.5rem",
                  md: "1.9rem",
                },
                fontWeight: "bold",
              }}
            >
              {stat.value}
            </Typography>
            <Typography
              component="span"
              sx={{
                color: "white",
                fontSize: {
                  xs: "0.9rem",
                  md: "1.0rem",
                },
              }}
            >
              {stat.description}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          minHeight: 620,
          bgcolor: "#EBF5FF",
          padding: "32px 16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            component="h2"
            sx={{
              fontSize: { xs: "2rem", md: "2.7rem" },
              fontWeight: "bold",
              color: "#333",
              textAlign: "center",
              marginBottom: "50px",
            }}
          >
            Our Services
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "32px",
              "@media (max-width: 959.95px)": {
                flexDirection: "column",
                alignItems: "center",
              },
            }}
          >
            {services
              .slice(currentIndex, currentIndex + 3)
              .map((service, index) => (
                <Box
                  key={index}
                  sx={{
                    flex: "1 1 calc(33.333% - 32px)",
                    maxWidth: "calc(33.333% - 32px)",
                    textAlign: "center",
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    padding: "16px",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "translateY(-10px)",
                    },
                    "@media (max-width: 959.95px)": {
                      marginBottom: "16px",
                      flex: "1 1 100%",
                      maxWidth: "100%",
                      "&:last-child": {
                        marginBottom: 0,
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: 100,
                      width: 100,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      fontSize: "1.5rem",
                      marginBottom: "16px",
                      backgroundColor: "#D3E9FF",
                      margin: "0 auto",
                      padding: "8px",
                    }}
                  >
                    <img src={service.img} alt={service.name} />
                  </Box>
                  <Typography
                    component="p"
                    sx={{
                      color: "#275582",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                      marginTop: "16px",
                    }}
                  >
                    {service.title}
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      color: "#275582",
                      fontSize: "1.1rem",
                      marginTop: "8px",
                    }}
                  >
                    {service.description}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "32px",
            }}
          >
            <Button
              onClick={handleClickPrev}
              disabled={currentIndex === 0}
              sx={{ marginRight: "16px" }}
            >
              Prev
            </Button>
            <Button
              onClick={handleClickNext}
              disabled={currentIndex + 3 >= services.length}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
      <Container sx={{ my: 3 }}>
        <style>{shakeAnimation}</style>
        {content.map((service, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            {index % 2 === 0 ? (
              <>
                <Grid item xs={12} md={6}>
                  <Avatar
                    src={service.img}
                    alt={`Service ${index + 1}`}
                    sx={{
                      width: { xs: "90%", md: "80%" },
                      height: "auto",
                      borderRadius: 2,
                      display: "block",
                      margin: "0 auto",
                      "&:hover": {
                        animation: "subtleShake 0.5s",
                        animationIterationCount: "infinite",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      padding: 2,
                      borderRadius: 2,
                      textAlign: "start",
                      maxWidth: { xs: "90%", md: 500 },
                      display: "block",
                      margin: "0 auto",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#161C2D",
                        fontSize: {
                          xs: "1.2rem",
                          md: "2rem",
                        },
                      }}
                      gutterBottom
                    >
                      {service.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        marginTop: {
                          xs: 2,
                          md: 3,
                          lineHeight: 2.2,
                          color: "#161c2d",
                        },
                      }}
                    >
                      {service.content}
                    </Typography>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                  <Box
                    sx={{
                      padding: 2,
                      borderRadius: 2,
                      textAlign: "start",
                      maxWidth: { xs: "90%", md: 500 },
                      display: "block",
                      margin: "0 auto",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#161C2D",
                        fontSize: {
                          xs: "1.2rem",
                          md: "2rem",
                        },
                      }}
                      gutterBottom
                    >
                      {service.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        marginTop: {
                          xs: 2,
                          md: 3,
                          lineHeight: 2.2,
                          color: "#161c2d",
                        },
                      }}
                    >
                      {service.content}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                  <Avatar
                    src={service.img}
                    alt={`Service ${index + 1}`}
                    sx={{
                      width: { xs: "90%", md: "80%" },
                      height: "auto",
                      borderRadius: 2,
                      display: "block",
                      margin: "0 auto",
                      "&:hover": {
                        animation: "subtleShake 0.5s",
                        animationIterationCount: "infinite",
                      },
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </Container>
      <Box
        sx={{
          backgroundColor: "#DFEFFF",
        }}
      >
        <Typography
          component="h2"
          sx={{
            fontSize: { xs: "2rem", md: "2.7rem" },
            fontWeight: "bold",
            color: "#333",
            textAlign: "center",
            pt: "50px",
          }}
        >
          How does it work?
        </Typography>
        <Typography
          component="h2"
          sx={{
            fontSize: { xs: "1rem", md: "1.2rem" },
            color: "#333",
            textAlign: "center",
            pb: "50px",
            pt: "5px",
          }}
        >
          Getting started with AFFPULSE is easy
        </Typography>

        <Container sx={{ mt: 3, pb: 3 }}>
          <style>{shakeAnimation}</style>
          {howItsWorkscontent.map((service, index) => (
            <Grid container spacing={3} key={index} alignItems="center">
              {index % 2 === 0 ? (
                <>
                  <Grid item xs={12} md={6}>
                    <Avatar
                      src={service.img}
                      alt={`Service ${index + 1}`}
                      sx={{
                        width: { xs: "90%", md: "80%" },
                        height: "auto",
                        borderRadius: 2,
                        display: "block",
                        margin: "0 auto",
                        "&:hover": {
                          animation: "subtleShake 0.5s",
                          animationIterationCount: "infinite",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        padding: 2,
                        borderRadius: 2,
                        textAlign: "start",
                        maxWidth: { xs: "90%", md: 500 },
                        display: "block",
                        margin: "0 auto",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#161C2D",
                          fontSize: {
                            xs: "1.2rem",
                            md: "2rem",
                          },
                        }}
                        gutterBottom
                      >
                        {service.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          marginTop: {
                            xs: 2,
                            md: 3,
                            lineHeight: 2.2,
                            color: "#161c2d",
                          },
                        }}
                      >
                        {service.content}
                      </Typography>
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                    <Box
                      sx={{
                        padding: 2,
                        borderRadius: 2,
                        textAlign: "start",
                        maxWidth: { xs: "90%", md: 500 },
                        display: "block",
                        margin: "0 auto",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#161C2D",
                          fontSize: {
                            xs: "1.2rem",
                            md: "2rem",
                          },
                        }}
                        gutterBottom
                      >
                        {service.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          marginTop: {
                            xs: 2,
                            md: 3,
                            lineHeight: 2.2,
                            color: "#161c2d",
                          },
                        }}
                      >
                        {service.content}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                    <Avatar
                      src={service.img}
                      alt={`Service ${index + 1}`}
                      sx={{
                        width: { xs: "90%", md: "80%" },
                        height: "auto",
                        borderRadius: 2,
                        display: "block",
                        margin: "0 auto",
                        "&:hover": {
                          animation: "subtleShake 0.5s",
                          animationIterationCount: "infinite",
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        </Container>
      </Box>

      <Box
        sx={{
          bgcolor: "#DEE5FF",
          py: {
            xs: 3,
            md: 16,
          },
          position: "relative",
        }}
      >
        <Avatar
          src={testimonialHexImg}
          sx={{
            position: "absolute",
            top: "50px",
            right: "10%",
            transform: "translateX(0%)",
            width: "140px",
            height: "140px",
            borderRadius: "0%",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        />
        <Typography
          component="h2"
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" },
            fontWeight: "bold",
            color: "#001E6C",
            textAlign: "center",
          }}
        >
          WHAT OUR CLIENTS SAY
        </Typography>

        <style>
          {`
          .carousel-control-next-icon,
          .carousel-control-prev-icon {
            background-color: #275EEF;
            // background-image: none; /* Remove default icon */
            width: 30px;
            height: 30px;
            border-radius: 50%;
            padding: "10px"
          }

          .carousel-indicators .active,
          .carousel-indicators li {
            background-color:  #275EEF;
          }
        `}
        </style>
        <Carousel className="mt-3">
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index}>
              <Paper
                sx={{
                  width: { xs: "90%", md: "800px" },
                  height: { xs: "500px", md: "280px" },
                  borderRadius: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  bgcolor: "#ffffff",
                  padding: "20px",
                }}
              >
                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: { xs: "column", md: "row" },
                    gap: { xs: "10px", md: "40px" },
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: `url(${testimonial.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: { xs: "100px", md: "150px" },
                      height: { xs: "100px", md: "150px" },
                      borderRadius: "50%",
                      flexShrink: 0,
                    }}
                  ></Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      component="h3"
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#001E6C",
                        textAlign: { xs: "center", md: "left" },
                      }}
                    >
                      {testimonial.name}
                    </Typography>
                    <Typography
                      component="p"
                      sx={{
                        fontSize: "1rem",
                        color: "#001E6C",
                        textAlign: { xs: "center", md: "left" },
                        padding: { xs: "10px", md: "10px 0" },
                        lineHeight: 1.6,
                      }}
                    >
                      {testimonial.content}
                    </Typography>
                  </Box>
                </Container>
              </Paper>
            </Carousel.Item>
          ))}
        </Carousel>
      </Box>
      <Box
        sx={{
          backgroundColor: "#161C2D",
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: theme.palette.common.white,
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${rectangleDesktopImg})`,
            padding: theme.spacing(4),
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: theme.spacing(6),
              md: theme.spacing(0),
            },
          }}
        >
          <Box
            sx={{
              color: "white",
              width: {
                xs: "95%",
                md: 400,
              },
            }}
          >
            <Avatar src={`https://via.placeholder.com/100`}></Avatar>{" "}
            <Typography
              component="p"
              sx={{
                fontSize: "2rem",
                fontWeight: "bold",
                marginTop: "16px",
              }}
            >
              Get a free consultancy from our expert right <br />
              now !
            </Typography>
            <Typography
              component="p"
              sx={{
                fontSize: "1.1rem",
                marginTop: "8px",
                lineHeight: 2,
              }}
            >
              Gain valuable insights from our expert consultancy, completely
              free! Get expert advice tailored to your needs, available now.
              Unlock the expertise you need to succeed, without any cost or
              commitment!
            </Typography>
          </Box>
          <Paper
            sx={{
              padding: theme.spacing(2),
              backgroundColor: theme.palette.common.white,
              borderRadius: 1,
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              width: 320,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ color: "#161C2D", width: "100%" }}>
              <div>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  className="form-control mt-2"
                  placeholder="Enter your name"
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginBottom: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="form-control mt-2"
                  placeholder="Enter your email"
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginBottom: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div>
                <label htmlFor="phone">Phone No.</label>
                <input
                  type="tel"
                  id="phone"
                  className="form-control mt-2"
                  placeholder="Enter your phone number"
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginBottom: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div>
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  className="form-control mt-2"
                  placeholder="Enter your message"
                  rows="4"
                  style={{
                    width: "100%",
                    padding: "8px",
                    marginBottom: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div className="text-center">
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "8px",
                    padding: "8px 24px",
                    width: "100%",
                    backgroundColor: "#0D134F",
                  }}
                >
                  Get Free Consultancy
                </Button>
              </div>
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
}

export default AffPulseLandingPage;
