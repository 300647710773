import { Box } from "@mui/material";
import React from "react";
import NotificationCard from "src/components/NotificationCard/NotificationCard";
import VolumeUpTwoToneIcon from "@mui/icons-material/VolumeUpTwoTone";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';

function Notification() {
  const linearGradient1 =
    "linear-gradient(to bottom, #6683b1, #7b91b9, #8fa0c0, #a3afc8, #b6becf, #bcc4d5, #c3cbdb, #c9d1e1, #c4cfe7, #bfcdee, #bacbf4, #b6c9fa)";

  return (
    <>
      <Box
        sx={{
          padding: { xs: "10px 0", md: "10px" },
          backgroundImage: linearGradient1,
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <NotificationCard
            notificationIcon={
              <VolumeUpTwoToneIcon
                fontSize="inherit"
                sx={{ fontSize: { xs: "2.5em", md: "4em" } }}
              />
            }
            title="Announcement 1"
            subTitle="New Loanable and Collateral ........."
            length={20}
            time="16/12"
          />
          <NotificationCard
            notificationIcon={
              <CardGiftcardOutlinedIcon
                fontSize="inherit"
                sx={{ fontSize: { xs: "2.5em", md: "4em" } }}
              />
            }
            title="Campaign"
            subTitle="Affworld Having Horizon 2024"
            length={20}
            time="16/12"
          />{" "}
          <NotificationCard
            notificationIcon={
              <HubOutlinedIcon
                fontSize="inherit"
                sx={{ fontSize: { xs: "2.5em", md: "4em" } }}
              />
            }
            title="Connections"
            subTitle="Affworld Having Horizon 2024."
            length={20}
            time="16/12"
          />{" "}
          <NotificationCard
            notificationIcon={
              <ArticleOutlinedIcon
                fontSize="inherit"
                sx={{ fontSize: { xs: "2.5em", md: "4em" } }}
              />
            }
            title="Proposal"
            subTitle="Affworld Having Horizon 2024"
            length={20}
            time="16/12"
          />{" "}
          <NotificationCard
            notificationIcon={
              <UpdateOutlinedIcon
                fontSize="inherit"
                sx={{ fontSize: { xs: "2.5em", md: "4em" } }}
              />
            }
            title="Updates"
            subTitle="Affworld Having Horizon 2024"
            length={20}
            time="16/12"
          />{" "}
          <NotificationCard
            notificationIcon={
              <AccountBalanceWalletOutlinedIcon
                fontSize="inherit"
                sx={{ fontSize: { xs: "2.5em", md: "4em" } }}
              />
            }
            title="Wallet"
            subTitle="New Loanable and Collateral ........."
            length={20}
            time="16/12"
          />{" "}
        </Box>
      </Box>
    </>
  );
}

export default Notification;
