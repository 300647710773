import React, { useState } from 'react';
import { Grid, Paper, Box, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
const images = [
    'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg',
    'https://img.freepik.com/free-photo/colorful-design-with-spiral-design_188544-9588.jpg?size=626&ext=jpg&ga=GA1.1.1224184972.1713657600&semt=sph',
    'https://cdn.pixabay.com/photo/2016/05/05/02/37/sunset-1373171_1280.jpg',
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRJSt9gJX4GhpmE0KMYDE6BNtlAqfI-iSghw&usqp=CAU",
    // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTK500IQ_2NpiNk7Ed_4phbWDtLSZ7QGWuNA&usqp=CAU",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYNkjHLKtpNvuXpLcHXfRtNzOwbc2E8EUWbQ&usqp=CAU" 
];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        // color: theme.palette.text.secondary,
        borderRadius: "15px",
        position: 'relative',
        overflow: 'hidden',
        
    },
    image: {
        Width: '100%',
        filter: 'blur(0)',
        height: '200px',
        borderRadius:"15px",
        objectFit: 'cover',
    },
    deleteIcon: {
        position: 'absolute',
        top: 5,
        right: 5,
        color: 'red',
        background: '#fff',
        borderRadius: '50%',
        cursor: 'pointer',
        zIndex:1,
        fontSize:"20px",
        
    },
    input: {
        display: 'none',
    },
}));

function AffpushGallery() {
    const classes = useStyles();
    const [uploadedImages, setUploadedImages] = useState([]);
    const [isSelecting, setIsSelecting] = useState(false);
    const handleImageUpload = (e) => {
        const files = e.target.files;
        const urls = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const url = URL.createObjectURL(file);
            urls.push(url);
        }
        setUploadedImages([...uploadedImages, ...urls]);
    };

    const toggleSelect = () => {
        setIsSelecting(!isSelecting);
    };  

    return (
        <Box
            sx={{
                background: "#a5c1dc",
                padding: "10px 15px",
                fontFamily: "Sree Krushnadevaraya",
                minHeight: "100vh"
            }}
        >
            <p
                style={{
                    color: "#12314F",
                    fontWeight: "400",
                    fontSize: "30px"
                }}
            >
                Image Gallery
            </p>

     
      <Typography>
      <IconButton sx={{
        color:"#0C53B7",
        fontSize:"15px",
        marginLeft:"auto",
        display:"block"
      }}
      onClick={toggleSelect}> {isSelecting ? "Cancel" : "Select"}</IconButton>
      </Typography>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4} >
                        <Paper className={classes.paper} style={{ background: "#DCDCDC",width:"200px", padding:"0 0px",margin:"0 0", height:"200px"}}> 
                            <label htmlFor="contained-button-file">
                                <IconButton component="span">
                                    <CloudUploadIcon
                                        sx={{
                                            display: "block",
                                            fontSize: "50px",
                                            fill: "black",
                                            marginTop: "2rem",
                                            marginLeft: "1rem",
                                            marginRight: "1rem",
                                            marginBottom: "0"
                                        }}
                                    />
                                </IconButton>
                            </label>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleImageUpload}
                            />
                            <p
                                style={{
                                    fontSize: "15px",
                                    wordWrap: "break-word"
                                }}
                            >
                                Drop photos here, or click to select files
                            </p>
                        </Paper>
                    </Grid>
                    {images.map((imageUrl, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper className={classes.paper} style={{ position: 'relative' }}>
                                {isSelecting && (
                                   <Box component={"div"} sx={{padding:"25px",  position:"absolute", top:5, right:5}}>
                                     <DeleteIcon
                                      className={classes.deleteIcon}
                                    />
                                   </Box>
                                )}
                                <img
                                    src={imageUrl}
                                    alt={`Image ${index}`}
                                    className={classes.image}
                                    style={{ filter: isSelecting ? 'blur(5px)' : 'blur(0)',background:"cover" }}
                                />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Box>
    );
}

export default AffpushGallery;
