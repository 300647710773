import { Grid, IconButton, Typography, Box, Avatar } from "@mui/material";
import React from "react";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { toast } from "react-toastify";

function AffPushTemplateCard({ clickAction, imageUrl, title, body }) {
  const handleCopyActionLink = (actionLink) => {
    navigator.clipboard
      .writeText(actionLink)
      .then(() => {
        toast.success("Link copied successfully");
      })
      .catch((err) => {
        // Error parameter included
        console.error("Failed to copy text:", err);
      });
  };

  return (
    <Box
      sx={{
        bgcolor: "#F9F9F9",
        p: { xs: 1, md: 1 },
        borderRadius: "20px",
        boxShadow: "0px 8px 24px rgba(0,0,0,0.1)",
        overflow: "hidden",
        color: "#000000",
        width: { xs: 380, sm: 380, md: 400 }, // Adjust width for various screen sizes
        border: "1px solid #b669ff",
      }}
    >
      <Grid container spacing={{ xs: 1, md: 2 }} alignItems="center">
        <Grid item xs={3} sm={2}>
          <Avatar
            src={imageUrl}
            alt="Profile Image" // Alt text for accessibility
            sx={{
              height: { xs: 50, sm: 50, md: 50 },
              width: { xs: 50, sm: 50, md: 50 },
              bgcolor: "white",
              borderRadius: "50%",
              boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
              border: "1px dashed #b669ff",
              margin: "0 auto",
              display: "block",
            }}
          />
        </Grid>
        <Grid item xs={7} sm={8} sx={{ pl: { xs: 1, md: 2 } }}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "bold", fontSize: { xs: 13, md: 15 } }}
          >
            {title}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: { xs: 12, md: 13 } }}>
            {body}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handleCopyActionLink(clickAction)}>
            <InsertLinkIcon fontSize="large" style={{ color: "#b669ff" }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default React.memo(AffPushTemplateCard); // Optimizing with React.memo
