import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import HistoryIcon from '@mui/icons-material/History';
import ImageIcon from '@mui/icons-material/Image';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import BoltSharpIcon from '@mui/icons-material/BoltSharp';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PhotoAlbumOutlinedIcon from '@mui/icons-material/PhotoAlbumOutlined';

function Home() {
  return (
    <div className='d-flex mt-2' style={{flexWrap:"wrap"}}>

<div style={{backgroundColor:"#E9FCD4", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-2'>
        <HistoryIcon  color='success'/>
        <h1>92</h1>
        <p>Push History</p>
        </div>


        <div style={{backgroundColor:"#E9FCD4", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-2'>
        <HistoryIcon  color='success'/>
        <h1>92</h1>
        <p>Push History</p>
        </div>


        <div style={{backgroundColor:"#B3E5FC", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-2'>
        <HistoryIcon  color='success'/>
        <h1>92</h1>
        <p>Push History</p>
        </div>


        <div style={{backgroundColor:"#E9FCD4", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-2'>
        <ImageIcon  color='success'/>
        <h1>200</h1>
        <p>Image galley</p>
        </div>

        <div style={{backgroundColor:"#E9FCD4", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-2'>
        <HistoryIcon  color='success'/>
        <h1>92</h1>
        <p>Push History</p>
        </div>

        <div style={{backgroundColor:"#E9FCD4", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-4'>
        <PhotoAlbumOutlinedIcon  color='success'/>
        <h1>92</h1>
        <p>Templates Library</p>
        </div>

        <div style={{backgroundColor:"#E9FCD4", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-4'>
        <BoltSharpIcon  color='success'/>
        <h1>92</h1>
        <p>Outsource My Site</p>
        </div>

        <div style={{ backgroundColor: "#B3E5FC", width: "209px", height: "238px", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: "center", borderRadius: "15px" }} className='ms-4 mt-4'>
        <FontAwesomeIcon icon={faGear} color='blue' />
        <h1>92</h1>
        <p>Settings</p>
      </div>

        <div style={{backgroundColor:"#E9FCD4", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-4'>
        <SignalCellularAltIcon  color='success'/>
        <h1>92</h1>
        <p>Statistics</p>
        </div>

        <div style={{backgroundColor:"#E9FCD4", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-4'>
        <DescriptionIcon  color='success'/>
        <h1>92</h1>
        <p> Reports</p>
        </div>

        <div style={{backgroundColor:"#E9FCD4", width:"209px",height:"238px", display:"flex",flexDirection:"column",justifyContent:"center", textAlign:"center", alignItems:"center", borderRadius:"15px"}} className='ms-4 mt-4'>
        <AccountBalanceWalletOutlinedIcon  color='success'/>
        <h1>92</h1>
        <p>Payments</p>
        </div>

        


     
    </div>
  )
}

export default Home
