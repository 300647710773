import { Box, Link as MuiLink, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Affworld</title>
        <meta
          name="description"
          content="Read AffWorld.io's Privacy Policy to understand how we collect, use, and protect your personal information. Your privacy and data security are our top priorities."
        />
        <meta
          name="keywords"
          content="AffWorld.io privacy policy, data protection, personal information, privacy practices, information security, data collection, user privacy, data privacy policy, Affworld, Affworld privacy policy"
        />
      </Helmet>
      <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
        Privacy Policy
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Introduction
      </Typography>
      <Typography>
        Affworld Technology ("we," "our," or "us") is committed to protecting
        your privacy. This Privacy Policy outlines how we collect, use,
        disclose, and safeguard your information when you visit our website or
        use our services.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Information We Collect <br />
        Personal Information:
      </Typography>
      <Typography>
        Name
        <br />
        Email address
        <br />
        Contact numter
        <br />
        Billing information
        <br />
        Any other information you voluntarily provide
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Non-Personal Information:
      </Typography>
      <Typography>
        IP address
        <br />
        Browser type
        <br />
        Operating system
        <br />
        Browsing behavior
        <br />
        Cookies and similar tracking technologies
        <br />
        How We Use Your Information
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Personal Information:
      </Typography>
      <Typography>
        To provide and manage our services
        <br />
        To process transactions
        <br />
        To communicate with you
        <br />
        To personalize your experience
        <br />
        To send promotional materials and updates (with your consent)
        <br />
        To respond to inquiries and provide customer support
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Non-Personal Information:
      </Typography>
      <Typography>
        To improve our website and services
        <br />
        To analyze usage patterns
        <br />
        To administer our website
        <br />
        How We Share Your Information
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Third-Party Service Providers:
      </Typography>
      <Typography>
        We may share your information with third-party service providers to
        facilitate our services (e.g., payment processors, email services).
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Legal Requirements:
      </Typography>
      <Typography>
        We may disclose your information if required by law or in response to
        legal processes.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Business Transfers:
      </Typography>
      <Typography>
        In the event of a merger, acquisition, or sale of assets, your
        information may be transferred to the new owner. Security of Your
        Information We use administrative, technical, and physical security
        measures to protect your personal information. However, no method of
        transmission over the Internet or electronic storage is completely
        secure, and we cannot guarantee its absolute security.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Your Choices
        <br />
        Opt-Out:
      </Typography>
      <Typography>
        You may opt-out of receiving promotional emails by following the
        unsubscribe link in the emails.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Access and Correction:
      </Typography>
      <Typography>
        You may request access to or correction of your personal information by
        contacting us at
        <MuiLink
          href="mailto:privacy@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >
          privacy@affworld.io
        </MuiLink>
        .
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Cookies:
      </Typography>
      <Typography>
        You can set your browser to refuse cookies or alert you when cookies are
        being sent. However, some features of our website may not function
        properly without cookies.
        <br />
        Changes to This Privacy Policy
        <br />
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Contact Us
      </Typography>
      <Typography>
        If you have any questions about this Privacy Policy, please contact us
        at
        <MuiLink
          href="mailto:privacy@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >
          privacy@affworld.io
        </MuiLink>{" "}
        or call our customer service hotline at{" "}
        <MuiLink
          href="tel:+971542997582"
          variant="body1"
          color="primary"
          underline="hover"
        >
          +971542997582
        </MuiLink>
        .
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Terms and Conditions{" "}
        </Typography>
        These Terms and Conditions ("Terms") govern your use of the Affworld
        Technology website and services. By accessing or using our website or
        services, you agree to be bound by these Terms.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Use of Our Services
        <br />
        Eligibility:
      </Typography>
      <Typography>
        You must be at least 18 years old to use our services.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Account Registration:
      </Typography>
      <Typography>
        You may be required to create an account to access certain services. You
        agree to provide accurate and complete information and keep your account
        information updated.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Prohibited Activities:
        <br />
        You agree not to engage in any unlawful or prohibited activities,
        including but not limited to:
      </Typography>
      <Typography>
        Violating any applicable laws or regulations
        <br />
        Infringing on the rights of others
        <br />
        Using our services to distribute spam or malware
        <br />
        Attempting to gain unauthorized access to our systems
        <br />
        Intellectual Property
        <br />
        All content on our website, including text, graphics, logos, and
        software, is the property of Affworld Technology or its licensors and is
        protected by copyright and other intellectual property laws. You agree
        not to reproduce, distribute, or create derivative works from any
        content without our prior written consent.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Service Availability:
      </Typography>
      <Typography>
        We strive to ensure our services are available at all times, but we do
        not guarantee uninterrupted access.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Accuracy of Information:
      </Typography>
      <Typography>
        We make reasonable efforts to provide accurate information on our
        website, but we do not warrant the accuracy, completeness, or
        reliability of any information.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Third-Party Links:
        </Typography>
        Our website may contain links to third-party websites. We are not
        responsible for the content or practices of these websites.
        <br />
        Limitation of Liability
        <br />
        To the fullest extent permitted by law, Affworld Technology shall not be
        liable for any indirect, incidental, special, or consequential damages
        arising out of or in connection with your use of our services.
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Indemnification
        </Typography>
        You agree to indemnify and hold Affworld Technology harmless from any
        claims, damages, losses, liabilities, and expenses arising out of or in
        connection with your use of our services or violation of these Terms.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Changes to These Terms
        </Typography>
        We may update these Terms from time to time. We will notify you of any
        changes by posting the new Terms on this page. Your continued use of our
        services after any changes constitutes your acceptance of the new Terms.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Effective Date:
        </Typography>{" "}
        These Terms are effective as of 01/08/2024
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Contact Us
        </Typography>
        If you have any questions about these Terms, please contact us at
        <MuiLink
          href="mailto:support@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >
          support@affworld.io
        </MuiLink>{" "}
        or call our customer service hotline at{" "}
        <MuiLink
          href="tel:+971542997582"
          variant="body1"
          color="primary"
          underline="hover"
        >
          +971542997582
        </MuiLink>
        .
      </Typography>
    </>
  );
}

export default PrivacyPolicy;
