import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  ListItemIcon,
  Icon,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import { bgBlur } from "../../../utils/cssStyles";
import HomeIcon from "@mui/icons-material/Home";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import BarChartIcon from "@mui/icons-material/BarChart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import bidIcon from "./../../../images/bid-icon.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import account from "src/_mock/account";

const NAV_WIDTH = 280;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const NavItem = ({ to, text, icon, elevation }) => (
  <NavLink
    to={to}
    className="nav-link"
    style={{ marginLeft: 10, textDecoration: "none" }}
  >
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      elevation={elevation}
      sx={{
        borderRadius: 1,
        boxShadow: elevation === 2 ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      <ListItemIcon>
        {icon === "home" && <Icon component={HomeIcon} fontSize="small" />}
        {icon === "chart-line-2" && (
          <Icon component={TrendingUpIcon} fontSize="small" />
        )}
        {icon === "bar-chart-outline" && (
          <Icon component={BarChartIcon} fontSize="small" />
        )}
        {icon === "credit-card-outline" && (
          <Icon component={CreditCardIcon} fontSize="small" />
        )}
      </ListItemIcon>
      <Typography variant="h6" color="text.primary">
        {text}
      </Typography>
    </Stack>
  </NavLink>
);

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  elevation: PropTypes.number.isRequired,
};

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Icon component={HomeIcon} fontSize="small" />{" "}
          {/* Example usage of Material-UI Icon */}
        </IconButton>
        <Box sx={{ flexGrow: 3 }} />
        <Stack
          direction="row"
          alignItems="center"
          color="black"
          spacing={{ xs: 1.5, sm: 1 }}
        >
          {/* <NavItem to="/" text="Home" icon="home" elevation={2} /> */}
          {/* <NavItem to="/affilate/conversions" text="Conversions" icon="chart-line-2" elevation={2} />
          <NavItem to="/affilate/user/details" text="Overview" icon="bar-chart-outline" elevation={2} />
          <NavItem to="/affilate/conversions" text="Finance" icon="credit-card-outline" elevation={2} /> */}
          <Searchbar />
          <Box>
            <Link
              to={`/profile/${account.displayName}`}
            >
              <ElectricBoltIcon className="mx-auto d-block text-dark" />
            </Link>
          </Box>

          <Box>
            {" "}
            <img
              src={bidIcon}
              alt=""
              height={"22px"}
              className="mx-auto d-block text-dark"
            />
          </Box>

          <Box>
            <Link>
              <SupportAgentIcon className="mx-auto d-block text-dark" />
            </Link>
          </Box>
          <Box>
            <Link to={"/affilate/notifications"}>
              <NotificationsIcon className="mx-auto d-block text-dark" />
            </Link>
          </Box>
          <Box>
            <AccountPopover />
          </Box>
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
