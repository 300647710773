import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  transition: "transform 0.3s",
  "&:hover": {
    transform: "scale(1.1)",
  },
  borderRadius: "0",
  fontWeight: "450",
}));

const NavigationButton = styled(Button)(({ theme }) => ({
  color: "black",
  textTransform: "none",
  fontSize: "0.9rem",
  fontWeight: "400",
}));

function Testnav() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ width: 250,}}>
      <Typography variant="h6" sx={{ my: 2, textAlign: "center" }}>
        AFFPUSH
      </Typography>
      <Divider />
      <List>
        {["Home", "About", "Contact", "Testimonials"].map((text) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
        <StyledButton
          variant="outlined"
          color="inherit"
          sx={{ color: "black", border: "none" }}
        >
          Login
        </StyledButton>
        <StyledButton variant="contained" color="primary">
          Sign Up
        </StyledButton>
      </Box>
    </Box>
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ marginRight: 2, color: "black" }}
            >
              AFFPUSH
            </Typography>
            {isMobile ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ marginLeft: "auto" }}
              >
                <MenuIcon
                  sx={{
                    fill: "black",
                  }}
                />
              </IconButton>
            ) : (
              <>
                <NavigationButton>Home</NavigationButton>
                <NavigationButton>About</NavigationButton>
                <NavigationButton>Contact</NavigationButton>
                <NavigationButton>Testimonials</NavigationButton>
                <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
                  <StyledButton
                    variant="outlined"
                    color="inherit"
                    sx={{ color: "black", border: "none" }}
                  >
                    Login
                  </StyledButton>
                  <StyledButton variant="contained" color="primary">
                    Sign Up
                  </StyledButton>
                </Box>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </>
  );
}

export default Testnav;
