import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Typography,
  Grid,
  TextField,
  LinearProgress,
  Input,
  Paper,
  Modal,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { blue, red } from "@mui/material/colors";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { useTheme } from "@mui/material";
import {
  getUserFromLocalStorage,
  getResFromLocalStorage,
} from "../utils/localStorage";
import ManagersTable from "./ManagersTable";
import Campaign from "./Campaign";
import account from "src/_mock/account";
import { Image } from "react-bootstrap";
import "./userStyle.css";
import PostBackMiniCard from "src/components/UserDetails/PostBackMiniCard";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import IosShareIcon from "@mui/icons-material/IosShare";
import PhoneIcon from "@mui/icons-material/Phone";
import skypeSvg from "./../images/skype.svg";
import EditIcon from "@mui/icons-material/Edit";
import LanguageIcon from "@mui/icons-material/Language";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import SnapChatIcon from "./../images/snapchat.svg";
import TickTockIcon from "./../images/ticktok.svg";
import RedditIcon from "@mui/icons-material/Reddit";
function UserDetails() {
  const useStyles = makeStyles((theme) => ({
    boxstyle: {
      color: `#7928CA`,
      fontSize: "40px",
      fontWeight: "bold",
      textAlign: "center",
    },
    boxstyleForm: {
      margin: "auto",
      border: "2px solid white",
      padding: "10px",
      borderRadius: "10px",
    },
    boxstyleForm1: {
      margin: "auto",
      display: "flex",
      alignItems: "left",
      justifyContent: "left",
      padding: "10px",
      borderRadius: "10px",
    },
    innerbox: {
      border: "0.5px solid gray",
      borderRadius: "10px",
      padding: "10px",
      width: "125px",
    },
    dabox: {
      border: "0.5px solid gray",
      borderRadius: "10px",
      padding: "10px",
      width: "240px",
      marginLeft: "10px",
    },
    textinBox: {
      marginLeft: "10px",
      fontWeight: "bold",
      marginTop: "10px",
      cursor: "pointer",
    },
  }));

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [affiliateData, setAffiliateData] = useState([]);
  const [verificationSent, setVerificationSent] = useState(false);
  const URL = process.env.REACT_APP_PROD_ADMIN_API;
  const URL2 = process.env.REACT_APP_PROD_API;
  const urlVerifyMail = `${URL2}/api/affiliates/send_verification_mail`;
  const urlVerifyAfterMail = `${URL2}/api/affiliates/`;
  const [checkEmailVerifiedStatus, setCheckEmailVerifiedStatus] =
    useState(false);
  const [affData, setAffData] = useState({});
  const [loading, setLoading] = useState(false);
  const user2 = getUserFromLocalStorage();
  const accessToken = user2?.data.access_token;
  const [image, setImage] = useState(null);
  const [affiliate, setAffiliate] = useState();
  const [nameBeni, setNameBeni] = useState();
  const [last, setLast] = useState();
  const [username, setUserName] = useState();
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [activeTab, setActiveTab] = useState("overview");
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [postCode, setPostCode] = useState();
  const [skype, setSkype] = useState("");
  const [age, setAge] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const urlWallet = `${URL2}/api/analytics/wallet`;
  const [walletData, setWalletData] = useState([]);
  const [campaignData, setCampaignData] = useState();
  const [campaginName, setCampaginName] = useState("");
  const [affiliateAdditionalInfo, setAffiliateAdditionalInfo] = useState({});
  const [b, setB] = useState({});
  const [verified , setVerified] = useState(false);
  const [mainProfile , setMainProfile] = useState("");

  const theme = useTheme();
  // const url = `${URL2}/api/analytics/clicks`;
  const url1 = `${URL2}/api/affiliates`;
  const url_image = `${URL2}/api/affiliates/update_profile_image`;
  const url_get_aff_info = `${URL2}/api/affiliates/additional_info`;
  const user1 = getResFromLocalStorage();
  const date = new Date(affiliateData.created_at).toLocaleString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
    hour12: false,
  });
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getTabClass = (tab) => {
    return tab === activeTab ? "active-tab" : "inactive-tab";
  };

  const sendVerificationEmail = async () => {
    // Make an API request to send the verification email.

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(urlVerifyMail, {
        method: "GET",
        mode: "cors",
        headers: headers,
      });
      console.log("RESPONSE:__", response);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();

      setVerificationSent(true);

      console.log("RESPONSE:", jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const checkEmailVerified = async () => {
    // Make an API request to send the verification email.

    try {
      // const headers = {
      //   "Content-Type": "application/json",
      //   Authorization: `Bearer ${accessToken}`,
      // };
      // const response = await fetch(urlVerifyAfterMail, {
      //   method: "GET",
      //   headers: headers,
      // });

      const response  = await axios.get(urlVerifyAfterMail, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      

      console.log("RESPONSE:__", response?.data);
      setAffData(response?.data);


      if(response.status ==200){
        if(response?.data?.verified){setVerified(true)}

        setMainProfile(response?.data?.profile_pic);



      }
      



      // if (!response.ok) {
      //   throw new Error(`HTTP error! Status: ${response.status}`);
      // }

      const jsonData = await response.json();

      setCheckEmailVerifiedStatus(jsonData?.verified);
      setAffData(jsonData);
      // console.log("RESPONSE:", jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchData = async () => {
  //   try {
  //     // Replace with your actual access token
  //     const headers = {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //     };

  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: headers,
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     const jsonData = await response.json();
  //     setData(jsonData);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const fetchCampaginDetails = async (id) => {
    if (!id) return;
    try {
      const campageinId = id;
      const url = `${URL}/campaign/${campageinId}`;
      const Camdata = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log("this is campagin data -->", Camdata.data);
      setCampaignData(Camdata.data);
      setCampaginName(Camdata.data.name);
    } catch (error) {
      console.error("Error fetching CampaginData data:", error);
      // toast.error("Error fetching CampaginData");
    }
  };
  const fetchAffiliateData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(url1, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();

      setAffiliateData(jsonData);

      await fetchCampaginDetails(jsonData?.iframe_campaign_id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchWalletData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await fetch(urlWallet, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();

      setWalletData(jsonData[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchImage = async () => {
    try {
      if (!image) {
        toast.error("No image selected for upload.");
        return;
      }
  
      const formData = new FormData();
      const blob = await fetch(image).then((res) => res.blob()); 
      formData.append("file", blob, "image.jpg"); 
  
      const url_image = `${URL2}/api/affiliates/update_profile_image`;
  
      const response = await axios.post(url_image, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        toast.success("Image uploaded successfully.");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error("Error uploading image. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image. Please try again.");
    }
  };

  async function init() {
    try {
      setLoading(true);
      // await fetchData();
      await fetchAffiliateData();
      await fetchWalletData();
      // await fetchImage();
      await checkEmailVerified();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    init();
  }, []);

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0]; 
    if (selectedImage) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        setImage(event.target.result);
      };
  
      reader.readAsDataURL(selectedImage); 
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      // Calculate the total count when data changes
      const calculatedTotalCount = data.reduce(
        (acc, item) => acc + (item.count || 0),
        0
      );
      setTotalCount(calculatedTotalCount);
    }
  }, [data]);

  const handleSubmit = async (event) => {
    await fetchImage();
    event.preventDefault();

    const data = {
      userId: user1?._id,
      affiliate,
      nameBeni,
      last,
      username,
      companyName,
      phoneNumber,
      address2,
      address1,
      age,
      skype,
      postCode,
      state,
      city,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}/api/v1/userDetail/userDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        toast.success("Form data submitted successfully!");
      } else {
        toast.error("Error submitting form data. Please try again.");
      }
    } catch (error) {
      toast.error("Error submitting form data. Please try again.");
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  const OverviewForm = () => {
    const [editable, setEditable] = useState(false);
    const [
      editAffiliateAdditionalInfoData,
      seteditAffiliateAdditionalInfoData,
    ] = useState({
      affiliate_id: "string",
      verticals: [],
      traffic_sources: [],
      traffic_source_country: "",
      company_name: "",
      profile: "",
      experience: "",
      company_address: "",
      contact_number: "",
      email_id: "",
      linkedin: "",
      news_paper: "",
      telegram: "",
      skype_id: "",
      affiliate_country: "",
      website_address: "",
      youtube_channel_link: "",
      pinterest_profile_link: "",
      tiktok_profile_link: "",
      twitter_handle: "",
      linkedin_profile_link: "",
      telegram_channel_link: "",
      snapchat_handle: "",
      reddit_profile_link: "",
    });

    const verticals = [
      "SWEEPS",
      "SEARCH_ARBITRAGE",
      "LEAD_GEN",
      "DATING",
      "APPS",
      "IGAMING",
      "STREAMING",
      "TRAVEL",
      "CARRIER_BILLING",
      "BIZOPP_OFFERS",
      "ECOM_AFFILIATE_OFFERS",
      "ECOM_STORE_OWNER",
      "CONTENT_MARKETING",
      "COD",
      "GAMING",
      "CONTENT_ARBITRAGE",
      "INSURANCE",
      "CRYPTO",
      "FINANCE",
      "HOME_IMPROVEMENT",
      "HEALTH_AND_BEAUTY",
      "NUTRA",
    ];
    const marketingChannels = [
      "SEO",
      "PAID_SEARCH",
      "NATIVE",
      "META_TS_FB_INSTA",
      "YOUTUBE_TS",
      "PINTEREST_TS",
      "TIKTOK_TS",
      "EMAIL_TS",
      "PUSH_TS",
      "DISPLAY_DESK_MOB",
      "TWITTER",
      "LINKEDIN",
      "TELEGRAM",
      "SNAPCHAT",
      "POPS_REDIRECT",
      "SMS",
      "REDDIT",
      "NEWSPAPER",
    ];

    useEffect(() => {
      const getAffiliateAdditionalInfo = async () => {
        try {
          const response = await axios.get(url_get_aff_info, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });
          console.log(response.data);

          const modifiedData = response.data;

          seteditAffiliateAdditionalInfoData(modifiedData);
          console.log("Modified JSON:", modifiedData);
        } catch (error) {
          console.log(error.message);
        }
      };

      getAffiliateAdditionalInfo();
      // init()
    }, [editable]);
    const toggleEditable = () => {
      setEditable(!editable);
    };
    const formOnchangeHandler = (event) => {
      event.preventDefault();
      if (!editable) {
        return; // Do nothing if not editable
      }
      const { name, value, checked } = event.target;

      if (name === "traffic_sources" || name === "verticals") {
        const propArray =
          name === "traffic_sources" ? "traffic_sources" : "verticals";
        seteditAffiliateAdditionalInfoData((prevCopy) => ({
          ...prevCopy,
          [propArray]: checked
            ? [...prevCopy[propArray], value]
            : prevCopy[propArray].filter((el) => el !== value),
        }));
      } else {
        seteditAffiliateAdditionalInfoData((prevCopy) => ({
          ...prevCopy,
          [name]: value,
        }));
      }
    };

    const handleFormSubmit = async (event) => {
      event.preventDefault();
      if (!editable) {
        return;
      }
      try {
        const affiliateId = affData.affiliate_id;
        const updatedEditAffiliateData = {
          ...editAffiliateAdditionalInfoData,
          affiliate_id: "4b5562fb788d4cdf99b0dcd5d461a8b2",
        };

        const response = await axios.post(
          url_get_aff_info,
          updatedEditAffiliateData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        seteditAffiliateAdditionalInfoData(response?.data);
        toast.success("Profile saved successfully", 2000);
        setEditable(false);
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    };
    const copyToClipboard = (textcopy) => {
      if (textcopy !== "") {
        navigator.clipboard
          .writeText(textcopy)
          .then(() => {
            toast.success("successfully copied", 2000);
          })
          .catch((err) => {
            console.error("Error copying text to clipboard:", err);
          });
      }
    };
    return (
      <>
        <Box
          style={{ maxWidth: "1200px", backgroundColor: "#EDF2F7" }}
          className={classes.boxstyleForm}
        >
          <form onSubmit={handleFormSubmit}>
            <Grid container spacing={3} mt={1}>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="account">Affiliate ID:</FormLabel>
                  <TextField
                    type="text"
                    id="affiliate"
                    min={0}
                    value={affData.affiliate_id}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                {" "}
                <FormControl>
                  <FormLabel htmlFor="name"> Name:</FormLabel>
                  <TextField
                    type="text"
                    id="name"
                    placeholder="Enter Name"
                    name="name"
                    min={0}
                    value={affData.name}
                    InputProps={{ readOnly: true }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="profile">Profile : </FormLabel>
                  <TextField
                    type="text"
                    id="profile"
                    placeholder="Enter Profile "
                    name="profile"
                    value={editAffiliateAdditionalInfoData.profile}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="experience">Experinece :</FormLabel>
                  <TextField
                    type="text"
                    id="experience"
                    placeholder="Enter experience "
                    name="experience"
                    value={editAffiliateAdditionalInfoData.experience}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3} mt={1}>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="name">Company Name:</FormLabel>
                  <TextField
                    type="text"
                    id="companyname"
                    placeholder="Company Name"
                    name="company_name"
                    value={editAffiliateAdditionalInfoData.company_name}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                {" "}
                <FormControl>
                  <FormLabel htmlFor="company_address">
                    Company Address{" "}
                  </FormLabel>
                  <TextField
                    name="company_address"
                    type="text"
                    id="company_address"
                    placeholder="Enter company address"
                    value={editAffiliateAdditionalInfoData.company_address}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="traffic_source_country">
                    Traffic Source Country
                  </FormLabel>
                  <TextField
                    type="text"
                    id="traffic_source_country"
                    placeholder="Enter traffic source country  "
                    name="traffic_source_country"
                    value={
                      editAffiliateAdditionalInfoData.traffic_source_country
                    }
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="affiliate_country">
                    Affiliate Country :
                  </FormLabel>
                  <TextField
                    type="text"
                    id="affiliate_country"
                    placeholder="Enter affiliate country"
                    name="affiliate_country"
                    value={editAffiliateAdditionalInfoData.affiliate_country}
                    onChange={formOnchangeHandler}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                <FormControl>
                  <FormLabel htmlFor="bio">Bio:</FormLabel>
                  <TextField
                    type="text"
                    id="bio"
                    placeholder="Enter bio"
                    name="profile"
                    value={affData.bio}
                    InputProps={{ readOnly: !editable }}
                    sx={{
                      bgcolor: editable ? "none" : "#e6e6e6",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                display={"flex"}
                justifyContent={"center"}
              >
                {verified ? (
                  <Button
                    variant="contained"
                    color="success"
                    className={classes.button + "  send-email-btn "}
                    sx={{
                      padding: "10px 0",
                      width: "218px",
                      marginTop: "25px",
                    }}
                  >
                    You are Varified now !
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendVerificationEmail}
                    className={classes.button + "  send-email-btn "}
                    sx={{
                      padding: "10px 0",
                      width: "218px",
                      marginTop: "25px",
                    }}
                  >
                    Send Verification Email
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid container mt={1}>
              <Grid
                item
                md={6}
                xs={12}
                height="auto"
                className="d-flex justify-content-center  gap-3 flex-column p-2"
              >
                <Box
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Box className="verticals-check-box-container p-2">
                    <div className="form-group">
                      <label>Verticals</label>
                      <Box sx={{ height: "230px", overflowY: "auto" }}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          spacing={0}
                        >
                          {verticals.map((vertical, index) => (
                            <Grid key={index} item xs={12} md={6}>
                              <Box className="">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      name={"verticals"}
                                      onChange={formOnchangeHandler}
                                      checked={
                                        editAffiliateAdditionalInfoData.verticals.indexOf(
                                          vertical
                                        ) !== -1
                                      }
                                      value={vertical}
                                    />
                                  }
                                  label={vertical}
                                  sx={{
                                    display: "block",
                                    margin: "0 auto",
                                    bgcolor: editable ? "none" : "#e6e6e6",
                                  }}
                                />
                              </Box>
                            </Grid>
                          ))}
                          <Grid item xs={12} md={12}>
                            <Box className="">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    name={"verticals"}
                                    value={"PAYPER_CALL"}
                                    onChange={formOnchangeHandler}
                                    checked={
                                      editAffiliateAdditionalInfoData.verticals.indexOf(
                                        "PAYPER_CALL"
                                      ) !== -1
                                    }
                                    disabled={!editable}
                                  />
                                }
                                label={"PAYPER_CALL"}
                                sx={{
                                  display: "block",
                                  margin: "0 auto",
                                  bgcolor: editable ? "none" : "#e6e6e6",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  </Box>
                </Box>
                <Box
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                    [theme.breakpoints.up("md")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Box className="verticals-check-box-container p-2">
                    <div className="form-group">
                      <label>Traffic Source</label>
                      <Box sx={{ height: "230px", overflowY: "auto" }}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          spacing={0}
                        >
                          {marketingChannels.map((marketingChannels, index) => (
                            <Grid key={index} item xs={12} md={6}>
                              <Box className="">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      name={"traffic_sources"}
                                      onChange={formOnchangeHandler}
                                      value={marketingChannels}
                                      checked={
                                        editAffiliateAdditionalInfoData.traffic_sources.indexOf(
                                          marketingChannels
                                        ) !== -1
                                      }
                                    />
                                  }
                                  label={marketingChannels}
                                  sx={{
                                    display: "block",
                                    margin: "0 auto",
                                    bgcolor: editable ? "none" : "#e6e6e6",
                                  }}
                                />
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                height="auto"
                className="d-flex justify-content-center  gap-3 flex-column p-2"
              >
                <Box
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Box className=" p-2">
                    <Typography fontWeight={"bold"}>Contact :</Typography>
                    <Box
                      sx={{ height: "230px", overflowY: "auto" }}
                      className="px-3"
                    >
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PhoneIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              bgcolor: editable ? "none" : "#e6e6e6",
                            }}
                            placeholder="Enter phone number"
                            name="contact_number"
                            value={
                              editAffiliateAdditionalInfoData.contact_number
                            }
                            onChange={formOnchangeHandler}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.contact_number
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              target="_blank"
                              href={`tel:${editAffiliateAdditionalInfoData.contact_number}`}
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter email address"
                            name="email_id"
                            value={editAffiliateAdditionalInfoData.email_id}
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.email_id
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={`mailto:${editAffiliateAdditionalInfoData.email_id}`}
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <TelegramIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter telegram username"
                            name="telegram"
                            value={editAffiliateAdditionalInfoData.telegram}
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.telegram
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              target="_blank"
                              href={`https://t.me/${editAffiliateAdditionalInfoData.telegram}`}
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <NewspaperIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter your newspaper"
                            name="news_paper"
                            value={editAffiliateAdditionalInfoData.news_paper}
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.news_paper
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={editAffiliateAdditionalInfoData.news_paper}
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      height: "280px",
                      border: "1px solid gray",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <Box className=" p-2">
                    <Typography fontWeight={"bold"}>Social Links :</Typography>
                    <Box
                      sx={{ height: "230px", overflowY: "auto" }}
                      className="px-3"
                    >
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={skypeSvg}
                                    alt=""
                                    style={{
                                      width: "auto",
                                      height: "26px",
                                      color: "black",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter your skype id"
                            name="skype_id"
                            value={editAffiliateAdditionalInfoData.skype_id}
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.skype_id
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={editAffiliateAdditionalInfoData.skype_id}
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LanguageIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter your website url"
                            name="website_address"
                            value={
                              editAffiliateAdditionalInfoData.website_address
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.website_address
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.website_address
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <TelegramIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter tel channel url"
                            name="telegram_channel_link"
                            value={
                              editAffiliateAdditionalInfoData.telegram_channel_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.telegram_channel_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.telegram_channel_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <YouTubeIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter youtube channel link"
                            name="youtube_channel_link"
                            value={
                              editAffiliateAdditionalInfoData.youtube_channel_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.youtube_channel_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.youtube_channel_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3 "
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LinkedInIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter linkedin profile url"
                            name="linkedin_profile_link"
                            value={
                              editAffiliateAdditionalInfoData.linkedin_profile_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.linkedin_profile_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.linkedin_profile_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PinterestIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter pinterest profile link"
                            name="pinterest_profile_link"
                            value={
                              editAffiliateAdditionalInfoData.pinterest_profile_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.pinterest_profile_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.pinterest_profile_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <TwitterIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter  twitter profile link"
                            name="twitter_handle"
                            value={
                              editAffiliateAdditionalInfoData.twitter_handle
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.twitter_handle
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.twitter_handle
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={TickTockIcon}
                                    alt=""
                                    style={{
                                      width: "auto",
                                      height: "26px",
                                      color: "black",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter tiktok profile link"
                            name="tiktok_profile_link"
                            value={
                              editAffiliateAdditionalInfoData.tiktok_profile_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.tiktok_profile_link
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.tiktok_profile_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={SnapChatIcon}
                                    alt=""
                                    style={{
                                      width: "auto",
                                      height: "26px",
                                      color: "black",
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter snapchat profile link"
                            name="snapchat_handle"
                            value={
                              editAffiliateAdditionalInfoData.snapchat_handle
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon
                              sx={{ fontSize: "20px" }}
                              onClick={() =>
                                copyToClipboard(
                                  editAffiliateAdditionalInfoData.snapchat_handle
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.snapchat_handle
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center gap-3"
                        sx={{
                          borderBottom: "1px solid #cccccc",
                          padding: "7px 0",
                        }}
                      >
                        <Box>
                          <TextField
                            InputProps={{
                              readOnly: !editable,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <RedditIcon
                                    sx={{ fontSize: 24, color: "black" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter reddit profile link"
                            name="reddit_profile_link"
                            value={
                              editAffiliateAdditionalInfoData.reddit_profile_link
                            }
                            onChange={formOnchangeHandler}
                            sx={{ bgcolor: editable ? "none" : "#e6e6e6" }}
                          />
                        </Box>

                        <Box className="d-flex justify-content-between align-content-center gap-3">
                          <Box>
                            <ContentPasteIcon sx={{ fontSize: "20px" }} />
                          </Box>
                          <Box>
                            <a
                              href={
                                editAffiliateAdditionalInfoData.reddit_profile_link
                              }
                              target="_blank"
                            >
                              <IosShareIcon sx={{ fontSize: "20px" }} />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ backgroundColor: "#f0f0f0", padding: "16px" }}>
              <Grid container>
                <Grid item md={6} xs={6}></Grid>
                <Grid item md={6} xs={6}>
                  <Box className="d-flex justify-content-end gap-3">
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        width: "25%",
                      }}
                      onClick={toggleEditable}
                    >
                      {!editable ? "Edit" : "Cancle"}
                    </Button>
                    {editable ? (
                      <Button
                        variant="contained"
                        sx={{
                          color: "#fff",
                          width: "25%",
                        }}
                        type="submit"
                      >
                        Save
                      </Button>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box>
        <Typography className={classes.boxstyle}> User Details  </Typography>
      </Box>

      <Grid style={{ padding: "300", minChildWidth: "300", spacing: "5" }}>
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: "1200px",
            backgroundColor: "#EDF2F7",
            flexWrap: "wrap",
            overflowX: "auto",
            overflowY: "auto",
            padding: " 20px 15px",
          }}
          className={classes.boxstyleForm1}
        >
          <Grid container>
            <Grid item xs={6} md={4} sx={{ borderRight: "1px solid #ccc" }}>
              <Box sx={{ padding: "10px" }}>
                <Image
                  src={mainProfile}
                  rounded
                  style={{
                    height: "100px",
                    display: "block",
                    margin: "0 auto",
                  }}
                  className="profile-image"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "column",
                    alignItems: "center ",
                    gap: "10px",
                  }}
                  mt={"10px"}
                >
                  <Box sx={{ padding: "0 120px" }}>
                    {" "}
                    <Input
                      type="file"
                      onChange={handleImageChange}
                      sx={{ width: "200px" }}
                      className="choose-file"
                    />
                  </Box>
                  <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    onClick={fetchImage}
                    sx={{ width: "200px" }}
                    className="upload-profile-btn"
                  >
                    {" "}
                    Upload
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid #ccc",
              }}
              className="basic-info-main-container"
            >
              <Box sx={{ bgcolor: "" }} className="basic-info-container">
                <Box>
                  <Typography
                    fontWeight={400}
                    sx={{ display: "flex", justifyContent: "start" }}
                  >
                    <AccountCircleIcon className="info-icon" />{" "}
                    <p> {user1?.data?.name} Active</p>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    fontWeight={400}
                    sx={{ display: "flex", justifyContent: "start" }}
                  >
                    {verified ? (
                      <VerifiedUserIcon
                        className="info-icon"
                        style={{ color: "green" }}
                      />
                    ) : (
                      <UnpublishedIcon
                        className="info-icon"
                        style={{ color: "red" }}
                      />
                    )}{" "}
                    <p> {user1.data.email}</p>
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    fontWeight={400}
                    sx={{ display: "flex", justifyContent: "start" }}
                  >
                    <AddLocationIcon /> <p> India</p>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid container spacing={2} px={"12px"}>
                <Grid item md={12}>
                  <Box className="btn-container" sx={{ padding: "10px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={12}>
                        <Button
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            width: "100%",
                            padding: "8px 3px",
                            margin: "0 auto",
                          }}
                          className="member-join-btn"
                        >{`MEMBER ${date}`}</Button>
                      </Grid>
                      <Grid item xs={6} md={12}>
                        <Button
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            width: "100%",
                            padding: "25px 3px",
                            margin: "0 auto",
                          }}
                          className="reset-pass-btn"
                        >
                          {" "}
                          RESET PASSWORD{" "}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item md={12}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2, px: 3 }}>
            <Grid item xs={6} md={2} display="flex" justifyContent="center">
              <Box className={classes.innerbox}>
                <Box>
                  {walletData?.total_earnings ? walletData?.total_earnings : 0}
                </Box>
                <Box>
                  <Typography style={{ color: "#4CAF50", fontWeight: "bold" }}>
                    Earnings(INR)
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={2} display="flex" justifyContent="center">
              <Box className={classes.innerbox}>
                <Box>1</Box>
                <Box>
                  <Typography style={{ color: "#1976D2", fontWeight: "bold" }}>
                    Offers
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={2} display="flex" justifyContent="center">
              <Box className={classes.innerbox}>
                <Box>{totalCount}</Box>
                <Box>
                  <Typography style={{ color: "#FFA000", fontWeight: "bold" }}>
                    Clicks
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={2} display="flex" justifyContent="center">
              <Box className={classes.innerbox}>
                <Box>{affiliateData.level}</Box>
                <Box>
                  <Typography style={{ color: "#FFA000", fontWeight: "bold" }}>
                    Level
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="center">
              <Box className={classes.dabox}>
                <Box>{campaginName ? campaginName : "No Campaign Linked"}</Box>
                <Box>
                  <Typography style={{ color: "#FFA000", fontWeight: "bold" }}>
                    DA
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            style={{
              display: "flex",

              justifyContent: "space-evenly",
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            <Typography
              className={`${classes.textinBox} ${getTabClass("overview")}`}
              onClick={() => handleTabClick("overview")}
            >
              Overview
            </Typography>

            <Typography
              className={`${classes.textinBox} ${getTabClass("managers")}`}
              onClick={() => handleTabClick("managers")}
            >
              Managers
            </Typography>

            <Typography
              className={`${classes.textinBox} ${getTabClass("campaign")}`}
              onClick={() => handleTabClick("campaign")}
            >
              Campaigns
            </Typography>
            <Typography
              className={classes.textinBox}
              onClick={() => handleTabClick("postback")}
            >
              PostBacks
            </Typography>
            <Typography className={classes.textinBox}>Payouts</Typography>
            <Typography className={classes.textinBox}>Comapany</Typography>
            <Typography className={classes.textinBox}>Billing</Typography>
          </Box>
        </Box>
      </Grid>

      {activeTab === "overview" && <OverviewForm />}

      {activeTab === "managers" && <ManagersTable />}
      {activeTab === "campaign" && <Campaign />}
      {/* {activeTab === "postback" && (
        // <Box
        //   sx={{
        //     [theme.breakpoints.up("xs")]: {
        //       // height: "500px",
        //       backgroundColor: "#EDF2F7",
        //       padding: "10px",
        //       marginTop: "5px",
        //     },
        //     [theme.breakpoints.up("md")]: {},
        //   }}
        // >
        //   <Grid container spacing={2}>
        //     <Grid item xs={12} md={4}>
        //       <Paper
        //         sx={{
        //           p: 2,
        //           height: "200px",
        //           boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.2)",
        //         }}
        //       >
        //         <Box>
        //           <Box fontWeight="bold">Bio</Box>
        //           <Box>Web Developer</Box>
        //         </Box>
        //       </Paper>
        //     </Grid>
        //     <Grid item xs={12} md={4}>
        //       <Paper
        //         sx={{
        //           p: 2,
        //           height: "200px",
        //           boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.2)",
        //         }}
        //       >
        //         <Box fontWeight="bold">Vertical</Box>

        //         {affiliateAdditionalInfo.verticals?.map((item, i) => {
        //           return (
        //             <Box
        //               key={i}
        //               className="d-inline-block"
        //               sx={{ marginLeft: "5px", marginTop: "-3px" }}
        //               style={{
        //                 overflow: "hidden",
        //                 whiteSpace: "nowrap",
        //                 textOverflow: "ellipsis",
        //               }}
        //             >
        //               <PostBackMiniCard getInfoData={item} />
        //             </Box>
        //           );
        //         })}
        //       </Paper>
        //     </Grid>
        //     <Grid item xs={12} md={4}>
        //       <Paper
        //         sx={{
        //           p: 2,
        //           height: "200px",
        //           boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.2)",
        //         }}
        //       >
        //         <Box fontWeight="bold">Traffic Sources</Box>
        //         {affiliateAdditionalInfo.traffic_sources?.map((item, i) => {
        //           return (
        //             <Box
        //               key={i}
        //               className="d-inline-block"
        //               sx={{ marginLeft: "5px", marginTop: "-3px" }}
        //               style={{
        //                 overflow: "hidden",
        //                 whiteSpace: "nowrap",
        //                 textOverflow: "ellipsis",
        //               }}
        //             >
        //               <PostBackMiniCard getInfoData={item} />
        //             </Box>
        //           );
        //         })}
        //       </Paper>
        //     </Grid>
        //     <Grid item xs={12} md={4}>
        //       <Paper
        //         sx={{
        //           p: 2,
        //           height: "291px",
        //           boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.2)",
        //         }}
        //       >
        //         <Box fontWeight="bold">Looking To Meet</Box>
        //         <PostBackMiniCard getInfoData={"Direct Adverties"} />
        //       </Paper>
        //     </Grid>
        //     <Grid item xs={12} md={8}>
        //       <Paper
        //         sx={{
        //           p: 2,
        //           height: "291px",
        //           boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.2)",
        //         }}
        //       >
        //         <Box
        //           fontWeight="bold"
        //           className="d-flex justify-content-between  mb-2"
        //         >
        //           <Box>Socials</Box>
        //           <Box>
        //             <EditIcon
        //               onClick={handleEditAffiliateAdditionalInfoClick}
        //             />
        //           </Box>
        //         </Box>
        //         <Box
        //           className="d-flex justify-content-between align-items-center "
        //           sx={{ borderBottom: "1px solid #cccccc", padding: "7px 0" }}
        //         >
        //           <Box>
        //             <Typography variant="body2">
        //               <PhoneIcon />
        //               <Typography
        //                 sx={{ marginLeft: "15px" }}
        //                 component={"span"}
        //                 variant="body2"
        //               >
        //                 {affiliateAdditionalInfo.contact_number}
        //               </Typography>
        //             </Typography>
        //           </Box>

        //           <Box className="d-flex justify-content-between align-content-center gap-4">
        //             <Box>
        //               <ContentPasteIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //             <Box>
        //               <IosShareIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //           </Box>
        //         </Box>
        //         <Box
        //           className="d-flex justify-content-between align-items-center "
        //           sx={{ borderBottom: "1px solid #cccccc", padding: "7px 0" }}
        //         >
        //           <Box>
        //             <Typography variant="body2">
        //               <EmailIcon />
        //               <Typography
        //                 sx={{ marginLeft: "15px" }}
        //                 component={"span"}
        //                 variant="body2"
        //               >
        //                 {affiliateAdditionalInfo.email_id}
        //               </Typography>
        //             </Typography>
        //           </Box>

        //           <Box className="d-flex justify-content-between align-content-center gap-4">
        //             <Box>
        //               <ContentPasteIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //             <Box>
        //               <IosShareIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //           </Box>
        //         </Box>
        //         <Box
        //           className="d-flex justify-content-between align-items-center "
        //           sx={{ borderBottom: "1px solid #cccccc", padding: "7px 0" }}
        //         >
        //           <Box>
        //             <Typography variant="body2">
        //               <LinkedInIcon />
        //               <Typography
        //                 sx={{ marginLeft: "15px" }}
        //                 component={"span"}
        //                 variant="body2"
        //               >
        //                 {affiliateAdditionalInfo.linkedin}
        //               </Typography>
        //             </Typography>
        //           </Box>

        //           <Box className="d-flex justify-content-between align-content-center gap-4">
        //             <Box>
        //               <ContentPasteIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //             <Box>
        //               <IosShareIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //           </Box>
        //         </Box>
        //         <Box
        //           className="d-flex justify-content-between align-items-center "
        //           sx={{ borderBottom: "1px solid #cccccc", padding: "7px 0" }}
        //         >
        //           <Box>
        //             <Typography variant="body2">
        //               <NewspaperIcon />
        //               <Typography
        //                 sx={{ marginLeft: "15px" }}
        //                 component={"span"}
        //                 variant="body2"
        //               >
        //                 {affiliateAdditionalInfo.news_paper}
        //               </Typography>
        //             </Typography>
        //           </Box>

        //           <Box className="d-flex justify-content-between align-content-center gap-4">
        //             <Box>
        //               <ContentPasteIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //             <Box>
        //               <IosShareIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //           </Box>
        //         </Box>
        //         <Box
        //           className="d-flex justify-content-between align-items-center "
        //           sx={{ borderBottom: "1px solid #cccccc", padding: "7px 0" }}
        //         >
        //           <Box>
        //             <Typography variant="body2">
        //               <TelegramIcon />
        //               <Typography
        //                 sx={{ marginLeft: "15px" }}
        //                 component={"span"}
        //                 variant="body2"
        //               >
        //                 {affiliateAdditionalInfo.telegram}
        //               </Typography>
        //             </Typography>
        //           </Box>

        //           <Box className="d-flex justify-content-between align-content-center gap-4">
        //             <Box>
        //               <ContentPasteIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //             <Box>
        //               <IosShareIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //           </Box>
        //         </Box>
        //         <Box
        //           className="d-flex justify-content-between align-items-center "
        //           sx={{ borderBottom: "1px solid #cccccc", padding: "7px 0" }}
        //         >
        //           <Box>
        //             <Typography variant="body2">
        //               <img
        //                 src={skypeSvg}
        //                 alt=""
        //                 height={" 20px"}
        //                 className="d-inline-block"
        //               />
        //               <Typography
        //                 sx={{ marginLeft: "15px" }}
        //                 component={"span"}
        //                 variant="body2"
        //               >
        //                 {affiliateAdditionalInfo.skype_id}
        //               </Typography>
        //             </Typography>
        //           </Box>

        //           <Box className="d-flex justify-content-between align-content-center gap-4">
        //             <Box>
        //               <ContentPasteIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //             <Box>
        //               <IosShareIcon sx={{ fontSize: "20px" }} />
        //             </Box>
        //           </Box>
        //         </Box>
        //       </Paper>
        //     </Grid>
        //   </Grid>
        // </Box>
      )} */}

      <ToastContainer />
    </>
  );
}

export default UserDetails;
