import axios from "axios";

const localStorageGet = (key) => {
  try {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  } catch (error) {
    console.error(`Error parsing data from local storage for key ${key}:`, error);
    throw error;
  }
};

const localStorageSet = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const localStorageRemove = (key) => {
  localStorage.removeItem(key);
};

export const afterLoginStorage = async (onSuccess = () => {}) => {
    const URL = process.env.REACT_APP_PROD_API;
    try {
      const userData = localStorageGet("user");
      if (userData) {
        const url = `${URL}/api/affiliates`;
        const accessToken = userData.data.access_token;
        const res = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
        });
        localStorageSet("res", res);
        onSuccess(res);
        return res.data;
      } else {
        console.log("User data not found in local storage.");
        throw new Error("User data not found in local storage.");
      }
    } catch (error) {
      console.error("Error during API request:", error);
      throw error;
    }
};

export const addUserToLocalStorage = (user) => {
  localStorageSet("user", user);
};

export const removeUserFromLocalStorage = () => {
  localStorageRemove("user");
  localStorageRemove("res");
};

export const getUserFromLocalStorage = () => {
  return localStorageGet("user");
};

export const getResFromLocalStorage = () => {
  return localStorageGet("res");
};
