import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Grid,
  Paper,
  Alert,
  Avatar,
  Tooltip,
  IconButton,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import axios from "axios";
import { toast } from "react-toastify";
import { getUserFromLocalStorage } from "src/service/localStorage";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import DeleteIcon from "@mui/icons-material/Delete";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: "80%" },
  background: "linear-gradient(180deg, #A5BFD9 9.55%, #A7D3FF 100%)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,

  borderRadius: 2,
};
const style2 = {
  height: { xs: 500, md: 400 },
  overflowX: "auto",
};
const imgUploadCardStyle = {
  height: { md: 180, xs: 80 },
  width: "100%",
  borderRadius: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

function ChooseImageForNotificationModal({
  open,
  handleClose,
  onSelectImage,
  onSelectExternalImageUrl,
}) {
  const notificationImageUploadRef = useRef(null);
  const [uploadStatus, setUploadStatus] = useState({
    loading: false,
    error: null,
  });
  const [progress, setProgress] = React.useState(10);
  const [pnsImages, setPnsImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onHoveredIndex, setOnHoveredIndex] = useState(-1);
  const skeletons = new Array(12).fill(0);
  const [imgUrl, setImgUrl] = useState("");

  const HandleButtonForGetImageUrl = (url) => {
    onSelectImage(url);
  };

  const handleButtonForGetImgExternalUrl = () => {
    return onSelectExternalImageUrl(imgUrl);
  };

  const handleMouseEnter = (index) => {
    setOnHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setOnHoveredIndex(-1);
  };

  const getPnsImages = async () => {
    const URL = process.env.REACT_APP_PROD_API;
    setLoading(true);
    try {
      const {
        data: { access_token },
      } = getUserFromLocalStorage();

      if (!access_token) {
        console.error("No access token available.");
        return;
      }

      const response = await axios.get(`${URL}/api/pns/images`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response.data) {
        setPnsImages(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch images:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPnsImages();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleButtonClick = () => {
    notificationImageUploadRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("image_file", file);

    try {
      setUploadStatus({ loading: true, error: null });
      const URL = process.env.REACT_APP_PROD_API;
      const {
        data: { access_token },
      } = getUserFromLocalStorage();

      const response = await axios.post(`${URL}/api/pns/upload`, formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response) {
        toast.success("Image Upload successful!");
        setUploadStatus({ loading: false, error: null });
        event.target.value = null;
        await getPnsImages();
      }
    } catch (error) {
      toast.error("Error uploading file.");
      setUploadStatus({
        loading: false,
        error: error.response?.data?.detail || "Failed to upload image",
      });
      event.target.value = null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box sx={style2}>
          <Typography id="modal-title" variant="h5" mt={1} color="#002C56">
            Choose Image for Notification
          </Typography>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item xs={6} md={3}>
              <Paper sx={imgUploadCardStyle} onClick={handleButtonClick}>
                {uploadStatus.loading ? (
                  <CircularProgressWithLabel value={progress} />
                ) : (
                  <AddPhotoAlternateIcon
                    sx={{
                      fontSize: { md: 80, xs: 50 },
                      color: "rgb(102,131,177)",
                    }}
                  />
                )}
              </Paper>
              <input
                type="file"
                ref={notificationImageUploadRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {uploadStatus.error && (
                <Alert severity="error" sx={{ mt: 1 }}>
                  {uploadStatus.error}
                </Alert>
              )}
            </Grid>
            {/* Image Display or Loading Indicator */}
            {loading ? (
              <>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>{" "}
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>{" "}
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>{" "}
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>{" "}
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>{" "}
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>{" "}
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    sx={{
                      ...imgUploadCardStyle,
                      bgcolor: "grey.700",
                      borderRadius: 2,
                      // height: 180,
                    }}
                    variant="rectangular"
                  />
                </Grid>
              </>
            ) : (
              pnsImages.map((image, index) => (
                <Grid
                  item
                  xs={6}
                  md={3}
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  sx={{ position: "relative" }}
                >
                  <Avatar
                    onClick={() => HandleButtonForGetImageUrl(image.image_url)}
                    src={image.image_url}
                    sx={{
                      width: 150,
                      height: 150,
                      borderRadius: 0,
                      display: "block",
                      mx: "auto",
                      "&:hover": {
                        cursor: "pointer",
                      },
                      ...imgUploadCardStyle,
                    }}
                  />
                </Grid>
              ))
            )}{" "}
          </Grid>
        </Box>
        <Divider sx={{ width: "100%", bgcolor: "white", my: 3 }}>OR </Divider>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            id="outlined-basic"
            label="Use Image URL "
            variant="outlined"
            sx={{ width: 400, bgcolor: "white", borderRadius: 2 }}
            onChange={(e) => setImgUrl(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mt: 2,
          }}
        >
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ bgcolor: "#384D6C" }}
            onClick={handleButtonForGetImgExternalUrl}
          >
            Import
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ChooseImageForNotificationModal;
