import React, { useState } from 'react';
import { IconButton, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TemplateComponent from 'src/components/Template/TemplateComponent';

function Template() {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [iconUrl, setIconUrl] = useState('');
    const [url, setUrl] = useState('');
    const [items, setItems] = useState([]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreateTemplate = () => {
        // Validate form fields
        if (title && body && iconUrl && url) {
            // Create new template item
            const newItem = {
                id: items.length + 1,
                title,
                body,
                iconUrl,
                url
            };
            // Add new item to items state
            setItems([...items, newItem]);
            // Close the popup box
            setOpen(false);
            // Clear form fields
            setTitle('');
            setBody('');
            setIconUrl('');
            setUrl('');
        } else {
            alert('Please fill in all required fields.');
        }
    };

    return (
        <div style={{ background: 'linear-gradient( #A5BFD9, #A7D3FF)', padding: "50px 25px" }}>
            <h1 style={{ fontSize: "40px", lineHeight: "24px", fontWeight: "400" }}>Template</h1>
            <IconButton
                sx={{
                    background: "#445490",
                    color: "white",
                    borderRadius: "15px",
                    height: "50px",
                    top: "50px",
                    fontStyle: "public sans",
                    fontWeight: "800",
                    fontSize: "20px",
                    lineHeight: "24px",
                    padding: "5px 30px"
                }}
                onClick={handleOpen}
            >
                CREATE TEMPLATE
            </IconButton>

            {/* Popup box for creating a template */}
            <Dialog open={open} onClose={handleClose}>
            <div style={{  background: 'linear-gradient( #B7D7F6, #B6D7FF)', padding: "50px 25px", padding: '20px' }}>
        <DialogTitle><h3>Create Template</h3></DialogTitle>
        <DialogContent>
            <label>Title</label>
            <TextField
                autoFocus
                margin="dense"
                placeholder="Enter Title"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                inputProps={{
                    style: { backgroundColor: 'white', color: 'black', borderRadius: '5px', padding: '10px', border:"none" }
                }}
            />

<label>Body</label>
            <TextField
                margin="dense"
                placeholder="Enter Body"
                fullWidth
                value={body}
                onChange={(e) => setBody(e.target.value)}
                inputProps={{
                    style: { backgroundColor: 'white', color: 'black', borderRadius: '5px', padding: '10px', border:"none" }
                }}
            />
            <TextField
                margin="dense"
                label="Icon URL"
                fullWidth
                value={iconUrl}
                onChange={(e) => setIconUrl(e.target.value)}
            />
            <TextField
                margin="dense"
                label="URL"
                fullWidth
                value={url}
                onChange={(e) => setUrl(e.target.value)}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleCreateTemplate}>Submit</Button>
        </DialogActions>
    </div>
</Dialog>

            <div style={{ display: "flex", marginTop: "100px", justifyContent: "start",fontStyle:"public sans" }}>
                <span style={{ fontSize: "20px", width: "500px", textAlign: "center" }}>Image</span>
                <span style={{ fontSize: "20px", width: "600px", textAlign: "center" }}>Template</span>
                <span style={{ fontSize: "20px", width: "600px", marginLeft: "30px", textAlign: "center" }}>Click on Template</span>
                <span style={{ fontSize: "20px", width: "600px", textAlign: "center", marginLeft: "30px", }}>Total Push Sent</span>
            </div><hr style={{ border: "2px solid #FFFFFF" }} />

        
            <div style={{ display: "flex", justifyContent: "start" , textAlign:"center", fontStyle:"public sans" }} >
                <span style={{ width: "500px", textAlign: "center" , }}><div style={{height:"40px", width:"40px", borderRadius:"50%", backgroundColor:"#D9D9D9", display:"block", marginLeft:"auto", marginRight:"auto"}}></div></span>
                <span style={{ fontSize: "18px", width: "600px", textAlign: "center", marginLeft: "30px", }}>Limited time offer!</span>
                <span style={{ fontSize: "18px", width: "600px", textAlign: "center", marginLeft: "30px" }}>50</span>
                <span style={{ fontSize: "18px", width: "600px", textAlign: "center", marginLeft: "30px" }}>200</span>
            </div><hr style={{ border: "2px solid #FFFFFF" }} />


           <div style={{marginTop:"80px"}}> {items.map((item) => (
                <TemplateComponent key={item.id} item={item} />
            ))}</div>
        </div>
    );
}

export default Template;
