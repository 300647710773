import React from "react";
import {
    Card,
    CardContent,
    CardMedia,
    Typography ,
    Box,
    Button,
    Grid,
    Divider,
    Avatar,
    Rating,
    InputLabel,
    TextField,
    
   } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import img1 from "./image12.png";
import avt1 from "./image13.png";
import avt2 from "./image14.png";
import avt3 from "./image15.png";
import avt4 from "./image16.png";
import avt5 from "./image17.png";
import avt6 from "./image18.png";
import avt7 from "./image19.png";
import cd1 from "./image20.png";
import cd2 from "./image21.png";
import cd3 from "./image22.png";
import cd4 from "./image23.png";
import "./Metro.css";

function Metro(){
    return(
        <>
        <Box>
            <div style={{ position: "relative" }}>
            <CardMedia style={{ height: "auto"}}  component="img" image={img1} title="Image" alt="Image"/> 
            <Typography  variant="h2" style={{position: "absolute",textAlign:"center", fontWeight:"bold", color: "rgb(14, 35, 156)",top: 610,left: "40%",transform: "translateX(-50%)",}}>Elevte Your Experience Now!</Typography>
            </div>
        </Box>

        <Box sx={{bgcolor:'#64b5f6',height:1200}}>
            <Typography variant="h2" sx={{paddingTop:5,paddingLeft:3,paddingBottom:3}}>Campaigns</Typography>
            <Box className="textf">
            <Box sx={{backgroundColor:"white",width:250,marginLeft:5}}><TextField id="outlined-basic" label="Target Audience"/><SearchIcon sx={{color:"blue", fontSize:30}}/></Box>
            <Button sx={{marginLeft:5,paddingLeft:5,paddingRight:5, backgroundColor:"rgb(20, 24, 135)"}} variant="contained">Create</Button>
            </Box>

            <Box sx={{padding:5}}>
            <Button sx={{backgroundColor:"rgb(20, 24, 135)"}} variant="contained">Instagram</Button>
            <Button sx={{backgroundColor:"white", color:"rgb(20, 24, 135)", marginLeft:5}} variant="contained">Telegram</Button>
            <Button sx={{backgroundColor:"white", color:"rgb(20, 24, 135)", marginLeft:5}} variant="contained">Other</Button>
            </Box>

            <Box sx={{paddingLeft:5}}>
                <Typography sx={{paddingBottom:4,fontWeight:"bold"}}>Audience</Typography>
                <Box className="audience">
                <Box>
                    <Avatar alt="Tony Shark" src={avt1} sx={{height:70,width:70}} />
                    <Typography sx={{fontSize:12,fontWeight:"bold"}}>Tony Shark</Typography>
                </Box>
                <Box>
                    <Avatar alt="Thor Odinson" src={avt2} sx={{height:70,width:70}} />
                    <Typography sx={{fontSize:12,fontWeight:"bold"}}>Thor Odinson</Typography>
                </Box>
                <Box>
                    <Avatar alt="Steve Rogers" src={avt3} sx={{height:70,width:70}} />
                    <Typography sx={{fontSize:12,fontWeight:"bold"}}>Steve Rogers</Typography>
                </Box>
                <Box>
                    <Avatar alt="Clint Barton" src={avt4} sx={{height:70,width:70}} />
                    <Typography sx={{fontSize:12,fontWeight:"bold"}}>Clint Barton</Typography>
                </Box>
                <Box>
                    <Avatar alt="Dr Banner" src={avt5} sx={{height:70,width:70}} />
                    <Typography sx={{fontSize:12,fontWeight:"bold"}}>Dr Banner</Typography>
                </Box>
                <Box>
                    <Avatar alt="Wanda Maximoff" src={avt6} sx={{height:70,width:70}} />
                    <Typography sx={{fontSize:12,fontWeight:"bold"}}>Wanda Maximoff</Typography>
                </Box>
                <Box>
                    <Avatar alt="Black Widow" src={avt7} sx={{height:70,width:70}} />
                    <Typography sx={{fontSize:12,fontWeight:"bold"}}>Black Widow</Typography>
                </Box>

                
                
            </Box>
            </Box>
                <Divider variant="middle" sx={{py: 0,
  width: '100%',
  maxWidth: 1140,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
  marginTop:3}} />

            <Box sx={{paddingLeft:5,paddingTop:2}}>
                <Typography sx={{paddingBottom:4,fontWeight:"bold"}}>Campaigns</Typography>
                <Button variant="contained" sx={{backgroundColor:"white", color:"rgb(20, 24, 135)"}}>Create</Button>
                <Button variant="contained" sx={{backgroundColor:"white", color:"rgb(20, 24, 135)", marginLeft:5}}>Edit</Button>
                <Button variant="contained" sx={{backgroundColor:"white", color:"rgb(20, 24, 135)", marginLeft:5}}>Delete</Button>

            </Box>
            <Divider variant="middle" sx={{py: 0,
  width: '100%',
  maxWidth: 1140,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
  marginTop:3}} />

  <Typography variant="h5" sx={{marginTop:2,marginLeft:4}}> Ad Placements</Typography>

  <Divider variant="middle" sx={{py: 0,
  width: '100%',
  maxWidth: 1140,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
  marginTop:3}} />
      
        <Box className="cards1" sx={{marginTop:5}}>
        <Card sx={{ backgroundColor: 'background.paper',height:200,width:550}}>
            <Box sx={{display:"flex"}}>
                <Box>
                <Typography sx={{paddingTop:5,paddingLeft:3,fontWeight:"bold"}}>Featured Ad Placement</Typography>
                <Typography sx={{paddingLeft:3}}>Eye-catching, engaging ads for <br/>
                maximum impact.</Typography>
                <Box sx={{display:"flex"}}><Typography sx={{paddingTop:2,paddingLeft:3,fontWeight:"bold"}}>Starts</Typography><Button sx={{marginLeft:4,marginTop:2,backgroundColor:"rgb(20, 24, 135)"}} variant="contained">Boost</Button>
                </Box>
                </Box>
                <Box>
                <CardMedia sx={{padding:5,height:200,width:250}} component="img" image={cd1} title="Image" alt="Image"/> 
                </Box>
            </Box>
        </Card>
        <Card sx={{ backgroundColor: 'background.paper',height:200,width:550}}>
            <Box sx={{display:"flex"}}>
                <Box>
                <Typography sx={{paddingTop:5,paddingLeft:3,fontWeight:"bold"}}>Promoted Post</Typography>
                <Typography sx={{paddingLeft:3}}>Targeted ads to boost visibility and <br/>
                engagement.</Typography>
                <Box sx={{display:"flex"}}><Typography sx={{paddingTop:2,paddingLeft:3,fontWeight:"bold"}}>From</Typography><Button sx={{marginLeft:4,marginTop:2,backgroundColor:"rgb(20, 24, 135)"}} variant="contained">Boost</Button>
                </Box>
                </Box>
                <Box>
                <CardMedia sx={{padding:5,height:200,width:250}} component="img" image={cd2} title="Image" alt="Image"/> 
                </Box>
            </Box>
        </Card>

       
      
        </Box>



        <Box className="cards1" sx={{marginTop:5}}>
        <Card sx={{ backgroundColor: 'background.paper',height:200,width:550}}>
            <Box sx={{display:"flex"}}>
                <Box>
                <Typography sx={{paddingTop:5,paddingLeft:3,fontWeight:"bold"}}>Sponsored Content</Typography>
                <Typography sx={{paddingLeft:3}}>Engaging Ad Copy</Typography>
                <Box sx={{display:"flex"}}><Typography sx={{paddingTop:2,paddingLeft:3,fontWeight:"bold"}}>₹2999</Typography><Button sx={{marginLeft:4,marginTop:2,backgroundColor:"rgb(20, 24, 135)"}} variant="contained">Boost</Button>
                </Box>
                </Box>
                <Box>
                <CardMedia sx={{padding:5,height:200,width:250,marginLeft:9}} component="img" image={cd3} title="Image" alt="Image"/> 
                </Box>
            </Box>
        </Card>
        <Card sx={{ backgroundColor: 'background.paper',height:200,width:550}}>
            <Box sx={{display:"flex"}}>
                <Box>
                <Typography sx={{paddingTop:5,paddingLeft:3,fontWeight:"bold"}}>Targeted Audience</Typography>
                <Typography sx={{paddingLeft:3}}>Reach the right audience with <br/>
                precision targeting.</Typography>
                <Box sx={{display:"flex"}}><Typography sx={{paddingTop:2,paddingLeft:3,fontWeight:"bold"}}>$45</Typography><Button sx={{marginLeft:4,marginTop:2,backgroundColor:"rgb(20, 24, 135)"}} variant="contained">Boost</Button>
                </Box>
                </Box>
                <Box>
                <CardMedia sx={{padding:5,height:200,width:250, marginLeft:4}} component="img" image={cd4} title="Image" alt="Image"/> 
                </Box>
            </Box>
        </Card>

       
      
        </Box>


        
        </Box>
        

        </>
    )
}

export default Metro;