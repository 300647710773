import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  Checkbox,
  IconButton,
  Button,
  Grid,
  Typography,
  MenuItem,
  Modal,
  TextField,
  InputAdornment,
  Divider,
  CircularProgress,
  useTheme,
  Box,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  DateRange as DateRangeIcon,
  CloudDownload as CloudDownloadIcon,
  Link as LinkIcon,
  InsertLink as InsertLinkIcon,
} from "@mui/icons-material";
import axios from "axios";
import { getUserFromLocalStorage } from "src/service/localStorage";
import { toast } from "react-toastify";
import ChooseImageForNotificationModal from "src/pages/modal/ChooseImageForNotificationModel";
import { useEffect } from "react";
import AffPushTemplateCard from "./AffPushTemplateCard";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: "80%" },
  background: "linear-gradient(180deg, #A5BFD9 9.55%, #A7D3FF 100%)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
};

function AffPushTemplateTable({ sendTemplateNotificationHandler }) {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [notificationClickActionLinkUrl, setNotificationClickActionLinkUrl] =
    useState("");
  const [notificationIconUrl, setNotificationIconUrl] = useState(null);
  const [notificationImageUrl, setNotificationImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoadingScelton, setIsLoadingSkeleton] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const theme = useTheme();
  const [affPushTemplateTableData, setAffPushTemplateTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentTemplateObject, setCurrentTemplateObject] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [templateUnarchived, setTemplateunarchived] = useState(true);
  const [sortAscending, setSortAscending] = useState(false);
  const [sortByCount, setSortByCount] = useState(false);
  const [sortBySentPush, setSortBySentPush] = useState(false);
  const [filterOption, setFilterOption] = useState("unarchived");

  const URL = process.env.REACT_APP_PROD_API;
  const {
    data: { access_token },
  } = getUserFromLocalStorage();
  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const handleTemplateArchive = async (archive) => {
    if (selectedRows.length === 0) {
      toast.error("Please select at least one template to proceed");
      return;
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const archiveRequests = selectedRows.map((id) => {
        return axios.put(
          `${URL}/api/pns/templates/archive`,
          {
            template_ids: [id],
            archive: archive,
          },
          { headers }
        );
      });

      await Promise.all(archiveRequests);

      setSelectedRows([]);
      toast.success(
        archive
          ? "Templates archived successfully"
          : "Templates unarchived successfully"
      );
      fetchAffPushTemplateData();
    } catch (error) {
      console.error("Error processing templates:", error);
      toast.error("Failed to process templates. Please try again.");
    }
  };

  const LoadingSkeletons = ({ count = 3 }) => {
    return (
      <>
        {Array(count)
          .fill()
          .map((_, index) => (
            <TableRow key={index}>
              <TableCell align="center">
                <Skeleton
                  variant="rounded"
                  width={10}
                  height={10}
                  sx={{ marginX: "auto", display: "block" }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                <Stack sx={{ position: "relative" }}>
                  <Skeleton
                    variant="circular"
                    width={53}
                    height={53}
                    sx={{ position: "absolute", top: 23, left: 15 }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{
                      width: { xs: 450, md: 500 },
                      py: 6,
                      borderRadius: 3.5,
                    }}
                  />
                </Stack>
              </TableCell>
              <TableCell align="center">
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ marginX: "auto", display: "block" }}
                />
              </TableCell>
              <TableCell align="center">
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ marginX: "auto", display: "block" }}
                />
              </TableCell>

              <TableCell align="center">
                <Skeleton
                  variant="rounded"
                  width={90}
                  height={35}
                  sx={{ marginX: "auto", display: "block" }}
                />
              </TableCell>
              <TableCell align="center">
                <Skeleton
                  variant="rounded"
                  width={40}
                  height={40}
                  sx={{ marginX: "auto", display: "block" }}
                />
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  };

  const deleteTemplate = async () => {
    if (selectedRows.length === 0) {
      toast.error("Please select a template first");
      return;
    }

    try {
      const {
        data: { access_token },
      } = getUserFromLocalStorage();

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      };

      const deleteRequests = selectedRows.map(async (id) => {
        try {
          await axios.delete(`${URL}/api/pns/template/${id}`, { headers });
          setSelectedRows([]);
        } catch (error) {
          // Log or display a more specific error message for this template deletion
          console.error(
            `Failed to delete template with ID ${id}: ${error.message}`
          );
          throw error; // Rethrow the error to ensure Promise.all catches it
        }
      });

      await Promise.all(deleteRequests);
      toast.success("Templates deleted successfully");
      fetchAffPushTemplateData(); // Assuming this function fetches the updated template data
    } catch (error) {
      toast.error("Something went wrong while deleting templates");
      console.error(`Failed to delete templates: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchAffPushTemplateData();
  }, []);

  useEffect(() => {
    setFilteredData(filterAndSortTemplates(affPushTemplateTableData));
  }, [
    affPushTemplateTableData,
    filterOption,
    sortAscending,
    sortByCount,
    sortBySentPush,
  ]);

  const fetchAffPushTemplateData = async () => {
    setIsLoadingSkeleton(true);
    try {
      const {
        data: { access_token },
      } = getUserFromLocalStorage();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      };
      const response = await axios.get(`${URL}/api/pns/templates`, { headers });
      if (response && response.data) {
        setAffPushTemplateTableData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoadingSkeleton(false);
    }
  };

  const sortData = (data, key, ascending) => {
    return data.sort((a, b) => {
      const valueA = key === "timestamp" ? new Date(a[key]) : a[key];
      const valueB = key === "timestamp" ? new Date(b[key]) : b[key];
      return ascending ? valueA - valueB : valueB - valueA;
    });
  };

  const filterAndSortTemplates = (originalData) => {
    let filteredData = [...originalData];

    if (filterOption === "unarchived") {
      filteredData = filteredData.filter((template) => !template.archive);
    } else if (filterOption === "archived") {
      filteredData = filteredData.filter((template) => template.archive);
    }

    if (sortByCount) {
      filteredData = sortData(filteredData, "click_count", sortAscending);
    } else if (sortBySentPush) {
      filteredData = sortData(filteredData, "push_sent", sortAscending);
    } else {
      filteredData = sortData(filteredData, "timestamp", sortAscending);
    }

    return filteredData;
  };

  const handleSelectExternalImageUrl = (url) => {
    if (url) {
      setNotificationImageUrl(url);
      handleClose();
      toast.success("Image URL added");
    } else {
      toast.error("Please select an image URL");
    }
  };

  const onSelectImage = (url) => {
    if (url) {
      setNotificationIconUrl(url);
      handleClose();
      toast.success("Image selected");
    }
  };

  const handleDuplicate = (currentTemplateData) => {
    setCurrentTemplateObject(currentTemplateData);
    if (currentTemplateData) {
      setNotificationClickActionLinkUrl(currentTemplateData.clickAction);
      setNotificationDescription(currentTemplateData.body);
      setNotificationIconUrl(currentTemplateData.image_url);
      setNotificationTitle(currentTemplateData.title);
    }
  };

  const handleSubmitButtonForCreateTemplates = async () => {
    const validate = (field, fieldName) => {
      if (!field || field.trim() === "") {
        toast.error(`${fieldName} field is required`);
        return false;
      }
      return true;
    };

    // Separate validation to clarify logic
    const imageValid = notificationIconUrl || notificationImageUrl;
    if (
      !validate(imageValid, "Select an Image") ||
      !validate(notificationTitle, "Notification Title") ||
      !validate(notificationDescription, "Notification Description") ||
      !validate(
        notificationClickActionLinkUrl,
        "Notification Click Action Link URL"
      )
    ) {
      return;
    }

    const { clickAction, image_url, title, body } = currentTemplateObject;
    if (currentTemplateObject) {
      setNotificationClickActionLinkUrl(clickAction);
      setNotificationDescription(body);
      setNotificationIconUrl(image_url);
      setNotificationTitle(title);
    }
    const payload = {
      title: notificationTitle || title,
      body: notificationDescription || body,
      image_url: imageValid || image_url,
      clickAction: notificationClickActionLinkUrl || clickAction,
    };

    const URL = process.env.REACT_APP_PROD_API;
    try {
      const apiURL = `${URL}/api/pns/template/create`;
      const {
        data: { access_token },
      } = getUserFromLocalStorage();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      };

      setLoading(true);
      const response = await axios.post(apiURL, payload, { headers });
      if (response) {
      }
      toast.success("Notification Template Created successfully!");
      handleClose1();
      fetchAffPushTemplateData();
    } catch (error) {
      toast.error(
        error.response?.data?.detail[0]?.msg || "An unexpected error occurred"
      );
    } finally {
      setLoading(false);
      setNotificationTitle("");
      setNotificationDescription("");
      setNotificationClickActionLinkUrl("");
      setNotificationIconUrl("");
      handleClose2();
    }
  };

  const handleCheckboxChange = (event, id) => {
    // When a checkbox is checked, add its id to the selectedRows array
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id]); // Using the spread operator to add id to the array
    } else {
      // When a checkbox is unchecked, remove its id from the selectedRows array
      setSelectedRows((prev) => prev.filter((rowId) => rowId !== id)); // Filtering out the id from the array
    }
  };

  return (
    <>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ color: "#12314F" }} variant="h6">
            Create Notification Template
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Paper
                onClick={handleOpen}
                elevation={18}
                sx={{
                  height: { xs: "100px", md: "200px" },
                  width: { xs: "320px", md: "200px" },
                  bgcolor: "#BDDCFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px dashed #669ADA",
                }}
              >
                <CloudDownloadIcon
                  fontSize="large"
                  style={{ color: "#1C81F3" }}
                />
              </Paper>
              <Typography
                sx={{
                  color: "#12314F",
                  mt: 1,
                  fontSize: { xs: "1rem", md: "1rem" },
                }}
                variant="h6"
              >
                Push Image
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <TextField
                sx={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid black",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                id="outlined-multiline-flexible"
                multiline
                maxRows={1}
                fullWidth
                value={notificationTitle}
                className="form-control custom-input shadow-sm"
                placeholder="Enter Your Notification’s Title Here"
                onChange={(e) => setNotificationTitle(e.target.value)}
              />

              <TextField
                sx={{
                  mt: 1,
                  backgroundColor: "#FFFFFF",
                  border: "1px solid black",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                id="outlined-multiline-flexible"
                multiline
                maxRows={2}
                fullWidth
                placeholder="Enter Your Notification’s Description Here"
                className="form-control custom-input shadow-sm"
                value={notificationDescription}
                onChange={(e) => setNotificationDescription(e.target.value)}
              />

              <TextField
                sx={{
                  mt: 1,
                  backgroundColor: "#FFFFFF",
                }}
                id="outlined-start-adornment"
                fullWidth
                multiline
                maxRows={2}
                placeholder="Enter Click Action Link"
                className="custom-input shadow-sm"
                value={notificationClickActionLinkUrl}
                onChange={(e) =>
                  setNotificationClickActionLinkUrl(e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ border: "2px solid black", my: 2 }} />
          <Box sx={{ mt: 1.8, width: "100%", textAlign: "center" }}>
            <Button
              disabled={loading}
              onClick={handleSubmitButtonForCreateTemplates}
              sx={{
                fontSize: { xs: 15, md: "18px" },
                backgroundColor: loading ? "gray" : "#445490",
                "&:hover": {
                  backgroundColor: loading ? "gray" : "#5671B0", // Changed the hover color for better user feedback
                },
                color: "white",
              }}
            >
              {loading ? <CircularProgress /> : "Generate"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ color: "#12314F" }} variant="h6">
            Create Duplicate Template
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Paper
                onClick={handleOpen1}
                elevation={18}
                sx={{
                  height: { xs: "100px", md: "200px" },
                  width: { xs: "320px", md: "200px" },
                  bgcolor: "#BDDCFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px dashed #669ADA",
                }}
              >
                <CloudDownloadIcon
                  fontSize="large"
                  style={{ color: "#1C81F3" }}
                />
              </Paper>
              <Typography
                sx={{
                  color: "#12314F",
                  mt: 1,
                  fontSize: { xs: "1rem", md: "1rem" },
                }}
                variant="h6"
              >
                Push Image
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <TextField
                sx={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid black",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                id="outlined-multiline-flexible"
                multiline
                maxRows={1}
                fullWidth
                value={notificationTitle}
                className="form-control custom-input shadow-sm"
                placeholder="Enter Your Notification’s Title Here"
                onChange={(e) => setNotificationTitle(e.target.value)}
              />

              <TextField
                sx={{
                  mt: 1,
                  backgroundColor: "#FFFFFF",
                  border: "1px solid black",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                id="outlined-multiline-flexible"
                multiline
                maxRows={2}
                fullWidth
                placeholder="Enter Your Notification’s Description Here"
                className="form-control custom-input shadow-sm"
                value={notificationDescription}
                onChange={(e) => setNotificationDescription(e.target.value)}
              />

              <TextField
                sx={{
                  mt: 1,
                  backgroundColor: "#FFFFFF",
                }}
                id="outlined-start-adornment"
                fullWidth
                multiline
                maxRows={2}
                placeholder="Enter Click Action Link"
                className="custom-input shadow-sm"
                value={notificationClickActionLinkUrl}
                onChange={(e) =>
                  setNotificationClickActionLinkUrl(e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ border: "2px solid black", my: 2 }} />
          <Box sx={{ mt: 1.8, width: "100%", textAlign: "center" }}>
            <Button
              disabled={loading}
              onClick={handleSubmitButtonForCreateTemplates}
              sx={{
                fontSize: { xs: 15, md: "18px" },
                backgroundColor: loading ? "gray" : "#445490",
                "&:hover": {
                  backgroundColor: loading ? "gray" : "#5671B0", // Changed the hover color for better user feedback
                },
                color: "white",
              }}
            >
              {loading ? <CircularProgress /> : "Generate"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <TableContainer
        component={Paper}
        elevation={3}
        sx={{ mt: 2, mb: 2, borderRadius: 4 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 3,
            py: 3,
            alignItems: "center",
            gap: { xs: 1.5, md: 0 },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#12314F",
              fontSize: { xs: 10, md: 15 },
              whiteSpace: "nowrap",
            }}
          >
            Your Templates
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
            }}
          >
            <Button
              variant="contained"
              onClick={handleOpen1}
              sx={{ fontSize: { xs: 10, md: 15 }, whiteSpace: "nowrap" }}
            >
              CREATE TEMPLATE
            </Button>
            <Button
              onClick={() => handleTemplateArchive(true)}
              variant="contained"
              sx={{
                fontSize: { xs: 10, md: 15 },
                whiteSpace: "nowrap",
                bgcolor: "#FFFF",
                color: "#12314F",
                "&:hover": {
                  bgcolor: "#F3F3F3",
                },
                border: "1px solid gray",
              }}
            >
              Archived
            </Button>
            <Button
              onClick={() => handleTemplateArchive(false)}
              variant="contained"
              sx={{
                fontSize: { xs: 10, md: 15 },
                whiteSpace: "nowrap",
                bgcolor: "#FFFF",
                color: "#12314F",
                "&:hover": {
                  bgcolor: "#F3F3F3",
                },
                border: "1px solid gray",
              }}
            >
              Unarchive
            </Button>
            <Button
              onClick={deleteTemplate}
              variant="contained"
              color="error"
              sx={{ fontSize: { xs: 10, md: 15 }, whiteSpace: "nowrap" }}
            >
              Delete
            </Button>
          </Box>
        </Box>
        <Table
          sx={{ width: { xs: 1200, md: "100%" } }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  // Determine if the checkbox should be in an indeterminate state
                  indeterminate={
                    selectedRows.length > 0 &&
                    selectedRows.length < filteredData.length
                  }
                  // Determine if the checkbox should be checked
                  checked={
                    filteredData.length > 0 &&
                    selectedRows.length === filteredData.length
                  }
                  // Handle the onChange event of the checkbox
                  onChange={(event) => {
                    // If the checkbox is checked, select all rows
                    const newSelectedRows = event.target.checked
                      ? filteredData.map((data, index) => data.template_id)
                      : // If the checkbox is unchecked, deselect all rows
                        [];
                    // Update the selectedRows state with the new selection
                    setSelectedRows(newSelectedRows);
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <Select value={filterOption} onChange={handleFilterChange}>
                  <MenuItem value="all">Templates all</MenuItem>
                  <MenuItem value="archived">Templates archived</MenuItem>
                  <MenuItem value="unarchived">Templates unarchived</MenuItem>
                </Select>
              </TableCell>
              <TableCell align="center">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  Created at{" "}
                  <IconButton
                    sx={{ marginLeft: "2px" }}
                    aria-label="toggle sort-date"
                    onClick={() => {
                      setSortAscending((prev) => !prev);
                    }}
                  >
                    {sortAscending ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </div>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  Total Clicks
                  <IconButton
                    sx={{ marginLeft: "2px" }}
                    aria-label="toggle sort-date"
                    onClick={() => {
                      setSortByCount((prev) => !prev);
                    }}
                  >
                    {sortByCount ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </div>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  Total Push Sent
                  <IconButton
                    sx={{ marginLeft: "2px" }}
                    aria-label="toggle sort-date"
                    onClick={() => {
                      setSortBySentPush((prev) => !prev);
                    }}
                  >
                    {sortBySentPush ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </div>
              </TableCell>
              <TableCell align="center">Send Push</TableCell>
              <TableCell align="center">Create Duplicate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoadingScelton ? (
              <LoadingSkeletons />
            ) : (
              filteredData.map((row, id) => (
                <TableRow
                  key={id}
                  selected={selectedRows.includes(row.template_id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRows.includes(row.template_id)}
                      onChange={(event) =>
                        handleCheckboxChange(event, row.template_id)
                      }
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <AffPushTemplateCard
                      title={row.title}
                      imageUrl={row.image_url}
                      body={row.body}
                      clickAction={row.clickAction}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {row.timestamp
                      ? new Date(row.timestamp).toLocaleString()
                      : 0}
                  </TableCell>
                  <TableCell align="center">
                    {row.click_count ? row.click_count : 0}
                  </TableCell>
                  <TableCell align="center">
                    {row.push_sent ? row.push_sent : 0}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => sendTemplateNotificationHandler(row)}
                      sx={{
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Send Push
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        handleDuplicate(row);
                        handleOpen2();
                      }}
                    >
                      <FilterListIcon
                        fontSize="large"
                        style={{ color: "#787aff" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <ChooseImageForNotificationModal
        onSelectImage={onSelectImage}
        open={open}
        handleClose={handleClose}
        onSelectExternalImageUrl={handleSelectExternalImageUrl}
        se
      />
    </>
  );
}

export default AffPushTemplateTable;
