import React from "react";
import { Link as MuiLink, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";

function Terms_Conditions() {
  return (
    <>
    <Helmet>
      <title>Terms and Conditions | Affworld</title>
      <meta name="description" content="Review AffWorld.io's Terms and Conditions to understand the rules and guidelines governing our services. Ensure a clear and fair understanding of our terms." />
      <meta name="keywords" content="AffWorld.io terms and conditions, service terms, user agreement, legal terms, company policies, usage guidelines, terms of service, service rules, Affworld, Affworld terms and conditions" />
    </Helmet>
      <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
        Terms and Conditions
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Introduction
      </Typography>
      <Typography>
        These Terms and Conditions ("Terms") govern your use of the Affworld
        Technology website and services. By accessing or using our website or
        services, you agree to be bound by these Terms. Use of Our Services
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Eligibility:
        </Typography>
        You must be at least 18 years old to use our services.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Account Registration:
        </Typography>
        You may be required to create an account to access certain services. You
        agree to provide accurate and complete information and keep your account
        information updated.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Prohibited Activities:
        </Typography>
        You agree not to engage in any unlawful or prohibited activities,
        including but not limited to: Violating any applicable laws or
        regulations
        <br />
        Infringing on the rights of others
        <br />
        Using our services to distribute spam or malware
        <br />
        Attempting to gain unauthorized access to our systems
        <br />
        Intellectual Property
        <br />
        All content on our website, including text, graphics, logos, and
        software, is the property of Affworld Technology or its licensors and is
        protected by copyright and other intellectual property laws. You agree
        not to reproduce, distribute, or create derivative works from any
        content without our prior written consent.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Disclaimers
          <br />
          Service Availability:
        </Typography>
        We strive to ensure our services are available at all times, but we do
        not guarantee uninterrupted access.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Accuracy of Information:
        </Typography>
        We make reasonable efforts to provide accurate information on our
        website, but we do not warrant the accuracy, completeness, or
        reliability of any information.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Third-Party Links:
        </Typography>
        Our website may contain links to third-party websites. We are not
        responsible for the content or practices of these websites.
        <br />
        Limitation of Liability
        <br />
        To the fullest extent permitted by law, Affworld Technology shall not be
        liable for any indirect, incidental, special, or consequential damages
        arising out of or in connection with your use of our services.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Indemnification
        </Typography>
        You agree to indemnify and hold Affworld Technology harmless from any
        claims, damages, losses, liabilities, and expenses arising out of or in
        connection with your use of our services or violation of these Terms.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Governing Law
        </Typography>
        These Terms shall be governed by and construed in accordance with the
        laws of [Your Jurisdiction]. Any disputes arising out of or in
        connection with these Terms shall be subject to the exclusive
        jurisdiction of the courts of [Your Jurisdiction].
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Changes to These Terms
        </Typography>
        We may update these Terms from time to time. We will notify you of any
        changes by posting the new Terms on this page. Your continued use of our
        services after any changes constitutes your acceptance of the new Terms.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Effective Date:
        </Typography>{" "}
        These Terms are effective as of [Date].
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Contact Us
        </Typography>
        If you have any questions about these Terms, please contact us at{" "}
        <MuiLink
          href="mailto:support@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >support@affworld.io</MuiLink> or call our customer service hotline at{" "}
        <MuiLink
          href="tel:+971542997582"
          variant="body1"
          color="primary"
          underline="hover"
        >+971542997582</MuiLink>.
      </Typography>
    </>
  );
}

export default Terms_Conditions;
