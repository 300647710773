import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Link,
  Box,
  Modal,
  Stack,
  TextField,
  LinearProgress,
} from "@mui/material";
import { getUserFromLocalStorage } from "src/service/localStorage";
import axios from "axios";
import { toast } from "react-toastify";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { isValidURL } from "src/pages/SelfPromotion";

const SelfPromotionTable = ({ productData, onDelete, onEdite, dataLoading }) => {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openEdit, setOpenEdite] = useState(false);

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_PROD_API;
  const {
    data: { access_token },
  } = getUserFromLocalStorage();
  const handleDeleteClick = (product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpenEdite(false);
    setSelectedProduct(null);
    setImage(null);
  };
  const handleChange = (field, value) => {
    setSelectedProduct((prev) => ({ ...prev, [field]: value }));
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  const handleEditClick = (product) => {
    console.log();

    setSelectedProduct({
      title: product.title,
      description: product.description,
      productLink: product.product_link,
      product_id: product.product_id,
    });
    setOpenEdite(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !selectedProduct.title ||
      !selectedProduct.description ||
      !selectedProduct.productLink
    ) {
      toast.error("Please fill in all fields.");
      return;
    }
    if (!isValidURL(selectedProduct.productLink)) {
      toast.error("Please enter a valid product link.");
      return;
    }
    if (!image) {
      toast.error("Please select an image.");
      return;
    }

    const formData = new FormData();
    formData.append("file", image);
    const queryParams = new URLSearchParams({
      title: selectedProduct.title,
      description: selectedProduct.description,
      product_link: selectedProduct.productLink,
    }).toString();

    setLoading(true);
    try {
      const response = await axios.put(
        `${API_URL}/api/adspace/self_promotion/${selectedProduct.product_id}?${queryParams}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (response) {
        onEdite();
      }
      toast.success("Product updated successfully!");
      handleClose();
    } catch (error) {
      console.error("Error uploading product:", error);
      toast.error("Failed to upload product.");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `${API_URL}/api/adspace/self_promotion/${selectedProduct?.product_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success("Product deleted successfully!");
      onDelete();
    } catch (error) {
      console.error("Failed to delete product:", error);
    } finally {
      setOpen(false);
    }
  };

  return (
    <Box sx={{ overflowX: "auto", width: "100%", padding: 2 }}>
      <TableContainer
        component={Paper}
        sx={{
          margin: "auto",
          width: "100%",
          borderRadius: "8px",
          height: "80vh",
        }}
      >
        {
          dataLoading ? (
            <LinearProgress />
          ) : (
            productData.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ minWidth: 80, fontWeight: "bold" }}
                    >
                      Image
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: 180, fontWeight: "bold" }}
                    >
                      Title
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: 250, width: 300, fontWeight: "bold" }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: 120, fontWeight: "bold" }}
                    >
                      Link
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: 160, fontWeight: "bold" }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productData.map((product) => (
                    <TableRow key={product.product_id}>
                      <TableCell align="center">
                        <img
                          src={product.image_url}
                          alt={product.title}
                          style={{
                            width: "100px",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                            margin: "auto",
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">{product.title}</TableCell>
                      <TableCell align="center">{product.description}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          href={product.product_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Visit
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => handleEditClick(product)}
                          variant="outlined"
                          color="primary"
                          sx={{ mr: { xs: 0, sm: 0, md: 2 } }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteClick(product)}
                          sx={{ mt: { xs: 2, sm: 0, md: 0 } }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="h6" align="center" sx={{ padding: 4 }}>
                No Products Available.
              </Typography>
            )
          )
        }
      </TableContainer>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <Typography variant="h6" align="center" sx={{ mb: 2 }}>
            Are you sure you want to delete this product?
          </Typography>
          <Stack direction="row" spacing={4} justifyContent="space-around">
            <Button
              variant="contained"
              color="error"
              onClick={handleConfirmDelete}
            >
              Yes, Delete
            </Button>
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: { xs: "90%", md: "40%" },
            borderRadius: 2,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Product
          </Typography>
          <form onSubmit={handleSubmit} style={{ marginTop: "1rem" }}>
            <TextField
              label="Title"
              fullWidth
              margin="normal"
              value={selectedProduct?.title}
              onChange={(e) => handleChange("title", e.target.value)}
            />
            <TextField
              label="Description"
              fullWidth
              margin="normal"
              multiline
              rows={3}
              value={selectedProduct?.description}
              onChange={(e) => handleChange("description", e.target.value)}
            />
            <TextField
              label="Product Link"
              fullWidth
              margin="normal"
              value={selectedProduct?.productLink}
              onChange={(e) => handleChange("productLink", e.target.value)}
            />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                component="label"
                startIcon={<CloudUploadIcon />}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Upload Image
                </Typography>
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageChange}
                />
              </Button>
              {image && (
                <Typography variant="body2" color="textSecondary">
                  {image.name}
                </Typography>
              )}
            </Box>

            {loading && <LinearProgress sx={{ mt: 2 }} />}

            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3 }}
              fullWidth
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default SelfPromotionTable;
