import { Link as MuiLink, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

function PricingDetails() {
  return (
    <>
    <Helmet>
    <title>Pricing Details | Affworld</title>
      <meta name="description" content="Explore AffWorld.io's transparent pricing details for our IT services. Find affordable solutions tailored to your business needs with clear and competitive pricing." />
      <meta name="keywords" content="AffWorld.io pricing details, IT service pricing, affordable solutions, transparent pricing, cost-effective IT services, pricing plans, service costs, Affworld, Affworld pricing details" />
    </Helmet>
      <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
        Pricing Details
      </Typography>
      <Typography>
        At Affworld Technology, we believe in transparent and straightforward
        pricing. Our fixed-fee structure ensures that you know exactly what
        you’re paying for, with no hidden costs. Below are the pricing details
        for our influencer services, ad space, and other offerings.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Influencer Services
          <br />
          Influencer Hiring:
        </Typography>
        Basic Package: $500 per campaign
        <br />
        Ideal for small-scale promotions.
        <br />
        Includes selection and management of one influencer.
        <br />
        Campaign duration: Up to 1 week.
        <br />
        Standard Package: $1,500 per campaign
        <br />
        <br />
        Suitable for medium-scale promotions.
        <br />
        Includes selection and management of up to three influencers.
        <br />
        Campaign duration: Up to 2 weeks.
        <br />
        Premium Package: $3,000 per campaign
        <br />
        <br />
        Perfect for large-scale promotions.
        <br />
        Includes selection and management of up to five influencers.
        <br />
        Campaign duration: Up to 1 month.
        <br />
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Influencer Agent Services:
        </Typography>
        Monthly Subscription: $1,000 per month
        <br />
        Ongoing influencer management and contract negotiations.
        <br />
        Continuous support and performance tracking.
        <br />
        Annual Subscription: $10,000 per year
        <br />
        All benefits of the monthly subscription with a discounted rate.
        <br />
        Priority support and exclusive insights.
        <br />
        Ad Space Purchase
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Fixed Ad Space Fees:
        </Typography>
        Local Ad Space: $200 per week
        <br />
        <br />
        Target a specific city or region.
        <br />
        Suitable for localized campaigns.
        <br />
        National Ad Space: $1,000 per week
        <br />
        Reach audiences across the country.
        <br />
        Ideal for national promotions.
        <br />
        International Ad Space: $5,000 per week
        <br />
        Global reach to multiple countries.
        <br />
        Perfect for international campaigns.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Custom Ad Space Packages:
        </Typography>
        Contact us at <MuiLink
          href="mailto:adspace@affworld.com"
          variant="body1"
          color="primary"
          underline="hover"
        >
          adspace@affworld.com
        </MuiLink> for tailored packages to suit your
        specific needs and goals.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Other Services
          <br />
          Consulting Services:
        </Typography>
        Basic Consultation: $250 per hour
        <br />
        Marketing strategy sessions.
        <br />
        Campaign planning and advice.
        <br />
        Comprehensive Consultation: $2,000 per month
        <br />
        Includes up to 10 hours of consultation.
        <br />
        Detailed campaign analysis and ongoing support.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Custom Solutions:
        </Typography>
        We also offer custom solutions tailored to your unique requirements.
        Please contact our sales team at sales@affworld.com to discuss your
        needs and get a personalized quote.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Agreements and Terms
          <br />
          Fixed Fees:
        </Typography>
        All our services are provided at fixed fees, ensuring transparency and
        predictability.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Agreements:
        </Typography>
        We have agreements in place on both sides (clients and
        influencers/advertisers) to ensure clarity and mutual understanding of
        terms.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Payment Terms:
        </Typography>
        Payment is required upfront for all fixed-fee services.
        <br />
        For subscription services, payment is due at the beginning of each
        billing cycle.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Refund Policy:
        </Typography>
        Please refer to our Cancellation and Refund Policy for details on refund
        eligibility and procedures.
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
          Contact Us:
        </Typography>
        For more information about our pricing or to request a custom quote,
        please contact us at{" "}
        <MuiLink
          href="mailto:pricing@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >
          pricing@affworld.io
        </MuiLink>{" "}
        or call{" "}
        <MuiLink
          href="tel:+971542997582"
          variant="body1"
          color="primary"
          underline="hover"
        >
          +971542997582
        </MuiLink>
        .
      </Typography>
    </>
  );
}

export default PricingDetails;
