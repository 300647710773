import React from 'react'
import BackupIcon from '@mui/icons-material/Backup';
import "./SendPush.css"
import link from "./../images/link.png"
import { Select } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PaidIcon from '@mui/icons-material/Paid';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import StackedBarChartOutlinedIcon from '@mui/icons-material/StackedBarChartOutlined';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
function SendPush() {
    return (
        <div>
            <div style={{ background: 'linear-gradient( #A5BFD9, #A7D3FF)', padding: "50px 25px" }}>
                <h1 className='top-hea' style={{ fontSize: "40px", lineHeight: "24px", fontWeight: "400" }}>AFF PUSH</h1>
                <div className='d-flex'>
                    <div className='div-1 mt-5'>
                        <p className='hea-2 mb-5'>Send Fresh Notification</p>
                        <div className='push-img-div d-flex'>
                            <div>
                                <div>
                                    <div className='upload'><BackupIcon className='upload-icon' /></div>
                                    <br />
                                    <p className='push-img-text'>Push Image</p></div>
                                <img src={link} className='mt-3 ms-4 link-icon' />
                            </div>
                            <div className='display- ms-3 '>
                                <input type='text' placeholder='Enter Your Notification’s  Title Here' className='input-title my-1' />
                                <input type='text' placeholder='Enter Your Notification’s Description Here' className='input-Description mb-3' />
                                <input type='text' placeholder='Enter Click Action Link' className='input-title' />
                            </div>
                        </div>
                        <div className='mt-5'>
                            <input type='checkbox' /> <label className='ms-2'>Schedule Notification</label><br />
                            <input type='text' placeholder='dd/mm/yyyy--:--' className='date-inputbox my-4' />
                            <Select className='select-inputbox'>
                                <option>Select Your Websites For Notification</option>
                            </Select>
                            <button className='send-btn my-4'>Send Notification</button>
                        </div>

                    </div>
                    <div className='mt-5 ms-5 d-flex'>
                        <div>
                            <div className='total-spend'>
                                <AccountBalanceWalletIcon className='mt-2 icons' /><br />
                                <span className='mt-5'>Total Spend</span><br />
                                <span className='mt-3 text'>₹ 5000</span>
                            </div>
                            <div className='total-spend mt-4'>
                                <AccountBalanceWalletIcon className='mt-4 icons' /><br />
                                <span className='mt-5 text'>Payments</span><br />

                            </div>
                            <div className='total-graph mt-4'>


                            </div>

                        </div>
                        <div className='ms-5'>
                            <div className='cards'><ImageIcon className='me-3' />Manage Gallery</div>
                            <div className='cards'><SettingsIcon className='me-3' /> Manage Site</div>
                            <div className='cards'><HistoryIcon className='me-3' /> Push History</div>
                            <div className='cards'><AttachFileIcon className='me-3' />Link Library</div>
                            <div className='cards'><PhotoAlbumIcon className='me-3' />template Library</div>
                            <div className='cards'><StackedBarChartOutlinedIcon className='me-3' /> Statistics</div>
                        </div>
                    </div>
                </div>





                <div className='save-div px-3 py-3'>
                    <div className='d-flex head'>
                        <span className='your-temp-heading'>Your Templates</span>
                        <div className='d-flex'><button className='send-btn'>CREATE TEMPLATE</button>
                            <button type='button' className='ms-3 delete-btn'>DELETE</button></div>
                    </div>
                    <div className='d-flex mt-5'>
                        <span className='widths'>Templates</span>
                        <span  className='width'>Total Clicks</span>
                        <span  className='width'>Total Push Sent</span>
                        <span  className='width'>Send Push</span>
                        <span className='width'>Schedule Push</span>
                        <span className='width'>Create Duplicate</span>
                        
                    </div>
                    <hr/>
                </div>
            </div>

        </div>
    )
}

export default SendPush
