import { Grid } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import moneyPng from "./../images/flying social media notification.png";
import { useState, useEffect } from "react";
import "./clickLogs.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Button, Input, Typography } from "@mui/material";
import { toast } from "react-toastify";
import account from "src/_mock/account";
import { getUserFromLocalStorage } from "src/service/localStorage";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Chistory from "./Chistory";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function AffPulse() {
  const theme = useTheme();
  const linearGradient1 =
    "linear-gradient(to bottom, #6683b1, #7b91b9, #8fa0c0, #a3afc8, #b6becf, #bcc4d5, #c3cbdb, #c9d1e1, #c4cfe7, #bfcdee, #bacbf4, #b6c9fa)";
  const linearGradient2 =
    "linear-gradient(to right, #3e6a92, #3b5b83, #384c73, #343d62, #2f2f52)";

  let [data, setData] = useState([]);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [service, setService] = useState([]);
  const [sname, setSname] = useState();
  const [serviceObj, setServiceObj] = useState(null);
  const [quantity, setQuantity] = useState(100);
  const [timing, setTiming] = useState(0 / 0 / 1);
  const [link, setLink] = useState();
  const [maxExecutions, setMaxExecutions] = useState(1);
  const { handleSubmit, control } = useForm();
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalCharges, setTotalCharges] = useState(0);
  const [rate, setRate] = useState(0);
  const URL = process.env.REACT_APP_PROD_FILINGSOLUTIONS_API;
  const Affiliate_URL = process.env.REACT_APP_PROD_API;
  const [remainBalance, setRemainBalance] = useState(0);
  const user2 = getUserFromLocalStorage();
  const accessToken = user2?.data.access_token;
  const [serviceId, setServiceId] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [checked, setChecked] = useState(false);
  const [showStatitics, setShowStatitics] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const getData = async () => {
    try {
      const response = await axios.get(`${URL}/api/service-list`);
      if (response.status === 200) {
        // toast.success("Services fetched successfully!!");
        setData(response?.data);
        // console.log("this is data--->", response?.data);
        setCategoryOption(data.category);
      }
    } catch (error) {
      console.error("Error fetching Data --->", error);
      toast.error("Error fetching filing hub soltion data!!");
    }
  };

  const getRemainBalance = async () => {
    try {
      const url = `${Affiliate_URL}/api/wallet/total-remaining-balance`;
      const data = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (data.status === 200) {
        // console.log("this is remaining balance--->", data);
        // toast.success("Remaining balance fetched successfully!!");
        const remainBalance = (data?.data?.total_remaining_balance).toFixed(2);
        setRemainBalance(remainBalance);
      }
    } catch (error) {
      console.error("Error in  fetching remaining balance --->", error);
      // toast.error("Error fetching remaining balance!!");
    }
  };

  const handleTiming = (e) => {
    const inputTiming = e.target.value;
    // Split the input into days, hours, and minutes
    const [days, hours, minutes] = inputTiming.split("/").map(Number);

    // Calculate the total milliseconds
    const totalMilliseconds =
      (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60) * 1000;
    setTiming(totalMilliseconds);
    // console.log(timing);
  };

  const uniqueCategories = Object.values(data).map((item) => item.category);
  const uniqueCategoriesSet = [...new Set(uniqueCategories)];

  useEffect(() => {
    getData();
    getRemainBalance();
  }, []);

  const handleAdvanceCamapgin = () => {
    navigate("/dashboard/clicklogs2");
  };

  const updateQunatityCharge = () => {
    let totalQuantity = 0;
    let totalCharges = 0;

    totalQuantity = quantity * maxExecutions;

    totalCharges = totalQuantity * rate;

    setTotalQuantity(totalQuantity);

    setTotalCharges(totalCharges);
  };

  useEffect(() => {
    updateQunatityCharge();
  }, [quantity, maxExecutions, rate, serviceId]);

  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryData = data.find(
        (item) => item.category === selectedCategory
      );
      if (selectedCategoryData) {
        setServiceOptions(selectedCategoryData.services);
        const names = selectedCategoryData.services.map(
          (service) => service.name
        );

        setService(names);
      }
    }
  }, [selectedCategory, data, rate, serviceId]);

  const handleServiceChange = (e) => {
    const selectedService = serviceOptions.find(
      (service) => service.name === e.target.value
    );
    if (selectedService) {
      setRate(selectedService.rate);
      setServiceId(selectedService.serviceId);
      setServiceName(selectedService.name);
    } else {
      setRate("");
      setServiceId("");
      setServiceName("");
    }
  };

  const onSubmit1 = async () => {
    if (!sname) {
      toast.error("Service name is required ?");
      return;
    }
    if (!quantity) {
      toast.error("Quantity is required ?");
      return;
    }
    if (!timing) {
      toast.error("Time Gap is required ?");
      return;
    }
    if (!link) {
      toast.error("Link is required ?");
      return;
    }
    if (!maxExecutions) {
      toast.error("Max Executions is required ?");
      return;
    }
    if (!serviceName) {
      toast.error("Service is required ?");
      return;
    }
    setSubmitting(true);
    try {
      const data = {
        name: sname,
        category: selectedCategory,
        serviceName:serviceName,
        quantity: quantity,
        timing: timing,
        link: link,
        maxExecutions: maxExecutions,
        affiliate_id: account.affiliate_id,
      };

      const res = await axios.post(`${URL}/api/jobs`, data);

      if (res.status === 200) {
        toast.success("Campagin Added successfully see Statistics!!");
        setServiceId("");
        setSname("");
        setTiming("");
        setQuantity("");
        setLink("");
        setMaxExecutions("");
        setRate("");
        setService([]);
        setServiceOptions([]);
        setCategoryOption([]);
      }
    } catch (error) {
      console.log("this is error While submitting the data--->", error);
      toast.error("Error While submitting the data!! See Console");
      setService([]);
    } finally {
      // Enable the button regardless of success or failure
      setSubmitting(false);
    }
  };
  const onSubmit2 = async () => {
    if (!link) {
      toast.error("Link is required ?");
      return;
    }
    if (quantity < 100) {
      toast.error("Minimum Quantity is 100 required ?");
      return;
    }

    if (!serviceName) {
      toast.error("Service is required ?");
      return;
    }
    setSubmitting(true);

    try {
      const data = {
        name: "Single Affpulse",
        category: selectedCategory,
        serviceName:serviceName,
        quantity: quantity,
        timing: "",
        link: link,
        maxExecutions: 1,
        affiliate_id: account.affiliate_id,
      };

      const res = await axios.post(`${URL}/api/jobs`, data);

      if (res.status === 200) {
        toast.success("Campagin Added successfully see Statistics!!");
        setServiceId("");
        setSname("");
        setTiming("");
        setQuantity("");
        setLink("");
        setMaxExecutions("");
        setRate("");
        setService([]);
        setServiceOptions([]);
        setCategoryOption([]);
      }
    } catch (error) {
      console.log("this is error While submitting the data--->", error);
      toast.error("Error While submitting the data!! See Console");
      setService([]);
    } finally {
      // Enable the button regardless of success or failure
      setSubmitting(false);
    }
  };
  return (
    <Box
      sx={{
        padding: { xs: "10px 0", md: "10px" },
        backgroundImage: linearGradient1,
      }}
    >
      <Box
        component={"header"}
        sx={{
          [theme.breakpoints.up("xs")]: {
            width: "90%",
            backgroundImage: linearGradient2,
            margin: "30px auto",
            borderRadius: "30px",
            padding: "5px 20px",
          },
          [theme.breakpoints.up("md")]: {
            width: "80%",
            backgroundImage: linearGradient2,
            margin: "40px auto",
            borderRadius: "30px",
            padding: "5px 20px",
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                margin: "10px",
                // height: "170px",
                overflow: "hidden",
                // padding: "5x",
              }}
            >
              <Box className="main-container d-flex flex-column justify-content-center align-items-cnter gap-2">
                <Typography
                  className="monoton-regular  text-light"
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      fontSize: "30px",
                      textAlign: "center",
                    },
                    [theme.breakpoints.up("md")]: {
                      fontSize: "42px",
                      textAlign: "start",
                    },
                  }}
                >
                  AFF Pulse
                </Typography>

                <Box sx={{ backgroundColor: "#ffff", borderRadius: "15px" }}>
                  <Box
                    className="add-wallet-money-container"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: { xs: "20px", md: "50px" },
                      padding: "5px 10px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "15px",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          [theme.breakpoints.up("xs")]: {
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                          },
                          [theme.breakpoints.up("md")]: {
                            fontSize: "22px",
                          },
                        }}
                      >
                        Wallet :{" "}
                        {remainBalance < 0 ? (
                          <span className="ml-3" style={{ color: "red" }}>
                            {" "}
                            ₹{remainBalance}{" "}
                          </span>
                        ) : (
                          <span className="ml-3" style={{ color: "green" }}>
                            {" "}
                            ₹{remainBalance}
                          </span>
                        )}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Link to={"/affilate/wallet"}>
                        {" "}
                        <AccountBalanceWalletIcon
                          sx={{ fontSize: 30, color: "#2196f3" }}
                        />{" "}
                      </Link>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 500, fontSize: 12 }}
                      >
                        Add Money
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ margin: "10px", display: { xs: "none", md: "block" } }}>
              <img
                src={moneyPng}
                alt="money"
                style={{
                  height: "140px",
                  width: "auto",
                  borderRadius: 0,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        component="header"
        sx={{
          width: { xs: "90%", md: "90%", lg: "80%" }, // Adjust width based on breakpoints
          margin: "0 auto", // Center the box horizontally
          borderRadius: "30px",
          padding: "10px 20px",
          backgroundColor: "#ffff",
          opacity: 0.7, // Adjust opacity value if needed
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow for depth
        }}
      >
        <Box className="d-flex align-items-center">
          {" "}
          <Switch
            checked={checked}
            onChange={handleChange}
            sx={{
              "& .MuiSwitch-root": {
                width: "48px",
                height: "32px",
              },
              "& .MuiSwitch-track": {
                bgcolor: "black",
                borderRadius: "50px",
              },
              "& .MuiSwitch-thumb": {
                bgcolor: checked ? "green" : "black", // Change bgcolor based on checked state
                transition: "background-color 0.3s", // Add transition for smooth color change
              },
              "& .Mui-focusVisible .MuiSwitch-thumb": {
                boxShadow: "0 0 1px #0974f1",
              },
            }}
          />
          <Typography
            className="racing-sans"
            sx={{
              fontSize: {
                xs: "16px",
                md: "24px",
                fontWeight: "bold",
                fontStyle: "italic",
              },
            }}
          >
            Advance
          </Typography>
        </Box>
        {!checked && (
          <form>
            <Grid container spacing={{ md: 2, xs: 1 }}>
              {" "}
              <Grid item xs={12} md={6}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="category" className="mb-2">
                    Category
                  </label>
                  <select
                    id="categoryDropdown"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    value={selectedCategory}
                    className="bg-light rounded-5 shadow-sm"
                  >
                    {uniqueCategoriesSet.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="services" className="mb-2">
                    Services
                  </label>
                  <select
                    className="bg-light rounded-5 shadow-sm"
                    onChange={handleServiceChange}
                  >
                    <option value={""}>Select Service</option>
                    {serviceOptions?.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="name" className="mb-2">
                    Link:
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control rounded-5 shadow-sm"
                    placeholder="Please  enter link"
                    name="link"
                    onChange={(e) => setLink(e.target.value)}
                    value={link}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  className="form-item m-2 d-flex justify-content-evenly align-items-center "
                  sx={{ color: "#212B36", gap: { xs: "16px", md: "30px" } }}
                >
                  <div>
                    <label htmlFor="quantity" className="mb-2">
                      Quantity
                    </label>
                    <input
                      type="number"
                      id="quantity"
                      className="form-control rounded-5 shadow-sm"
                      placeholder="Minimum 100"
                      name="quantity"
                      onChange={(e) => setQuantity(e.target.value)}
                      value={quantity}
                    />{" "}
                  </div>

                  <div style={{ display: "none" }}>
                    <label className="mb-2 text-center" htmlFor="maxExecutions">
                      Max Executions
                    </label>
                    <input
                      type="number"
                      id="name"
                      className="form-control rounded-5 shadow-sm"
                      placeholder="max exucation"
                      name="maxExecutions"
                      onChange={(e) => setMaxExecutions(e.target.value)}
                      value={maxExecutions}
                    />
                  </div>
                  <div className="form-item m-2" style={{ color: "#212B36" }}>
                    <label htmlFor="totalCharges" className="mb-2">
                      Total Charges
                    </label>
                    <input
                      id="name"
                      className="form-control rounded-5 shadow-sm"
                      type="number"
                      name="totalCharges"
                      value={totalCharges}
                      placeholder="Total Charges"
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "none" }}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="timing" className="mb-2">
                    Time Gap (Days/Hours/Minutes)
                  </label>
                  <input
                    type="text"
                    id="timing"
                    className="form-control rounded-5 shadow-sm"
                    placeholder="Enter timing (dd/hh/mm)"
                    name="timing"
                    onChange={handleTiming}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "none" }}>
                <Box
                  className="form-item m-2 d-flex justify-content-evenly align-items-center "
                  sx={{ color: "#212B36", gap: { xs: "16px", md: "30px" } }}
                >
                  <div>
                    <label htmlFor="totalQuantity" className="mb-2">
                      Total Quantity
                    </label>
                    <input
                      type="number    "
                      id="totalQuantity"
                      className="form-control py-2 rounded-5 shadow-sm"
                      name="totalQuantity"
                      value={totalQuantity}
                      placeholder="Total Quantity"
                    />{" "}
                  </div>
                  <Typography variant="h2" className="pt-4">X</Typography>
                  <div>
                    <label className="mb-2 text-center" htmlFor="maxExecutions">
                      Price Per Quantity
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control rounded-5 shadow-sm"
                      placeholder="max exucation"
                      value={rate}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "none" }}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="totalCharges" className="mb-2">
                    Total Charges
                  </label>
                  <input
                    id="name"
                    className="form-control rounded-5 shadow-sm"
                    type="number"
                    name="totalCharges"
                    value={totalCharges}
                    placeholder="Total Charges"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="form-group submit d-flex justify-content-center  align-items-center mt-2">
                  <Button
                    onClick={onSubmit2}
                    type="button"
                    variant="contained"
                    className="rounded-5"
                    sx={{
                      backgroundColor: "#546683",
                      width: "200px",
                      fontSize: { xs: "15px", md: "20px" },
                      "&:hover": {
                        backgroundColor: "green",
                      },
                    }}
                    disabled={submitting} // Disable the button if submitting is true
                  >
                    {submitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
        {checked && (
          <form>
            <Grid container spacing={{ md: 2, xs: 1 }}>
              <Grid item xs={12} md={6}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="name" className="mb-2">
                    Name
                  </label>
                  <input
                    className="form-control rounded-5 shadow-sm"
                    type="text"
                    id="name"
                    name="name"
                    value={sname}
                    onChange={(e) => setSname(e.target.value)}
                    placeholder="Enter Service name"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="name" className="mb-2">
                    Link:
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control rounded-5 shadow-sm"
                    placeholder="Please  enter link"
                    name="link"
                    onChange={(e) => setLink(e.target.value)}
                    value={link}
                  />
                </div>
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="category" className="mb-2">
                    Category
                  </label>
                  <select
                    id="categoryDropdown"
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    value={selectedCategory}
                    className="bg-light rounded-5 shadow-sm"
                  >
                    {uniqueCategoriesSet.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="services" className="mb-2">
                    Services
                  </label>
                  <select
                    className="bg-light rounded-5 shadow-sm"
                    onChange={handleServiceChange}
                  >
                    <option value={""}>Select Service</option>
                    {serviceOptions?.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  className="form-item m-2 d-flex justify-content-evenly align-items-center "
                  sx={{ color: "#212B36", gap: { xs: "16px", md: "30px" } }}
                >
                  <div>
                    <label htmlFor="quantity" className="mb-2">
                      Quantity
                    </label>
                    <input
                      type="number"
                      id="quantity"
                      className="form-control rounded-5 shadow-sm"
                      placeholder="quantity"
                      name="quantity"
                      onChange={(e) => setQuantity(e.target.value)}
                      value={quantity}
                    />{" "}
                  </div>
                  <Typography variant="h2" className="pt-4">X</Typography>
                  <div>
                    <label className="mb-2 text-center" htmlFor="maxExecutions">
                      Max Executions
                    </label>
                    <input
                      type="number"
                      id="name"
                      className="form-control rounded-5 shadow-sm"
                      placeholder="max exucation"
                      name="maxExecutions"
                      onChange={(e) => setMaxExecutions(e.target.value)}
                      value={maxExecutions}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="timing" className="mb-2">
                    Time Gap (Days/Hours/Minutes)
                  </label>
                  <input
                    type="text"
                    id="timing"
                    className="form-control rounded-5 shadow-sm"
                    placeholder="Enter timing (dd/hh/mm)"
                    name="timing"
                    onChange={handleTiming}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  className="form-item m-2 d-flex justify-content-evenly align-items-center "
                  sx={{ color: "#212B36", gap: { xs: "16px", md: "30px" } }}
                >
                  <div>
                    <label htmlFor="totalQuantity" className="mb-2">
                      Total Quantity
                    </label>
                    <input
                      type="number    "
                      id="totalQuantity"
                      className="form-control py-2 rounded-5 shadow-sm"
                      name="totalQuantity"
                      value={totalQuantity}
                      placeholder="Total Quantity"
                    />{" "}
                  </div>
                  <Typography variant="h2" className="pt-4">X</Typography>
                  <div>
                    <label className="mb-2 text-center" htmlFor="maxExecutions">
                      Price Per Quantity
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control rounded-5 shadow-sm"
                      placeholder="max exucation"
                      value={rate}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="form-item m-2" style={{ color: "#212B36" }}>
                  <label htmlFor="totalCharges" className="mb-2">
                    Total Charges
                  </label>
                  <input
                    id="name"
                    className="form-control rounded-5 shadow-sm"
                    type="number"
                    name="totalCharges"
                    value={totalCharges}
                    placeholder="Total Charges"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="form-group submit d-flex justify-content-center  align-items-center mt-2">
                  <Button
                    onClick={onSubmit1}
                    type="button"
                    variant="contained"
                    className="rounded-5"
                    sx={{
                      backgroundColor: "#546683",
                      width: "200px",
                      fontSize: { xs: "15px", md: "20px" },
                      "&:hover": {
                        backgroundColor: "green",
                      },
                    }}
                    disabled={submitting} // Disable the button if submitting is true
                  >
                    {submitting ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>

      <Box className="mt-5 d-flex justify-content-center">
        <Typography
          onClick={() => {
            setShowStatitics((prev) => !prev);
          }}
          sx={{
            bgcolor: "#ffff",
            opacity: "70%",
            fontSize: { xs: "20px", md: "24px" },
            padding: "0 20px",
            fontWeight: "bold",
            borderRadius: 10,
            cursor: "pointer",
          }}
        >
          Statistics{" "}
          {!showStatitics ? (
            <KeyboardArrowDownIcon fontSize="large" />
          ) : (
            <KeyboardArrowUpIcon fontSize="large" />
          )}
        </Typography>
      </Box>

      {showStatitics && <Chistory />}
    </Box>
  );
}

export default AffPulse;
