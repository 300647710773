// component
import SvgColor from "../../../components/svg-color";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FolderSharedIcon from '@mui/icons-material/FolderShared';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: icon("ic_analytics"),
  },
  {
    title: "Offers",
    path: "/affilate/offers",
    icon: <LocalOfferIcon />,
  },
  {
    title: "Conversions",
    path: "/affilate/conversions",
    icon: <ChangeCircleIcon />,
  },
  // {
  //   title: "ClickLogs",
  //   path: "/affilate/clicklogs",
  //   icon: <QueryStatsIcon />,
  // },

  {
    title: "Aff Pulse",
    path: "/dashboard/aff-pulse",
    icon: <PostAddIcon />,
  },
  // {
  //   title: "C-Statistics",
  //   path: "/dashboard/chistory",
  //   icon: <TaskIcon />,
  // },
  // {
  //   title: "ConversionReport",
  //   path: "/affilate/conversionReport",
  //   icon: <ChangeCircleIcon />,
  // },
  {
    title: "AdSpace",
    path: "/dashboard/adspace",
    icon: <AddToQueueIcon />,
  },
  {
    title: "Wallet",
    path: "/affilate/wallet",
    icon: <AccountBalanceWalletIcon />,
  },
  {
    title: "Aff Push",
    path: "/aff-push",
    icon: <NotificationsActiveIcon />,
  },
  {
    title: "Self Promotions",
    path: "/self-promotions",
    icon: <FolderSharedIcon />,
  },
];

export default navConfig;
