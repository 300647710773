import React from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import { Email, Phone } from "@mui/icons-material";

function Proposal() {
  return (
    <Box
      sx={{
        background: "linear-gradient(#A5BFD9, #A7D3FF)",
        p: 4,
        minHeight: "100vh",
      }}
    >
      <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
        Proposal
      </Typography>

      <Box
        p={5}
        bgcolor="#EDF3F9"
        borderRadius={2}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
      >
        {/* Left Container */}
        <Box
          width={{ xs: "100%", md: 250 }}
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Avatar
            src="profile-pic-url.jpg"
            alt="Profile Picture"
            sx={{ width: 150, height: 150, mb: 2 }}
          />
          <Typography variant="h6" textAlign="center">
            John Doe
          </Typography>
          <Typography variant="body2" textAlign="center">
            UserID: johndoe123
          </Typography>
          <Box mt={2} width="100%">
            <Typography variant="h6" color={"#717171"}>
              Contact Info
            </Typography>
            <Box display="flex" alignItems="center" mt={1} ml={2}>
              <Email fontSize="small" />
              <Typography variant="body2" ml={1}>
                johndoe@example.com
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1} ml={2}>
              <Phone fontSize="small" />
              <Typography variant="body2" ml={1}>
                +1234567890
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            marginX: 2,
            borderWidth: 2,
            bgcolor: "#404040",
            display: { xs: "none", md: "block" },
          }}
        />

        {/* Right Container */}
        <Box flex={1} ml={{ md: 2 }} mt={{ xs: 2, md: 0 }}>
          <Box mb={2}>
            <Typography variant="h6" color={"#717171"}>
              About Us
            </Typography>
            <Typography variant="body2">
              bfiuvbqieubvliuebviqev le vhe vhere v here vher evher vjhqe rvjh
              ejvh qej vq evjhe fvjhq fvj jfv ljf vjh fv fh vjef vj fvj lfjv jl
              fvl elfvh jehf vlqe efvje efjv qejlf vjlqef evjl efvlj efjhv ljefh
              vjhqe vh qelvhpivsa jh vh l vlhqer vlqheriqerer vqher vjler evlh
              ervjl erjlv jqler vje rvj eqrjv jqher vje rvj eqrjv jlqer vljqe vj
              qeejv jheq vjheljv jel vjh efvjqhlj vjlqe rvjl qejv jlqe vjqe vjh
              qjl vjlqev
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography variant="h6" color={"#717171"}>
              Type
            </Typography>
            <Box display="flex" flexWrap="wrap">
              <Button
                sx={{
                  color: "#000",
                  border: "1px solid #000",
                  bgcolor: "#fff",
                  width: "100px",
                  height: "30px",
                  margin: "2px 10px",
                }}
              >
                Reels
              </Button>
              <Button
                sx={{
                  color: "#000",
                  border: "1px solid #000",
                  bgcolor: "#fff",
                  width: "100px",
                  height: "30px",
                  margin: "2px 10px",
                }}
              >
                Reels
              </Button>
              <Button
                sx={{
                  color: "#000",
                  border: "1px solid #000",
                  bgcolor: "#fff",
                  width: "100px",
                  height: "30px",
                  margin: "2px 10px",
                }}
              >
                Reels
              </Button>
              <Button
                sx={{
                  color: "#000",
                  border: "1px solid #000",
                  bgcolor: "#fff",
                  width: "100px",
                  height: "30px",
                  margin: "2px 10px",
                }}
              >
                Reels
              </Button>
            </Box>
          </Box>

          <Box mb={2}>
            <Typography variant="h6" color={"#717171"}>
              Price
            </Typography>
            <Typography variant="body2" sx={{fontSize:"18px"}}>4000 AED</Typography>
          </Box>

          <Box mb={2}>
            <Typography variant="h6" color={"#717171"}>
              Collaborations
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
                <IconButton />
              </Grid>
              <Grid item>
                <IconButton />
              </Grid>
              <Grid item>
                <IconButton />
              </Grid>
              <Grid item>
                <IconButton />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Proposal;
