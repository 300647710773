import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningIcon from "@mui/icons-material/Warning";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import {
  getResFromLocalStorage,
  getUserFromLocalStorage,
} from "src/service/localStorage.js";
import account from "src/_mock/account";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function UserMainDetails() {
  const LocalStorageUser = getResFromLocalStorage();
  const theme = useTheme();

  return (
    <>
      <Box
        component={"main"}
        sx={{
          bgcolor: "#EDF2F7",
          height: "100vh",
          padding: { xs: "10px 10px", md: "10px 20px" },
        }}
      >
        <Box className="mt-3">
          <Grid container>
            <Grid item lg={4} md={4} xs={12}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  borderRadius: "0px",
                  borderRight: { xs: "none", md: "1px solid #B4B4B4" },
                  borderBottom: { xs: "1px solid #B4B4B4", md: "none" },
                  borderRadius: {
                    xs: "10px 0px 0px 10px",
                    md: "10px 0px 0px 10px",
                  },
                }}
              >
                <Box
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      height: "130px",
                      width: "130px",
                      bgcolor: "#B7B7B7",
                      margin: "0 auto",
                      borderRadius: 50,
                    },
                    [theme.breakpoints.up("md")]: {
                      height: "149px",
                      width: "149px",
                      bgcolor: "#B7B7B7",
                      margin: "0 auto",
                      borderRadius: 50,
                    },
                  }}
                >
                  <img
                    src={account.photoURL}
                    alt="img"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                </Box>
                <Box className="d-flex justify-content-center align-items-center gap-2 mt-3">
                  <VerifiedIcon style={{ color: "#1F94FF" }} />
                  <Typography
                    sx={{
                      [theme.breakpoints.up("md")]: {
                        fontWeight: "700",
                        fontSize: "20px",
                      },
                    }}
                  >
                    {LocalStorageUser.data.name}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  borderRadius: "0px",
                  borderRight: { xs: "none", md: "1px solid #B4B4B4" },
                  borderBottom: { xs: "1px solid #B4B4B4", md: "none" },
                }}
              >
                <Box>
                  <List>
                    <ListItem className="d-flex justify-content-center align-items-center">
                      <Box sx={{ marginRight: "10px" }}>
                        <AccountBoxIcon />
                      </Box>
                      <ListItemText
                        primary={`${LocalStorageUser.data.name} Active`}
                        primaryTypographyProps={{ style: { fontWeight: 600 } }}
                      />
                    </ListItem>
                    <ListItem className="d-flex justify-content-center align-items-center">
                      <Box sx={{ marginRight: "10px" }}>
                        {account.verified ? (
                          <VerifiedUserIcon
                            className="info-icon"
                            style={{ color: "green" }}
                          />
                        ) : (
                          <UnpublishedIcon
                            className="info-icon"
                            style={{ color: "red" }}
                          />
                        )}
                      </Box>
                      <ListItemText
                        primary={`${LocalStorageUser.data.email}`}
                        primaryTypographyProps={{ style: { fontWeight: 600 } }}
                      />
                    </ListItem>{" "}
                    <ListItem className="d-flex justify-content-center align-items-center">
                      <Box sx={{ marginRight: "10px" }}>
                        <LocationOnOutlinedIcon style={{ color: "#E82327" }} />
                      </Box>
                      <ListItemText
                        primary="India"
                        primaryTypographyProps={{ style: { fontWeight: 600 } }}
                      />
                    </ListItem>{" "}
                    <ListItem className="d-flex justify-content-center align-items-center">
                      <Box sx={{ marginRight: "10px" }}>
                        <CallOutlinedIcon />
                      </Box>
                      <ListItemText
                        primary="+91 123 456 7890"
                        primaryTypographyProps={{ style: { fontWeight: 600 } }}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Paper
                elevation={3}
                className="d-flex justify-content-center flex-column  gap-3"
                sx={{
                  p: 3,
                  height: "100%",
                  borderRadius: {
                    xs: "0px 0px 10px 10px",
                    md: "0px 10px 10px 0px",
                  },
                }}
              >
                <Box>
                  <Typography variant="h6">Joined on</Typography>
                  <Typography>September 10,2023 </Typography>
                </Box>
                <Box>
                  <Typography variant="h6">IP Address</Typography>
                  <Typography> 13:43:46.874 </Typography>
                </Box>
                <Box>
                  <Button variant="outlined">Reset Password</Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box className="mt-4">
          <Grid container spacing={{ xs: 3, md: 2 }}>
            <Grid item xs={6} md={2.4}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  borderRadius: "0px",
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6" sx={{ color: "#16a916" }}>
                  Earnings(INR)
                </Typography>
                <Typography variant="body2" sx={{ color: "#000" }}>
                  ₹ 0
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={6} md={2.4}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  borderRadius: "0px",
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6" sx={{ color: "#1f94ff" }}>
                  Offers
                </Typography>
                <Typography variant="body2" sx={{ color: "#000" }}>
                  ₹ 1
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={6} md={2.4}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  borderRadius: "0px",
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6" sx={{ color: "#dca817" }}>
                  Clicks
                </Typography>
                <Typography variant="body2" sx={{ color: "#000" }}>
                  0
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={6} md={2.4}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  borderRadius: "0px",
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6" sx={{ color: "#dca817" }}>
                  Level
                </Typography>
                <Typography variant="body2" sx={{ color: "#000" }}>
                  Silver
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  borderRadius: "0px",
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6" sx={{ color: "#dca817" }}>
                  DA
                </Typography>
                <Typography variant="body2" sx={{ color: "#000" }}>
                  1 win
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box className="mt-4">
          <Grid
            container
            spacing={{ xs: 3, md: 0 }}
            className="d-flex justify-content-center"
          >
            <Grid item md={1.7} xs={1.7}>
              <Paper
                className="d-flex justify-content-center align-items-center "
                elevation={3}
                sx={{
                  p: 1,
                  height: "100%",
                  borderRadius: "0px",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Overview</Typography>{" "}
                <KeyboardArrowDownIcon /> <KeyboardArrowUpIcon />
              </Paper>
            </Grid>
            <Grid item md={1.7} xs={1.7}>
              <Paper
                className="d-flex justify-content-center align-items-center "
                elevation={3}
                sx={{
                  p: 1,
                  height: "100%",
                  borderRadius: "0px",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Managers</Typography>{" "}
                <KeyboardArrowDownIcon /> <KeyboardArrowUpIcon />
              </Paper>
            </Grid>{" "}
            <Grid item md={1.7} xs={1.7}>
              <Paper
                className="d-flex justify-content-center align-items-center "
                elevation={3}
                sx={{
                  p: 1,
                  height: "100%",
                  borderRadius: "0px",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Campaigns</Typography>{" "}
                <KeyboardArrowDownIcon /> <KeyboardArrowUpIcon />
              </Paper>
            </Grid>{" "}
            <Grid item md={1.7} xs={1.7}>
              <Paper
                className="d-flex justify-content-center align-items-center "
                elevation={3}
                sx={{
                  p: 1,
                  height: "100%",
                  borderRadius: "0px",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>PostBacks</Typography>{" "}
                <KeyboardArrowDownIcon /> <KeyboardArrowUpIcon />
              </Paper>
            </Grid>{" "}
            <Grid item md={1.7} xs={1.7}>
              <Paper
                className="d-flex justify-content-center align-items-center "
                elevation={3}
                sx={{
                  p: 1,
                  height: "100%",
                  borderRadius: "0px",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Payouts</Typography>{" "}
                <KeyboardArrowDownIcon /> <KeyboardArrowUpIcon />
              </Paper>
            </Grid>{" "}
            <Grid item md={1.7} xs={1.7}>
              <Paper
                className="d-flex justify-content-center align-items-center "
                elevation={3}
                sx={{
                  p: 1,
                  height: "100%",
                  borderRadius: "0px",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Company</Typography>{" "}
                <KeyboardArrowDownIcon /> <KeyboardArrowUpIcon />
              </Paper>
            </Grid>{" "}
            <Grid item md={1.7} xs={1.7}>
              <Paper
                className="d-flex justify-content-center align-items-center "
                elevation={3}
                sx={{
                  p: 1,
                  height: "100%",
                  borderRadius: "0px",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Billing</Typography>{" "}
                <KeyboardArrowDownIcon /> <KeyboardArrowUpIcon />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default UserMainDetails;
