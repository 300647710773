import { Link as MuiLink, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

function CancellationRefund() {
  return (
    <>
    <Helmet>
    <title>Cancellation and Refund Policy | Affworld</title>
      <meta name="description" content="Learn about AffWorld.io's Cancellation and Refund Policy. Find out how we handle cancellations and refunds to ensure a smooth experience for our customers." />
      <meta name="keywords" content="AffWorld.io cancellation policy, refund policy, cancellation process, refund process, customer support, policy terms, return policy, cancellations and refunds, Affworld, Affworld cancellations and refunds" />
    </Helmet>
      <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
        Cancellation and Refund Policy
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Introduction
      </Typography>
      <Typography>
        At Affworld Technology, we strive to provide the best services to our
        customers. However, we understand that there may be instances where you
        need to cancel a service or request a refund. This policy outlines the
        conditions and procedures for cancellations and refunds.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Cancellation Policy
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Service Cancellation:
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Ad Space Purchase:
      </Typography>
      <Typography>
        Cancellation Window: You can cancel your ad space purchase within 24
        hours of placing the order. Procedure: To cancel, please contact our
        customer support team at <MuiLink
          href="mailto:support@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >support@affworld.io</MuiLink> with your order
        details.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Influencer Hiring:
      </Typography>
      <Typography>
        Cancellation Window: You can cancel the hiring of an influencer within
        48 hours of confirmation. Procedure: To cancel, please contact our
        influencer support team at <MuiLink
          href="mailto:influencers@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >influencers@affworld.io</MuiLink> with the
        influencer and contract details.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Influencer Agent Services:
      </Typography>
      <Typography>
        Cancellation Window: You can cancel influencer agent services within 7
        days of signing the agreement. Procedure: To cancel, please contact our
        agent services team at <MuiLink
          href="mailto:agentsupport@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >agentsupport@affworld.io</MuiLink> with the
        agreement details.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        General Conditions:
      </Typography>
      <Typography>
        All cancellation requests must be made in writing via email.
        Cancellations are subject to approval based on the time of request and
        the nature of the service. Some services may have specific cancellation
        terms which will be detailed in the service agreement. Refund Policy
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Eligibility for Refund:
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
      Ad Space Purchase:
      </Typography>
      <Typography>
      Refunds are available if the cancellation is made within the specified 24-hour window.
      Refunds are processed within 7-10 business days from the date of cancellation confirmation.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
      Influencer Hiring:
      </Typography>
      <Typography>
      Refunds are available if the cancellation is made within the specified 48-hour window.
Partial refunds may be available if services have already commenced.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
      Influencer Agent Services:
      </Typography>
      <Typography>
      Refunds are available if the cancellation is made within the specified 7-day window.
A pro-rata refund may be issued based on the duration of the services used.
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
      Non-Refundable Situations:
      </Typography>
      <Typography>
      No refunds will be issued for services canceled after the specified cancellation windows.
No refunds will be issued for services that have been fully rendered or completed.
How to Request a Refund
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
      Send an email to the respective support team based on the service:
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
      Ad Space: <MuiLink
          href="mailto:support@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >support@affworld.io</MuiLink>
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
      Influencer Hiring: <MuiLink
          href="mailto:influencers@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >influencers@affworld.io</MuiLink>
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
      Influencer Agent Services: <MuiLink
          href="mailto:agentsupport@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >agentsupport@affworld.io</MuiLink>
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
      Include the following details in your email:
      </Typography>
      <Typography>
      Your full name and contact information.<br/>
The order or agreement numter.<br/>
The reason for the cancellation or refund request.<br/>
Any supporting documents or evidence.
<Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
Contact Us:
      </Typography>
If you have any questions about our Cancellation and Refund Policy, please contact us at<MuiLink
          href="mailto:support@affworld.io"
          variant="body1"
          color="primary"
          underline="hover"
        >support@affworld.io</MuiLink> or call our customer service hotline at <MuiLink
        href="tel:+971542997582"
        variant="body1"
        color="primary"
        underline="hover"
      >+971542997582</MuiLink>.
      </Typography>
    </>
  );
}

export default CancellationRefund;
