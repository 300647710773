import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Alert,
  CircularProgress,
  Grid,
  Skeleton,
  Avatar,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import axios from "axios";
import { toast } from "react-toastify";
import { getUserFromLocalStorage } from "src/service/localStorage";

const imgUploadCardStyle = {
  height: { md: 180, xs: 80 },
  width: "100%",
  borderRadius: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

function CircularProgressWithLabel({ value }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={value} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function ManageGallery() {
  const {
    data: { access_token },
  } = getUserFromLocalStorage();
  const URL = process.env.REACT_APP_PROD_API;
  const notificationImageUploadRef = useRef(null);
  const [uploadStatus, setUploadStatus] = useState({
    loading: false,
    error: null,
  });
  const [progress, setProgress] = useState(0);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [deleteLoadingState, setDeleteLoadingState] = useState(false);

  const fetchPnsImages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${URL}/api/pns/images`, {
        headers: { Authorization: `Bearer ${access_token}` },
      });
      setImages(response.data || []);
    } catch (error) {
      console.error("Error fetching images:", error);
      toast.error("Error fetching images");
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    notificationImageUploadRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("image_file", file);

    try {
      setUploadStatus({ loading: true, error: null });
      const response = await axios.post(`${URL}/api/pns/upload`, formData, {
        headers: { Authorization: `Bearer ${access_token}` },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });
      if (response) {
        toast.success("Image upload successful!");
        fetchPnsImages();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file.");
      setUploadStatus({
        loading: false,
        error: error.response?.data?.detail || "Failed to upload image",
      });
    } finally {
      setUploadStatus({ loading: false, error: null });
      setProgress(0);
      event.target.value = null;
    }
  };

  const handleSelectChange = (event, imageId) => {
    if (event.target.checked) {
      setSelectedImages((prevSelectedImages) => [
        ...prevSelectedImages,
        imageId,
      ]);
    } else {
      setSelectedImages((prevSelectedImages) =>
        prevSelectedImages.filter((id) => id !== imageId)
      );
    }
  };

  const handleDeleteImages = async () => {
    if (selectedImages.length === 0) {
      toast.error("No images selected");
      setDeleteLoadingState(false);
      return;
    }
    setDeleteLoadingState(true);
    const promises = selectedImages.map((id) =>
      axios.delete(`${URL}/api/pns/delete/${id}`, {
        headers: { Authorization: `Bearer ${access_token}` },
      })
    );
    try {
      await Promise.all(promises);
      toast.success("Images deleted successfully");
      setDeleteLoadingState(false);
      setSelectedImages([]);
      fetchPnsImages();
    } catch (error) {
      console.error("Error deleting images:", error);
      toast.error("Error deleting images");
      setDeleteLoadingState(false);
    }
  };

  useEffect(() => {
    fetchPnsImages();
  }, []);

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #A5BFD9 9.55%, #A7D3FF 100%)",
        height: { xs: "auto", md: "100vh" },
      }}
    >
      <Box component="section" px={2} py={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#12314F",
              fontWeight: "bold",
              fontSize: { xs: "1.2rem", md: "2rem" },
            }}
          >
           Manage Gallery
          </Typography>
          <Box sx={{ display: "flex", gap: { xs: 2, md: 3 } }}>
            {isSelect && (
              <Button
                onClick={handleDeleteImages}
                sx={{
                  bgcolor: "red",
                  color: "#FFFF",
                  fontWeight: "bold",
                  "&:hover": {
                    bgcolor: "red",
                  },
                }}
              >
                {deleteLoadingState ? "deleting" : "Delete"}
              </Button>
            )}
            <Button
              onClick={() => {
                setIsSelect((prev) => !prev);
              }}
              sx={{
                bgcolor: "#FFFFFF",
                color: "#12314F",
                fontWeight: "bold",
                "&:hover": {
                  bgcolor: "#FFFFFF",
                },
              }}
            >
              {isSelect ? "Deselect" : "Select"}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            height: "85vh",
            overflowY: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          <Grid container spacing={3} mt={1}>
            <Grid item xs={6} md={3}>
              <Paper sx={imgUploadCardStyle} onClick={handleButtonClick}>
                {uploadStatus.loading ? (
                  <CircularProgressWithLabel value={progress} />
                ) : (
                  <AddPhotoAlternateIcon
                    sx={{
                      fontSize: { md: 80, xs: 50 },
                      color: "rgb(102,131,177)",
                    }}
                  />
                )}
              </Paper>
            </Grid>
            {loading
              ? Array.from(new Array(12)).map((_, index) => (
                  <Grid
                    item
                    xs={6}
                    md={3}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton
                      sx={{
                        ...imgUploadCardStyle,
                        bgcolor: "grey.700",
                        borderRadius: 2,
                      }}
                      variant="rectangular"
                    />
                  </Grid>
                ))
              : images.map((image, index) => (
                  <Grid
                    item
                    xs={6}
                    md={3}
                    key={index}
                    sx={{ position: "relative" }}
                  >
                    <Box sx={{ position: "relative" }}>
                      <Avatar
                        src={image.image_url}
                        sx={{
                          width: 150,
                          height: 150,
                          borderRadius: 0,
                          display: "block",
                          mx: "auto",
                          "&:hover": { cursor: "pointer" },
                          ...imgUploadCardStyle,
                        }}
                      />
                      {isSelect && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedImages.includes(image._id)}
                              onChange={(e) => handleSelectChange(e, image._id)}
                              sx={{ position: "absolute", top: 8, left: 8,
                                border:"2px solid blue"
                               }}
                            />
                          }
                          label=""
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
          </Grid>
        </Box>

        <input
          type="file"
          ref={notificationImageUploadRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        {uploadStatus.error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {uploadStatus.error}
          </Alert>
        )}
      </Box>
    </Box>
  );
}

export default ManageGallery;
