import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Typography,
  Link,
  Divider,
  Stack,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import {
  Phone as PhoneIcon,
  Email as EmailIcon,
  Home as AddressIcon,
  Language as WebsiteIcon,
  Business as CompanyIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
  CloudQueue as SkypeIcon,
} from "@mui/icons-material";

function App() {
  const [userData, setUserData] = useState(null);
  const URL = process.env.REACT_APP_PROD_API;
  const { id } = useParams();

  const getUserData = async () => {
    const url = `${URL}/api/affiliates/info?name=${id}`;
    try {
      const res = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      setUserData(res?.data);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  if (!userData)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ margin: "20px auto" }} />;
      </Box>
    );

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "20px auto",
        padding: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <Avatar src={userData.profile_pic} sx={{ width: 100, height: 100 }} />
        <Typography variant="h5" fontWeight="bold">
          {userData.name}
        </Typography>
        {userData?.bio && (
          <Typography variant="body1">{userData?.bio}</Typography>
        )}
      </Stack>

      <Divider sx={{ marginY: 3 }} />

      <Typography variant="h6" gutterBottom>
       Collections
      </Typography>
      <Box
        sx={{
          display: "flex",
          overflowY: "auto",
          gap: 2,
          padding: 1,
          flexWrap: "wrap",
          maxHeight: 600,
        }}
      >
        {userData?.products?.map((product) => (
          <ProductCard key={product?.product_id} product={product} />
        ))}
      </Box>
      <Divider sx={{ marginY: 3 }} />

      <Stack spacing={2}>
        {userData?.contact_number && (
          <ContactItem
            icon={<PhoneIcon color="primary" />}
            label="Phone"
            value={userData?.contact_number}
            link={`tel:${userData?.contact_number}`}
          />
        )}
       {userData?.email &&
         <ContactItem
         icon={<EmailIcon color="primary" />}
         label="Email"
         value={userData?.email}  
         link={`mailto:${userData?.email}`}
       />
       }
       {
        userData?.affiliate_address && (
          <ContactItem
          icon={<AddressIcon color="primary" />}
          label="Affiliate Address"
          value={userData?.affiliate_address || "No address provided"}
        />)
       }
        {
        userData?.website_address &&
          <ContactItem
          icon={<WebsiteIcon color="primary" />}
          label="Website"
          value={userData?.website_address || "No website available"}
        />
        }
       {
        userData?.company_name && (
          <ContactItem
          icon={<CompanyIcon color="primary" />}
          label="Company"
          value={userData?.company_name}
        />
        )
       }
      </Stack>

      <Divider sx={{ marginY: 3 }} />

      <Stack direction="row" spacing={3} justifyContent="center">
        {userData?.linkedin_profile_link && (
          <SocialLink
            icon={<LinkedInIcon color="primary" />}
            url={userData?.linkedin_profile_link}
          />
        )}
        {userData?.twitter_handle && (
          <SocialLink
            icon={<TwitterIcon color="primary" />}
            url={userData?.twitter_handle}
          />
        )}
       {
        userData?.skype_id && (
          <SocialLink
          icon={<SkypeIcon color="primary" />}
          value={userData?.skype_id}
        />
        )
       }
      </Stack>
    </Box>
  );
}

function ContactItem({ icon, label, value, link }) {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {icon}
      <Box>
        <Typography variant="subtitle1">{label}</Typography>
        {link ? (
          <Link href={link} underline="none" color="inherit">
            {value}
          </Link>
        ) : (
          <Typography variant="body2" color="textSecondary">
            {value}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

function SocialLink({ icon, url, value }) {
  return (
    <a
      href={url || "#"}
      target="_blank"
      underline="none"
      color="inherit"
      sx={{ display: "flex", alignItems: "center" }}
      rel="noreferrer"
    >
      {icon}
    </a>
  );
}

function ProductCard({ product }) {
  return (
    <Card sx={{ width: 250, boxShadow: 2, marginX: "auto" }}>
      <CardMedia
        component="img"
        height="250"
        image={product?.image_url}
        alt={product?.title}
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Typography
          variant="subtitle1"
          noWrap
          sx={{ textDecoration: "none", marginBottom: 1 }}
        >
          {product?.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" marginBottom={1}>
          {product?.description}
        </Typography>
        <div>
          <Button>
            <a
              href={`${product?.product_link}`}
              underline="none"
              color="inherit"
              sx={{ textDecoration: "none" }}
              rel="noreferrer"
              target="_blank"
            >
              View Details
            </a>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default App;
